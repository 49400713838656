import { useState } from "react";
import { BusinessBasicInfo } from "../../Components/BusinessBasicInfo/BusinessBasicInfo";
import { BusinessLocationStep } from "../../Components/BusinessLocationStep/BusinessLocationStep";
import { SelectServicesStep } from "../../Components/SelectServicesStep/SelectServicesStep";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  logout,
  setEnableFlowToNull,
} from "../../Features/authorizeUser/authorizeUser-slice";
import NavSteps from "./components/NavSteps";
import { createShop } from "../../Features/shop/shop-slice";

export const CreateShopFlow = ({ isFromSettings, setIsAddBranchPressed }) => {
  const [stepSelected, setStepSelected] = useState(1);
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [shopLocation, setShopLocation] = useState(null); //{lng: , lat:}
  const [shopAddress, setShopAddress] = useState({
    address: "",
    number: "",
    region: "",
    city: "",
    country: "",
    zipCode: "",
  });
  const [mainService, setMainService] = useState(null);
  const [aboutUs, setAboutUs] = useState("-");
  const [selectedServices, setSelectedServices] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const newShop = async () => {
    try {
      const res = await dispatch(
        createShop({
          payload: {
            branchName: businessName,
            categoryNames: [...selectedServices, mainService],
            branchCountry: shopAddress.country,
            branchType: mainService,
            branchRegion: shopAddress.region,
            branchAddress: shopAddress.address + " " + shopAddress.number,
            branchCity: shopAddress.city,
            branchPostCode: shopAddress.zipCode,
            branchLatitude: `${shopLocation.lat}`,
            branchLongitude: `${shopLocation.lng}`,
            aboutUs: aboutUs,
            branchPhone: businessPhone,
          },
        }),
      );

      if (!res?.error) {
        dispatch(setEnableFlowToNull());
        navigate("/calendar");
      }
    } catch (e) {}
  };
  const goBack = () => {
    try {
      if (stepSelected === 1) {
        dispatch(logout());
        navigate("/");
      }
      setStepSelected(stepSelected - 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <NavSteps goBack={goBack} current={stepSelected} />
      <div className={"h-screen p-5 align-center bg-gray-50 m-auto"}>
        {stepSelected === 1 && (
          <BusinessBasicInfo
            isFromSettings={isFromSettings}
            setIsAddBranchPressed={setIsAddBranchPressed}
            setBusinessPhone={setBusinessPhone}
            setBusinessName={setBusinessName}
            businessPhone={businessPhone}
            businessName={businessName}
            aboutUs={aboutUs}
            setAboutUs={setAboutUs}
            onNext={() => setStepSelected(2)}
          />
        )}
        {stepSelected === 2 && (
          <BusinessLocationStep
            goBack={() => {
              setStepSelected(stepSelected - 1);
            }}
            shopLocation={shopLocation}
            shopAddress={shopAddress}
            setShopAddress={setShopAddress}
            setShopLocation={setShopLocation}
            onNext={() => setStepSelected(3)}
          />
        )}
        {stepSelected === 3 && (
          <SelectServicesStep
            goBack={() => {
              setStepSelected(stepSelected - 1);
            }}
            setMainService={setMainService}
            mainService={mainService}
            setSelectedServices={setSelectedServices}
            selectedServices={selectedServices}
            onNext={() => {
              newShop();
            }}
          />
        )}
      </div>
    </>
  );
};
