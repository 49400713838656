import { useDispatch, useSelector } from "react-redux";
import { turnPayloadFromBackendToHaveLocalTimeNotUtc } from "../../Utilities/utilities";

import { useEffect, useState } from "react";
import { EmployeeShiftsOfWeekTable } from "../EmployeeShiftsOfWeekTable/EmployeeShiftsOfWeekTable";
import { editEmployeeShifts } from "../../Features/employeeShifts/employeeShifts-slice";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";
import { employees } from "../../Features/employees/employees-slice";

export const EmployeesShiftsTable = ({ employeesProps }) => {
  const dispatch = useDispatch();
  const employeeWhenIsInEmployeeMode = useSelector(employees);
  const isEmployeeShiftsLoading = useSelector(
    (state) => state.employeeShiftsSlice.isLoading,
  );
  const employeeId = useSelector((state) => state.authorizeUser.employeeId);
  const [employee, setEmployee] = useState({});
  const [allWeekShiftsArray, setAllWeekShiftsArray] = useState(null);
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  useEffect(() => {
    setAllWeekShiftsArray(
      turnPayloadFromBackendToHaveLocalTimeNotUtc(employee?.shifts),
    );
  }, [employee]);

  useEffect(() => {
    if (!employeesProps) {
      setEmployee(
        employeeWhenIsInEmployeeMode.filter(
          (employee) => employee?.id === employeeId,
        )?.[0],
      );
    }
    if (employeesProps) {
      setEmployee(employeesProps?.[0]);
    }
  }, [employeesProps, employeeWhenIsInEmployeeMode]);

  return (
    <div className="mx-auto">
      <div className={"lg:flex w-full justify-between"}>
        <div>
          <h2
            className="text-base font-semibold leading-7
                text-gray-900"
          >
            Βάρδιες Υπαλλήλου
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Οι πληροφορίες εδώ φαίνονται δημόσια. Πρόσεξε τι θα βάλεις.
          </p>
        </div>
        {loggedInAs !== "employee" && (
          <div className={"mt-4 mb-5   sm:flex-none"}>
            <ButtonWithLoader
              text={"Αποθήκευση"}
              customStyling={
                "block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              isLoading={isEmployeeShiftsLoading}
              onClick={() => {
                dispatch(editEmployeeShifts({ allWeekShiftsArray }));
              }}
            />
          </div>
        )}
      </div>
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"></dl>
      {employee &&
        Object?.keys(employee)?.length > 0 &&
        allWeekShiftsArray?.length > 0 && (
          <EmployeeShiftsOfWeekTable
            allWeekShiftsArray={allWeekShiftsArray}
            setAllWeekShiftsArray={setAllWeekShiftsArray}
          />
        )}
    </div>
  );
};
