import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { classNames } from "../../Utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import { setTypeOfView } from "../../Features/typeOfView/typeOfView-slice";
import { ViewColumnsIcon } from "@heroicons/react/24/outline";

const RangeOfDays = () => {
  // const selectedRangeOfDays = useSelector()
  const currentTypeOfView = useSelector((state) => state.typeOfView.typeOfView);
  const dispatch = useDispatch();
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  const availableViews = [
    {
      type: "weeklyView",
      name: "Εβδομάδα",
    },
    { type: "dayView", name: "1 ημέρα" },
    { type: "twoDayView", name: "2 ημέρες" },
    { type: "threeDayView", name: "3 ημέρες" },
  ];

  return (
    <Menu as="div" className=" w-full md:w-auto relative ">
      <Menu.Button
        type="button"
        className="w-full h-[40px] justify-between items-center text-ellipsis  flex gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ViewColumnsIcon className="h-5 w-5" />
        <div
          className={loggedInAs === "employee" ? "block" : "hidden sm:block"}
        >
          {
            availableViews?.filter(
              (view) => view.type === currentTypeOfView,
            )?.[0]?.name
          }
        </div>

        <ChevronDownIcon className="-mr-1 h-4 w-4" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            " sm:left-0 absolute z-10 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            loggedInAs === "owner" && "w-auto sm:w-full  left-[-24px] ",
            loggedInAs === "employee" && "w-full",
          )}
        >
          <div className="py-1">
            {availableViews.map((view, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <span
                    onClick={() => {
                      dispatch(setTypeOfView(view.type));
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer",
                    )}
                  >
                    {view.name}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default RangeOfDays;
