import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { classNames } from "../../Utilities/utilities";
import "./style.css";
import "react-phone-number-input/style.css";

const PhoneNumberInput = ({
  phone = "",
  setPhone,
  className,
  disabled = false,
}) => {
  return (
    <PhoneInput
      disabled={disabled}
      className={classNames(
        isValidPhoneNumber(phone ? phone : "")
          ? "after:content-['✅']"
          : "after:content-['🚫']",
        className ? className : "",
        "h-10 mt-1 after:text-xs rounded px-4 w-full bg-gray-50 input-phone-number",
      )}
      placeholder="Enter phone number"
      value={phone}
      onChange={(e) => {
        setPhone(e || "");
      }}
      international
      countryCallingCodeEditable={false}
      defaultCountry="GR"
      countrySelectProps={{ unicodeFlags: true }}
      focusInputOnCountrySelection={false}
    />
  );
};

export default PhoneNumberInput;
