import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import WorkerMenuDropDown from "../../Pages/WorkersPage/components/WorkerMenuDropDown";

const EmployeeCard = ({ employee, setWorkerToBeDeleted }) => {
  return (
    <div className={"bg-gray-100 rounded-xl p-3"}>
      <header className={"flex justify-between"}>
        <div>
          <span className="relative inline-block">
            {employee?.profilePicUrl?.includes("https") ? (
              <img
                className="h-20 w-20  object-cover rounded-full overflow-hidden"
                src={employee?.profilePicUrl}
                alt=""
              />
            ) : (
              <div className="h-20 w-20 overflow-hidden rounded-full bg-white flex items-center justify-center">
                <UserIcon className={"h-8 w-8 inline-block text-gray-300 "} />
              </div>
            )}

            <span className="absolute bottom-3 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
          </span>
          <div>
            <h3>
              {employee?.firstName} {employee?.lastName}
            </h3>
            <span className={"text-gray-500"}>{employee?.occupation}</span>
          </div>
        </div>
        <div>
          <WorkerMenuDropDown
            employee={employee}
            setWorkerToBeDeleted={setWorkerToBeDeleted}
          />
        </div>
      </header>
      <div className={"bg-white rounded-xl p-4 mt-3 overflow-hidden"}>
        <div className={"grid grid-cols-2 gap-2"}>
          <div>
            <p className={"text-xs text-gray-500"}>Ρόλοι</p>
            <p className={"text-sm"}>Employee</p>
          </div>
          <div>
            <p className={"text-xs text-gray-500"}>Ημ. Πρόσληψης</p>
            <p className={"text-sm"}>
              {dayjs(employee?.createdDate).format("DD-MM-YYYY")}
            </p>
          </div>
          <div
            className={"inline-flex items-center gap-3 mt-4 text-sm col-span-3"}
          >
            <EnvelopeIcon className={"w-4 h-4"} />
            <span className={"text-ellipsis overflow-hidden"}>
              {employee?.email}
            </span>
          </div>
          <div className={"inline-flex items-center gap-3 text-sm col-span-3"}>
            <PhoneIcon className={"w-4 h-4"} />
            <span className={"text-ellipsis overflow-hidden"}>
              {employee?.phone}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
