import { OwnerRoutes } from "../OwnerRoutes/OwnerRoutes";
import { EmployeeRoutes } from "../EmployeeRoutes/EmployeeRoutes";
import { useSelector } from "react-redux";

export const LoggedInRoutes = () => {
  const isLoggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  if (isLoggedInAs === "owner") {
    return <OwnerRoutes />;
  }

  if (isLoggedInAs === "employee") {
    return <EmployeeRoutes />;
  }
};
