import { login } from "../../Features/authorizeUser/authorizeUser-slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";

export const SignInForm = ({
  asOwner,
  setUserWantsLoginAsAnOwner,
  setUserWantsToRegister,
  setUserWantsLoginAsAnEmployee,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isLoadingAuthorise = useSelector(
    (state) => state.authorizeUser.isLoading,
  );
  const dispatch = useDispatch();

  const loginUser = async () => {
    const response = await dispatch(
      login({
        email,
        password,
        loginAs: asOwner ? "BranchOwner" : "Employee",
      }),
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Συνδέσου ως
          {asOwner ? " ιδιοκτήτης" : " υπάλληλος"}
        </h2>
      </div>
      <form>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Κωδικός
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Ξέχασες τον κωδικό σου?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <ButtonWithLoader
                isLoading={isLoadingAuthorise}
                text={"Σύνδεση"}
                type={"submit"}
                customStyling={
                  "flex w-full justify-center rounded-md bg-primary px-3 py-1.5 h-9 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={loginUser}
              />
            </div>
          </div>
          <p className="mt-10 text-center text-sm cursor-pointer text-gray-500">
            Δεν είσαι ιδιοκτήτης?{" "}
            <span
              onClick={() => setUserWantsToRegister()}
              className="font-semibold leading-6 text-indigo-600  hover:text-indigo-500"
            >
              Κάνε εγγραφή ως ιδιοκτήτης
            </span>
          </p>{" "}
          <p className="text-center text-sm cursor-pointer text-gray-500">
            Δεν είσαι {asOwner ? "ιδιοκτήτης" : "υπάλληλος"}?{" "}
            <span
              onClick={() =>
                asOwner
                  ? setUserWantsLoginAsAnEmployee()
                  : setUserWantsLoginAsAnOwner()
              }
              className="font-semibold leading-6 text-indigo-600  hover:text-indigo-500"
            >
              Συνδέσου ως {asOwner ? "υπάλληλος" : "ιδιοκτήτης"}
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};
