import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changeAppointmentStatues } from "../../Features/editAppointment/editAppointment-slice";

const people = [
  { id: 1, name: "Εκκρεμής", value: "Pending" },
  { id: 2, name: "Ήρθε", value: "Finished" },
  { id: 3, name: "Δεν ήρθε", value: "Absent" },
  // { id: 0, name: "Ακύρωση", value: "Canceled" },
];

const AppointmentStatusChanger = ({ appointment }) => {
  const [selected, setSelected] = useState(people[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(people.find((item) => item?.value === appointment?.status));
  }, []);

  const changeAppointmentStatus = async (event) => {
    try {
      setSelected(event);
      await dispatch(changeAppointmentStatues({ status: event?.value }));
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <div className="w-full py-2 px-4 border-b-[1px] pb-4">
      <p className={"text-sm font-semibold"}>Κατάσταση:</p>
      <Listbox value={selected} onChange={changeAppointmentStatus}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-pointer hover:bg-gray-50 rounded-lg bg-white py-2 pl-4 text-left ring-1 ring-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-6 pr-4 ${
                      active
                        ? "bg-primary-100 text-primary-900"
                        : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <div key={person.id}>
                      <span
                        className={`block truncate ${
                          selected
                            ? "font-medium text-primary-900"
                            : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-primary-600">
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default AppointmentStatusChanger;
