import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authorizeUser from "../Features/authorizeUser/authorizeUser-slice";
import modal from "../Features/modal/modal-slice";
import calendar from "../Features/calendar/calendar-slice";
import appointment from "../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import typeOfView from "../Features/typeOfView/typeOfView-slice";
import userInterface from "../Features/ui/ui-slice";
import personalDataSlice from "../Features/personalData/personalData-slice";
import storeSlice from "../Features/shop/shop-slice";

import shiftsSlice from "../Features/shifts/shifts-slice";
import employeesSlice from "../Features/employees/employees-slice";
import categoriesWithServicesSlice from "../Features/categoriesWithServices/categoriesWithServices-slice";
import branchesSlice from "../Features/branches/branches-slice";
import clientsSlice from "../Features/clients/clientsSlice";
import appointmentsSlice from "../Features/appointments/appointments-slice";
import editAppointmentSlice from "../Features/editAppointment/editAppointment-slice";
import inviteEmployeeSlice from "../Features/inviteEmployee/InviteEmployee-slice";
import employeeShiftsSlice from "../Features/employeeShifts/employeeShifts-slice";
import imagesSlice from "../Features/images/images-slice";
import statisticsSlice from "../Features/statistics/statistics-slice";
import reviewsSlice from "../Features/reviews/reviews-slice";

const combinedReducer = combineReducers({
  authorizeUser: authorizeUser,
  modal: modal,
  calendar: calendar,
  appointment: appointment,
  appointments: appointmentsSlice,
  typeOfView: typeOfView,
  userInterface: userInterface,
  personalData: personalDataSlice,
  shop: storeSlice,
  categoriesWithServices: categoriesWithServicesSlice,
  shifts: shiftsSlice,
  employees: employeesSlice,
  branches: branchesSlice,
  clients: clientsSlice,
  editAppointment: editAppointmentSlice,
  inviteEmployee: inviteEmployeeSlice,
  employeeShiftsSlice: employeeShiftsSlice,
  imagesUpload: imagesSlice,
  statistics: statisticsSlice,
  reviews: reviewsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = {};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
