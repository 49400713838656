import { classNames } from "../../Utilities/utilities";
import { useDispatch } from "react-redux";
import { setSelectedClient } from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import { validateEmail } from "../../Utilities/validateEmail";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";

export default function ClientCard({ client, close, onEdit }) {
  const dispatch = useDispatch();
  return (
    <button
      onClick={() => {
        if (!onEdit) {
          dispatch(setSelectedClient(client));
          if (close) {
            close();
          }
        }
        if (onEdit) {
          onEdit();
        }
      }}
      key={client.name}
      className={
        "w-full flex items-center justify-between p-4 text-gray-900 hover:bg-gray-50 border-b-[1px] last:border-0 border-gray-200"
      }
    >
      <div className={"inline-flex gap-3 items-center "}>
        <div
          className={
            "h-10 w-10 ring-1 ring-gray-400 rounded-full flex justify-center items-center"
          }
        >
          <UsersIcon className={"h-5 w-5"} />
        </div>
        <div className="flex items-start flex-col text-xs">
          <span className="font-medium text-gray-900">
            {client.firstName + " " + client.lastName}
          </span>
          <span className={"text-sm"}>{client.phone}</span>
          <span className={"text-sm"}>
            {validateEmail(client.email) ? client.email : "-"}
          </span>
        </div>
      </div>

      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
}
