import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";

export const getBranchEmployees = createAsyncThunk(
  "employees/getBranchEmployees",
  async ({ id }, thunkAPI) => {
    const res = await Request.get(`Branch/get-employees/${id}`);
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const updateEmployee = createAsyncThunk(
  "employees/updateEmployee",
  async ({ payload }, thunkAPI) => {
    const { employees, shop } = thunkAPI.getState();
    const res = await Request.put(
      "Employee/update/" + employees.employeeToBeEdited?.id,
      {
        body: payload,
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const getEmployee = createAsyncThunk(
  "employees/getEmployee",
  async ({ id }, thunkAPI) => {
    const res = await Request.get(`Employee/${id}`);
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const dismissalEmployee = createAsyncThunk(
  "employee/dismissal",
  async ({ employeeId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();

    const res = await Request.remove(
      `Branch/delete-employee/${employeeId}/${shop?.id}`,
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const initialState = {
  isLoading: false,
  data: [],
  employeeToBeEdited: null,
};

const employeesSlice = createSlice({
  name: "Employees",
  initialState,
  reducers: {
    setBranchEmployees(state, action) {
      state.data = action.payload;
    },
    setEmployeeToBeEdited(state, action) {
      state.employeeToBeEdited = action.payload;
    },
    clearEmployeeToBeEdited(state, action) {
      state.employeeToBeEdited = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBranchEmployees.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = action.payload || [];
        }

        if (state.employeeToBeEdited) {
          const employeeWhichWsEdited = action.payload?.filter(
            (employee) => employee.id === state.employeeToBeEdited.id,
          )?.[0];
          if (employeeWhichWsEdited) {
            state.employeeToBeEdited = employeeWhichWsEdited;
          }
        }
      })
      .addCase(getBranchEmployees.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(getEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = [action.payload];
        }
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Ο υπάλληλος άλλαξε επιτυχώς");
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          toast.error(action.error.message);
        }
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(dismissalEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(dismissalEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Ο υπάλληλος έφυγε από την ομάδα σας");
      })
      .addCase(dismissalEmployee.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

export const employeeToBeEdited = (state) => state.employees.employeeToBeEdited;
export const employees = (state) => state.employees?.data;

export const {
  setBranchEmployees,
  setEmployeeToBeEdited,
  clearEmployeeToBeEdited,
} = employeesSlice.actions;

export default employeesSlice.reducer;
