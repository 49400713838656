import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import EditService from "../EditService/EditService";
import { useEffect, useState } from "react";
import AddService from "../AddService/AddService";

export const AddServiceSlideOver = ({
  isAddServiceShown,

  onCancel,
  isPressedFromCategory,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAddServiceShown) {
      setOpen(false);
    }

    if (isAddServiceShown) {
      setOpen(true);
    }
  }, [isAddServiceShown]);

  useEffect(() => {
    if (!open) {
      onCancel();
    }
  }, [open]);
  return (
    <SlideOverWithCloseButtonOnOutside
      open={open}
      setOpen={setOpen}
      title={"Προσθήκη υπηρεσίας"}
    >
      <AddService
        isPressedFromCategory={isPressedFromCategory}
        onCancel={onCancel}
      />
    </SlideOverWithCloseButtonOnOutside>
  );
};
