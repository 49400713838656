import dayjs from "dayjs";
import Datepicker from "react-tailwindcss-datepicker";
import { CalendarIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeIncome,
  getTotalIncome,
  initializeEmployeesIncome,
} from "../../../Features/statistics/statistics-slice";

const CalendarRangePicker = () => {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees?.data);
  const [value, setValue] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const handleValueChange = async (newValue) => {
    try {
      setValue(newValue);
      console.log({
        dateStart: newValue?.startDate,
        dateEnd: newValue?.endDate,
      });
      await dispatch(
        getTotalIncome({
          data: {
            dateStart: newValue?.startDate,
            dateEnd: newValue?.endDate,
          },
        }),
      );
      employees?.forEach((employee) => {
        const data = {
          dateStart: newValue?.startDate,
          dateEnd: newValue?.endDate,
          employee,
          fromCalendar: true,
        };
        dispatch(initializeEmployeesIncome());
        dispatch(getEmployeeIncome({ data }));
      });
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <Datepicker
      i18n={"el"}
      toggleClassName={"absolute top-3 left-5"}
      toggleIcon={() => <CalendarIcon className={"w-5 h-5 text-white "} />}
      containerClassName={"w-full h-full right-0 cursor-pointer p-0"}
      inputClassName={
        "text-sm w-full cursor-pointer text-center bg-primary text-white placeholder:text-white p-0 outline-none"
      }
      showShortcuts={true}
      showFooter={true}
      // configs={{
      //   shortcuts: {
      //     today: "Σήμερα",
      //     yesterday: "Χθές",
      //     past: (period) => `P-${period} Text`,
      //     currentMonth: "Αυτός ο μήνας",
      //     pastMonth: "Προηγούμενος μήνας",
      //   },
      //   footer: {
      //     cancel: "CText",
      //     apply: "AText",
      //   },
      // }}
      readOnly
      value={value}
      onChange={handleValueChange}
    />
  );
};

export default CalendarRangePicker;
