import { useEffect } from "react";
import { gapi } from "gapi-script";
import { clientId } from "../Utilities/utilities";

export const ConnectWithGoogleApi = () => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "openid",
      });
    }

    gapi.load("client:auth2", start);
  }, []);
};
