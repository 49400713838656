import { styles } from "./styles";
import { useSelector } from "react-redux";
import { turnPixelsIntoHoursAndMinutes } from "../../Utilities/utilities";
import { TimeThatAppointmentIsBeingDraggedAt } from "../TimeThatAppointmentIsBeingDroppedAt/TimeThatAppointmentIsBeingDraggedAt";

const LeftPartShowingHours = () => {
  const indexOfDayThatAppointmentIsBeingDraggedOn = useSelector(
    (state) => state.appointment.indexOfDayThatAppointmentIsDraggedOn,
  );
  const isAppointmentBeingDragged = useSelector(
    (state) => state.appointment.isAppointmentBeingDragged,
  );
  const numberOfMultipleWorkersShown = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );
  let arr = [];

  for (let i = 0; i < 24; i++) {
    arr.push(i);
  }
  return (
    <div
      className={"leftPart border-r"}
      style={{
        height: "2880px",
        position: "sticky",
        left: 0,
        top: numberOfMultipleWorkersShown ? 76 : 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        zIndex: 10,
        background: "white",
        color: "gray",
      }}
    >
      <div style={{ position: "relative" }} className={"h-full"}>
        {arr.map((time, index) => (
          <div
            key={time}
            style={{
              top: `${time * 120}px`,
              display: "flex",
              height: "120px",
              alignItems: "start",
              width: "35.41px",
            }}
            className={`sticky p-0 text-right text-xs font-semibold text-white`}
          >
            <div
              className={`absolute  ${index === 0 ? "top-0" : "top-[-8px]"} text-black`}
            >
              {time}:00
            </div>
            {/*{time}:00*/}
          </div>
        ))}
      </div>
      {indexOfDayThatAppointmentIsBeingDraggedOn === 0 &&
        isAppointmentBeingDragged && (
          <TimeThatAppointmentIsBeingDraggedAt isFromLeftPartShowingHours />
        )}
    </div>
  );
};

export default LeftPartShowingHours;
