import theme from "../../theme";

const {createStyles} = require("@mui/styles");


export const styles = createStyles(
    {

        day: {
            display: "flex",
            flexDirection: "row",
            minHeight: '100px',
            justifyContent: 'start',

            width: '100%',
            alignItems: "center",


        }, dayWithBorder: {
            display: "flex",
            flexDirection: "row",
            minHeight: '100px',
            justifyContent: 'start',
            borderBottom: '1px solid gray',
            width: '100%',
            alignItems: "center",


        },


    })
