import dayjs from "dayjs";
import Request from "./Request";
import utc from "dayjs/plugin/utc";
import { getMinutes } from "@mui/x-date-pickers/TimeClock/shared";

dayjs.extend(utc);

export const printHourDiv = () => {
  let hoursOfDay = 24;
  let hourDivs = [];
  let spaces = 60 / 15; //spaces in an hour ( 4 15minute slots)
  for (let i = 0; i < hoursOfDay; i++) {
    let fiveMinutesInAnHour = [];
    for (let x = 0; x < spaces; x++) {
      fiveMinutesInAnHour.push(x);
    }
    hourDivs.push({
      time: dayjs().hour(i),
      slots: fiveMinutesInAnHour,
    });
  }
  return hourDivs;
};

export const clientId =
  "430690229215-4v527q5hk52q3fnh2qe3tcqfib1vkkoo.apps.googleusercontent.com";

export const weekCreator = [
  {
    type: "add",
    number: 0,
  },
  { type: "add", number: 1 },
  {
    type: "add",
    number: 2,
  },
  { type: "add", number: 3 },
  { type: "add", number: 4 },
  { type: "add", number: 5 },
  { type: "add", number: 6 },
];
export const twoDayCreator = [
  { type: "add", number: 0 },
  { type: "add", number: 1 },
];
export const threeDayCreator = [
  { type: "add", number: 0 },
  { type: "add", number: 1 },
  { type: "add", number: 2 },
];
export const oneDayCreator = [{ type: "add", number: 0 }];

export const indexOfDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
export const indexOfDaysReversed = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};
const uploadSingleImage = async (base64, place) => {
  try {
    const res = await Request.post("/upload-image", {
      body: {
        image: base64,
        place,
      },
    });
  } catch (e) {
    throw new Error(e);
  }
};

export const doTwoArraysContainTheSameValues = (array1, array2) => {
  let areTheArraysTheSame = true;
  if (array1?.length !== array2?.length) {
    areTheArraysTheSame = false;
    return areTheArraysTheSame;
  }

  array1.map((arrItem) => {
    if (array2?.filter((arr2Item) => arr2Item === arrItem)?.length === 0) {
      areTheArraysTheSame = false;
      return areTheArraysTheSame;
    }
  });
  return areTheArraysTheSame;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const uploadImage = async (event, place) => {
  const files = event.target.files;

  if (files?.length === 1) {
    const base64 = await convertBase64(files[0]);
    return await uploadSingleImage(base64, place);
  }
};

export const hoursInDayArrayConstructor = (afterNum) => {
  let hours = 24;
  let arr = [];
  for (let i = afterNum ? parseInt(afterNum) + 1 : 0; i < hours; i++) {
    arr.push(i < 10 ? "0" + i : i);
  }

  return arr;
};
export const thirtyMinutesInAnHour = () => {
  let thirtyMinutesPerHour = 2;
  let arr = [];
  for (let i = 0; i !== thirtyMinutesPerHour; i++) {
    if (i * 15 === 0) {
      arr.push("00");
    } else {
      arr.push(i * 30);
    }
  }

  return arr;
};

export const isDateGivenTheSameOrBetweenTwoDateObjects = (
  dateWeWantToCompare,
  firstDateObject,
  secondDateObject,
) => {
  if (
    dayjs(firstDateObject).isSame(dayjs(dateWeWantToCompare), "minutes") ||
    dayjs(secondDateObject).isSame(dayjs(dateWeWantToCompare), "minutes") ||
    dayjs(dateWeWantToCompare).isBetween(
      dayjs(firstDateObject),
      dayjs(secondDateObject),
      "minutes",
    )
  ) {
    return true;
  }
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const turnPixelsIntoHoursAndMinutes = (pixels) => {
  // Calculate hours
  let hours = Math.floor(pixels / 120);

  // Calculate remaining pixels for minutes
  const remainingPixels = pixels % 120;

  // Calculate minutes
  let minutes = Math.floor(remainingPixels / 2);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return { hours, minutes };
};
export const turnPixelsIntoMinutes = (pixels) => {
  // Calculate hours
  let hours = Math.floor(pixels / 120);

  // Calculate remaining pixels for minutes
  const remainingPixels = pixels % 120;

  // Calculate minutes
  let minutes = Math.floor(remainingPixels / 2);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return parseInt(hours * 60) + parseInt(minutes);
};

export const handleDragOver = (e) => {
  e.preventDefault();
};

export const combineManualTopWithManualDateAndReturnDateInFormatThatBackendUnderstands =
  (date, topInPixels) => {
    try {
      const newDate = dayjs(
        new Date(
          dayjs(date).format("YYYY"),
          dayjs(date).format("MM") - 1,
          dayjs(date).format("DD"),
          turnPixelsIntoHoursAndMinutes(topInPixels).hours,
          turnPixelsIntoHoursAndMinutes(topInPixels).minutes,
        ),
      );
      return dayjs(newDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } catch (e) {
      console.log(e);
    }
  };

export const weekWithEmptyShifts = () => {
  return {
    1: { id: null, shifts: [] },
    2: { id: null, shifts: [] },
    3: { id: null, shifts: [] },
    4: { id: null, shifts: [] },
    5: { id: null, shifts: [] },
    6: { id: null, shifts: [] },
    0: { id: null, shifts: [] },
  };
};

export const turnTimeStringToNumber = (timeString) => {
  return (
    parseInt(timeString.split(":")[0]) * 60 + parseInt(timeString.split(":")[1])
  );
};

export const turnShiftsHoursToNumbers = (dayWithShiftsArray) => {
  let newDayWithShiftsArray = [...dayWithShiftsArray];
  let arrayToBeSend = [...dayWithShiftsArray];
  newDayWithShiftsArray.map((dayWithShifts) => {
    let newObjDayWithShifts = { ...dayWithShifts };
    let firstShiftStart = newObjDayWithShifts.firstShiftStart;
    let firstShiftEnd = newObjDayWithShifts.firstShiftEnd;
    let secondShiftStart = newObjDayWithShifts.secondShiftStart;
    let secondShiftEnd = newObjDayWithShifts.secondShiftEnd;

    let firstShiftStartToNumber =
      parseInt(firstShiftStart.split(":")[0]) * 60 +
      parseInt(firstShiftStart.split(":")[1]);
    let firstShiftEndToNumber =
      parseInt(firstShiftEnd.split(":")[0]) * 60 +
      parseInt(firstShiftEnd.split(":")[1]);
    let secondShiftStartToNumber =
      parseInt(secondShiftStart.split(":")[0]) * 60 +
      parseInt(secondShiftStart.split(":")[1]);
    let secondShiftEndToNumber =
      parseInt(secondShiftEnd.split(":")[0]) * 60 +
      parseInt(secondShiftEnd.split(":")[1]);

    newObjDayWithShifts.firstShiftStart = `${firstShiftStartToNumber}`;
    newObjDayWithShifts.firstShiftEnd = `${firstShiftEndToNumber}`;
    newObjDayWithShifts.secondShiftStart = `${secondShiftStartToNumber}`;
    newObjDayWithShifts.secondShiftEnd = `${secondShiftEndToNumber}`;

    let arrToBeSetWithChangedShiftTimes = arrayToBeSend?.filter(
      (dayObj) => dayObj.day !== dayWithShifts.day,
    );
    arrayToBeSend = [...arrToBeSetWithChangedShiftTimes, newObjDayWithShifts];
  });

  return arrayToBeSend;
};

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const returnADateObjectInWhichTheYearMonthAndDayRemainTheSameButOnlyTheHoursAndMinutesChange =
  (
    dateObjectFromWhichWeWillHoldYearMonthAndDate,
    pixelsToCalculateHoursAndMinutes,
  ) => {
    return dayjs(
      new Date(
        parseInt(
          dayjs(dateObjectFromWhichWeWillHoldYearMonthAndDate).format("YYYY"),
        ),
        parseInt(
          dayjs(dateObjectFromWhichWeWillHoldYearMonthAndDate).format("MM"),
        ) - 1,
        parseInt(
          dayjs(dateObjectFromWhichWeWillHoldYearMonthAndDate).format("DD"),
        ),
        turnPixelsIntoHoursAndMinutes(pixelsToCalculateHoursAndMinutes).hours,
        turnPixelsIntoHoursAndMinutes(pixelsToCalculateHoursAndMinutes).minutes,
      ),
    ).$d;
  };

export function roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(
  pixels,
) {
  // Assuming 120 pixels represent 60 minutes (1 hour)
  const pixelsPerMinute = 120 / 60;

  // Calculate the equivalent minutes
  const minutes = pixels / pixelsPerMinute;

  // Round the minutes to the nearest 10 minutes
  let roundedMinutes = Math.round(minutes / 10) * 10;

  // if (roundedMinutes < 10) {
  //     roundedMinutes = 0
  // }
  // if (roundedMinutes > 10 && roundedMinutes < 20) {
  //     roundedMinutes = 10
  // }
  // if (roundedMinutes > 20 && roundedMinutes < 30) {
  //     roundedMinutes = 20
  // }
  // if (roundedMinutes > 30 && roundedMinutes < 40) {
  //     roundedMinutes = 30
  // }
  // if (roundedMinutes > 40 && roundedMinutes < 50) {
  //     roundedMinutes = 40
  // }
  // if (roundedMinutes > 50 && roundedMinutes < 60) {
  //     roundedMinutes = 50
  // }

  if (roundedMinutes >= 0 && roundedMinutes < 20) {
    roundedMinutes = 0;
  }
  if (roundedMinutes >= 20 && roundedMinutes < 40) {
    roundedMinutes = 30;
  }
  if (roundedMinutes >= 40 && roundedMinutes <= 60) {
    roundedMinutes = 60;
  }

  // console.log(roundedMinutes * pixelsPerMinute, 'pixelsOut')

  // Convert the rounded minutes back to pixels
  return roundedMinutes * pixelsPerMinute;
}

export function validateWebsite(website) {
  return website.match(
    "^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$",
  );
}

export function removeNonUsedDataFromEmployeeShiftsArray(allWeekShiftsArray) {
  const arr = [];
  allWeekShiftsArray.map((shiftObj) => {
    shiftObj = { ...shiftObj };
    arr.push({
      id: shiftObj.id,
      day: shiftObj.day,
      firstShiftStart:
        shiftObj?.firstShiftStart !== "00:00"
          ? dayjs(shiftObj?.firstShiftStart, "HH:mm")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : dayjs().utc().format("YYYY-MM-DDT00:00:ss.SSS[Z]"),
      firstShiftEnd:
        shiftObj?.firstShiftEnd !== "00:00"
          ? dayjs(shiftObj?.firstShiftEnd, "HH:mm")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : dayjs().utc().format("YYYY-MM-DDT00:00:ss.SSS[Z]"),
      secondShiftStart:
        shiftObj?.secondShiftStart !== "00:00"
          ? dayjs(shiftObj?.secondShiftStart, "HH:mm")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : dayjs().utc().format("YYYY-MM-DDT00:00:ss.SSS[Z]"),
      secondShiftEnd:
        shiftObj?.secondShiftEnd !== "00:00"
          ? dayjs(shiftObj?.secondShiftEnd, "HH:mm")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : dayjs().utc().format("YYYY-MM-DDT00:00:ss.SSS[Z]"),
    });
  });

  return arr;
}

export function modifyShopShiftsToUtcTime(allWeekShiftsArray) {
  const arr = [];
  allWeekShiftsArray.map((shiftObj) => {
    shiftObj = { ...shiftObj };
    arr.push({
      id: shiftObj.id,
      day: shiftObj.day,
      firstShiftStart:
        shiftObj?.firstShiftStart !== "00:00"
          ? dayjs(shiftObj?.firstShiftStart, "HH:mm").utc().format("HH:mm")
          : dayjs().utc().format("00:00"),
      firstShiftEnd:
        shiftObj?.firstShiftEnd !== "00:00"
          ? dayjs(shiftObj?.firstShiftEnd, "HH:mm").utc().format("HH:mm")
          : dayjs().utc().format("00:00"),
      secondShiftStart:
        shiftObj?.secondShiftStart !== "00:00"
          ? dayjs(shiftObj?.secondShiftStart, "HH:mm").utc().format("HH:mm")
          : dayjs().utc().format("00:00"),
      secondShiftEnd:
        shiftObj?.secondShiftEnd !== "00:00"
          ? dayjs(shiftObj?.secondShiftEnd, "HH:mm").utc().format("HH:mm")
          : dayjs().utc().format("00:00"),
    });
  });

  return arr;
}

export function turnPayloadFromBackendToHaveLocalTimeNotUtc(
  allWeekShiftsArray,
) {
  const arr = [];
  allWeekShiftsArray?.map((shiftObj) => {
    shiftObj = { ...shiftObj };
    arr.push({
      id: shiftObj.id,
      day: shiftObj.day,
      firstShiftStart:
        shiftObj?.firstShiftStart !== "00:00"
          ? dayjs
              .utc(shiftObj?.firstShiftStart, "HH:mm")
              .local()
              .format("HH:mm")
          : "00:00",
      firstShiftEnd:
        shiftObj?.firstShiftEnd !== "00:00"
          ? dayjs.utc(shiftObj?.firstShiftEnd, "HH:mm").local().format("HH:mm")
          : "00:00",
      secondShiftStart:
        shiftObj?.secondShiftStart !== "00:00"
          ? dayjs
              .utc(shiftObj?.secondShiftStart, "HH:mm")
              .local()
              .format("HH:mm")
          : "00:00",
      secondShiftEnd:
        shiftObj?.secondShiftEnd !== "00:00"
          ? dayjs.utc(shiftObj?.secondShiftEnd, "HH:mm").local().format("HH:mm")
          : "00:00",
    });
  });

  return [...arr];
}

export function turnSingleShiftBackendToHaveLocalTimeNotUtc(
  allWeekShiftsArray,
) {
  let newShift = { ...allWeekShiftsArray };

  newShift = {
    id: newShift.id,
    day: newShift.day,
    firstShiftStart:
      newShift?.firstShiftStart !== "00:00"
        ? dayjs.utc(newShift?.firstShiftStart, "HH:mm").local().format("HH:mm")
        : "00:00",
    firstShiftEnd:
      newShift?.firstShiftEnd !== "00:00"
        ? dayjs.utc(newShift?.firstShiftEnd, "HH:mm").local().format("HH:mm")
        : "00:00",
    secondShiftStart:
      newShift?.secondShiftStart !== "00:00"
        ? dayjs.utc(newShift?.secondShiftStart, "HH:mm").local().format("HH:mm")
        : "00:00",
    secondShiftEnd:
      newShift?.secondShiftEnd !== "00:00"
        ? dayjs.utc(newShift?.secondShiftEnd, "HH:mm").local().format("HH:mm")
        : "00:00",
  };

  return { ...newShift };
}

export const monthsEnglishToGreek = {
  January: "Ιανουάριος",
  February: "Φεβρουάριος",
  March: "Μάρτιος",
  April: "Απρίλιος",
  May: "Μάιος",
  June: "Ιούνιος",
  July: "Ιούλιος",
  August: "Αύγουστος",
  September: "Σεπτέμβριος",
  October: "Οκτώβριος",
  November: "Νοέμβριος",
  December: "Δεκέμβριος",
};

export const daysEnglishToGreek = {
  Sun: "Κυρ",
  Mon: "Δευ",
  Tue: "Τρί",
  Wed: "Τετ",
  Thu: "Πέμ",
  Fri: "Παρ",
  Sat: "Σάβ",
};

export const fullDaysEnglishToGreek = {
  Monday: "Δευτέρα",
  Tuesday: "Τρίτη",
  Wednesday: "Τετάρτη",
  Thursday: "Πέμπτη",
  Friday: "Παρασκευή",
  Saturday: "Σάββατο",
  Sunday: "Κυριακή",
};

export const monthsEnglishToGreekInOu = {
  January: "Ιανουαρίου",
  February: "Φεβρουαρίου",
  March: "Μαρτίου",
  April: "Απριλίου",
  May: "Μαΐου",
  June: "Ιουνίου",
  July: "Ιουλίου",
  August: "Αυγούστου",
  September: "Σεπτεμβρίου",
  October: "Οκτωβρίου",
  November: "Νοεμβρίου",
  December: "Δεκεμβρίου",
};

export const checkEmptyProperties = (obj = {}) => {
  let emptyPropertyNames = [];
  for (let key in obj) {
    if (!obj[key]) {
      emptyPropertyNames.push(key);
    }
  }
  return emptyPropertyNames;
};

export const returnColorOfTextBasedOnColorOfAppointment = (color) => {
  let textColor;
  switch (color) {
    case "#ffd950":
      textColor = "text-gray-800";
      break;
    case "#03b277":
      textColor = "text-white";
      break;
    case "#BBABFFC5":
      textColor = "text-gray-800";
      break;
    case "#c3ddff":
      textColor = "text-gray-800";
      break;
    case "#6c32f5":
      textColor = "text-white";
      break;
    case "#5e7cc2":
      textColor = "text-white";
      break;
    default:
      textColor = "text-primary-900";
  }
  return textColor;
};

export const getTotalsOfAllTheServices = (selectedServiceForAppointment) => {
  let nameOfAll = "";
  let priceOfAll = 0;
  let durationOfAll = 0;
  let idsOfAll = [];
  selectedServiceForAppointment?.map((selectedService) => {
    nameOfAll += selectedService.name + ", ";

    priceOfAll += parseInt(selectedService?.finalPrice);
    durationOfAll += parseInt(selectedService?.duration);
    idsOfAll.push(selectedService?.id);
  });

  return {
    nameOfAll: nameOfAll?.slice(0, -2),
    priceOfAll,
    durationOfAll,
    idsOfAll,
  };
};

export const doesAppointmentHaveTheSameServicesWithBefore = (
  appointmentServices,
  selectedServices,
) => {
  let areSame = true;
  if (appointmentServices?.length !== selectedServices?.length) {
    areSame = false;
    return areSame;
  }

  selectedServices.map((selectedService) => {
    if (
      !appointmentServices?.filter(
        (appointmentService) =>
          appointmentService?.branchServiceId ===
          selectedService?.brandchServiceId,
      )?.length > 0
    ) {
      areSame = false;
      return areSame;
    }
  });

  return areSame;
};

export const isResponseSuccessful = (response) => {
  return response?.status !== "error";
};
export const produceError = (response) => {
  throw new Error(response?.message ? response.message : "Κάτι πήγε στραβά");
};

export const genderValues = [
  { id: 1, name: "Male", gr: "Άντρας" },
  { id: 2, name: "Female", gr: "Γυναίκα" },
  { id: 3, name: "Both", gr: "Και οι δύο" },
];

export const turnServicesFromBranchIdsToEmployeeIds = (
  appointmentToModify,
  employees,
) => {
  let appointment = { ...appointmentToModify };
  appointment.services = [...appointment.services];
  const employeeToUse = employees?.filter(
    (employee) => employee?.id === appointment?.employeeId,
  )?.[0];
  appointment?.services?.map((branchService, i) => {
    employeeToUse?.services?.map((employeeService) => {
      if (employeeService?.branchServiceId === branchService?.id) {
        appointment.services[i] = { id: employeeService?.id };
      }
    });
  });
  return appointment;
};

function convertMinutesToHoursAndMinutes(minutes) {
  // Calculate hours and minutes
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;
  let result;
  // Format the result
  if (hours > 0) {
    result = hours + " ώρες ";
  }

  if (remainingMinutes !== 0) {
    if (result) {
      result += remainingMinutes + " λεπτά";
    }
    if (!result) {
      result = remainingMinutes + " λεπτά";
    }
  }

  return result;
}
export const produceMinutesUntilFiveHours = () => {
  getMinutes();
  const slots = 20;
  let arrToReturn = [];
  for (let i = 1; i < slots; i++) {
    arrToReturn.push({
      text: convertMinutesToHoursAndMinutes(i * 15),
      value: i * 15,
    });
  }

  return arrToReturn;
};

export const formatForDayJsForBackend = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
