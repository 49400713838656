import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getShop } from "../shop/shop-slice";
const initialState = {
  // [{ id:..., day:0, shift:5-9 }]
  branchShifts: null,

  isLoading: false,
};
export const getBranchShifts = createAsyncThunk(
  "shifts/getShifts",
  async (_, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.post(
      `BranchShifts/get-branch-shifts/${shop?.id}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const saveBranchShifts = createAsyncThunk(
  "shifts/saveShifts",
  async ({ weekShifts }, thunkAPI) => {
    const res = await Request.put("BranchShifts/edit-branch-shift", {
      body: weekShifts,
    });
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchShifts());
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const shiftsSlice = createSlice({
  name: "Shifts",
  initialState,
  reducers: {
    setBranchShifts(state, action) {
      state.branchShifts = action.payload;
    },
    setEmployeeShifts(state, action) {
      state.branchShifts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.branchShifts = action.payload;
      })
      .addCase(getBranchShifts.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(saveBranchShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveBranchShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.branchShifts = action.payload;
        toast.success("Το ωράριο αποθηκεύτηκε επιτυχώς");
      })
      .addCase(saveBranchShifts.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(getShop.fulfilled, (state, action) => {
        state.branchShifts = action?.payload?.shifts;
      });
  },
});

export const { setBranchShifts } = shiftsSlice.actions;

export default shiftsSlice.reducer;
