import {useState} from "react";
import {
    SignInForm
} from "../../Components/SignInForm/SignInForm";
import {
    SignUpForm
} from "../../Components/SignUpForm/SignUpForm";
import logo from './IMG_7339.jpg'

const LoginSignUpResetPass = () => {
    const register = 'register'
    const resetPass = 'reset-pass'
    const loginAsEmployee = 'employee-login'
    const loginAsOwner = 'owner-login'
    const [whichTypeOfActionDoesUserWantToDo, setWhichTypeOfActionDoesUserWantToDo] = useState('owner-login')//login , register, reset-pass

    const resetPasswordAction = async () => {

    }


    return (
        <div
        >
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    width: '100%',
                    justifyContent: "center",
                }}>
                <img src={logo}
                     alt={'logo of booke'} height={100}
                     width={100}/></div>
            {whichTypeOfActionDoesUserWantToDo === loginAsOwner &&
                <SignInForm
                    asOwner
                    setUserWantsLoginAsAnEmployee={() => setWhichTypeOfActionDoesUserWantToDo(loginAsEmployee)}
                    setUserWantsToRegister={() => {
                        setWhichTypeOfActionDoesUserWantToDo(register)
                    }}/>}
            {whichTypeOfActionDoesUserWantToDo === loginAsEmployee &&
                <SignInForm
                    asEmployee
                    setUserWantsLoginAsAnOwner={() => setWhichTypeOfActionDoesUserWantToDo(loginAsOwner)}
                    setUserWantsToRegister={() => {
                        setWhichTypeOfActionDoesUserWantToDo(register)
                    }}/>}
            {whichTypeOfActionDoesUserWantToDo === register &&
                <SignUpForm
                    setUserWantsToSignIn={() => {
                        setWhichTypeOfActionDoesUserWantToDo(loginAsOwner)
                    }}/>}


        </div>)
}


export default LoginSignUpResetPass
