import { turnPixelsIntoHoursAndMinutes } from "../../Utilities/utilities";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const TimeThatAppointmentIsBeingDraggedAt = ({
  indexOfWorkerThatTheDayRepresents,
  indexOfDay,
  isFromLeftPartShowingHours,
}) => {
  const pixelThatCursorIsOnDuringDrag = useSelector(
    (state) => state.appointment.pixelThatCursorIsOnDuringDrag,
  );
  const isAppointmentBeingDragged = useSelector(
    (state) => state.appointment.isAppointmentBeingDragged,
  );
  const indexOfDayThatAppointmentIsBeingDraggedOn = useSelector(
    (state) => state.appointment.indexOfDayThatAppointmentIsDraggedOn,
  );
  const indexOfEmployeeThatAppointmentIsBeingDraggedOn = useSelector(
    (state) => state.appointment.indexOfWorkerThatAppointmentIsDraggedOn,
  );
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const numberOfMultipleWorkersShown = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );

  useEffect(() => {
    if (isAppointmentBeingDragged) {
      if (!numberOfMultipleWorkersShown) {
        if (
          pixelThatCursorIsOnDuringDrag &&
          indexOfDay === indexOfDayThatAppointmentIsBeingDraggedOn - 1
        ) {
          setShouldDisplay(true);
        }

        if (isFromLeftPartShowingHours) {
          setShouldDisplay(true);
        }

        if (
          indexOfDay !== indexOfDayThatAppointmentIsBeingDraggedOn - 1 &&
          !isFromLeftPartShowingHours
        ) {
          setShouldDisplay(false);
        }
      }
      if (numberOfMultipleWorkersShown >= 2) {
        //if second day and first employee
        if (
          indexOfDayThatAppointmentIsBeingDraggedOn === 0 &&
          indexOfEmployeeThatAppointmentIsBeingDraggedOn === 0 &&
          isFromLeftPartShowingHours
        ) {
          setShouldDisplay(true);
        }

        //if first day, second employee
        if (
          indexOfDayThatAppointmentIsBeingDraggedOn === 0 &&
          indexOfDay === 0 &&
          indexOfEmployeeThatAppointmentIsBeingDraggedOn === 1 &&
          indexOfWorkerThatTheDayRepresents === 0
        ) {
          setShouldDisplay(true);
        }

        //if second day, first employee
        if (
          indexOfDayThatAppointmentIsBeingDraggedOn === 1 &&
          indexOfDay === 0 &&
          indexOfEmployeeThatAppointmentIsBeingDraggedOn === 0 &&
          indexOfWorkerThatTheDayRepresents === 1
        ) {
          setShouldDisplay(true);
        }
        //if second day, second employee

        if (
          indexOfDayThatAppointmentIsBeingDraggedOn === 1 &&
          indexOfDay === 1 &&
          indexOfEmployeeThatAppointmentIsBeingDraggedOn === 1 &&
          indexOfWorkerThatTheDayRepresents === 0
        ) {
          setShouldDisplay(true);
        }

        if (
          !(
            indexOfDayThatAppointmentIsBeingDraggedOn === 0 &&
            indexOfEmployeeThatAppointmentIsBeingDraggedOn === 0 &&
            isFromLeftPartShowingHours
          ) &&
          !(
            indexOfDayThatAppointmentIsBeingDraggedOn === 0 &&
            indexOfDay === 0 &&
            indexOfEmployeeThatAppointmentIsBeingDraggedOn === 1 &&
            indexOfWorkerThatTheDayRepresents === 0
          ) &&
          !(
            indexOfDayThatAppointmentIsBeingDraggedOn === 1 &&
            indexOfDay === 0 &&
            indexOfEmployeeThatAppointmentIsBeingDraggedOn === 0 &&
            indexOfWorkerThatTheDayRepresents === 1
          ) &&
          !(
            indexOfDayThatAppointmentIsBeingDraggedOn === 1 &&
            indexOfDay === 1 &&
            indexOfEmployeeThatAppointmentIsBeingDraggedOn === 1 &&
            indexOfWorkerThatTheDayRepresents === 0
          )
        ) {
          setShouldDisplay(false);
        }
      }
    }
    if (!isAppointmentBeingDragged) {
      setShouldDisplay(false);
    }
  }, [
    indexOfEmployeeThatAppointmentIsBeingDraggedOn,
    indexOfDayThatAppointmentIsBeingDraggedOn,
    indexOfEmployeeThatAppointmentIsBeingDraggedOn,
    isAppointmentBeingDragged,
  ]);

  if (shouldDisplay) {
    return (
      <span
        onClick={() => {}}
        className={
          "text-xs text-white leading-5   shadow-lg font-bold  rounded bg-primary p-1"
        }
        key={pixelThatCursorIsOnDuringDrag}
        style={{
          display: "block",
          right: isFromLeftPartShowingHours ? "-5px" : 0,
          padding: 1,
          top: `${parseInt(pixelThatCursorIsOnDuringDrag) ? pixelThatCursorIsOnDuringDrag : 0}px`,
          position: "absolute",
        }}
      >
        {turnPixelsIntoHoursAndMinutes(pixelThatCursorIsOnDuringDrag).hours}:
        {turnPixelsIntoHoursAndMinutes(pixelThatCursorIsOnDuringDrag).minutes}
      </span>
    );
  }
};
