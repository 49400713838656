import { PhotoIcon } from "@heroicons/react/24/solid";

import { useDispatch, useSelector } from "react-redux";
import {
  isShopPortraitLoading,
  uploadShopBannerImage,
} from "../../Features/images/images-slice";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";

export function UploadImage() {
  const dispatch = useDispatch();
  const shopImage = useSelector((state) => state.shop?.portraitUrl);
  const isUploadingLoading = useSelector(isShopPortraitLoading);

  const WrapperOfUpload = ({ children }) => {
    return (
      <>
        <div className="mt-10 space-y-12">
          <div className="col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Κύρια Φωτογραφία
            </label>
            <div className="mt-2 flex max-w-[350px] overflow-hidden justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              {children}
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!shopImage && !isUploadingLoading) {
    return (
      <WrapperOfUpload>
        <div className="text-center">
          <PhotoIcon
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <span>Ανέβασμα φωτογραφίας</span>
              <input
                onChange={async (e) =>
                  dispatch(uploadShopBannerImage({ event: e }))
                }
                id="file-upload"
                name="file-upload"
                multiple={false}
                type="file"
                className="sr-only"
              />
            </label>
          </div>
          <p className="text-xs leading-5 text-gray-600">PNG, JPG, WEBP</p>
        </div>
      </WrapperOfUpload>
    );
  }
  if (shopImage && !isUploadingLoading) {
    return (
      <WrapperOfUpload>
        <>
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <img className={"object-contain "} src={shopImage} />
              <span>Αλλαγή κύριας φωτογραφίας</span>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
              <input
                onChange={async (e) =>
                  dispatch(uploadShopBannerImage({ event: e }))
                }
                id="file-upload"
                name="file-upload"
                multiple={false}
                type="file"
                className="sr-only"
              />
            </label>
          </div>
        </>
      </WrapperOfUpload>
    );
  }
  if (isUploadingLoading) {
    return (
      <WrapperOfUpload>
        <SimpleLoader />
      </WrapperOfUpload>
    );
  }
}
