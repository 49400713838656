import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import EditService from "../EditService/EditService";
import { useEffect, useState } from "react";

export const EditServiceSlideOver = ({
  selectedServiceToBeEdited,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!selectedServiceToBeEdited) {
      setOpen(false);
    }

    if (selectedServiceToBeEdited) {
      setOpen(true);
    }
  }, [selectedServiceToBeEdited]);

  return (
    <SlideOverWithCloseButtonOnOutside
      open={open}
      setOpen={setOpen}
      title={"Επεξεργασία υπηρεσίας"}
    >
      <EditService
        selectedServiceToBeEdited={selectedServiceToBeEdited}
        onCancel={onCancel}
      />
    </SlideOverWithCloseButtonOnOutside>
  );
};
