import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SimpleAlertWithActions from "../../Components/SimpleAlertWithActions/SimpleAlertWithActions";
import EditClient from "../../Components/EditClient/EditClient";
import { validateEmail } from "../../Utilities/validateEmail";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function ClientsPage() {
  const clients = useSelector((state) => state.clients?.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddClientShown, setIsAddClientShown] = useState(false);
  const dispatch = useDispatch();
  const [clientToBeDeleted, setClientToBeDeleted] = useState(null);
  const [clientToBeEdited, setClientToBeEdited] = useState(null);
  const [searchText, setTextSearch] = useState("");
  const [
    clientsFromShopWhoMatchSearchText,
    setClientsFromShopWhoMatchSearchText,
  ] = useState(clients);

  useEffect(() => {
    if (searchText === "") {
      setClientsFromShopWhoMatchSearchText(clients);
    }

    if (searchText.length > 0) {
      setClientsFromShopWhoMatchSearchText(
        clients?.filter(
          (client) =>
            client.firstName?.includes(searchText) ||
            client.lastName?.includes(searchText) ||
            client.phone?.includes(searchText) ||
            client?.email?.includes(searchText),
        ),
      );
    }
  }, [searchText]);

  useEffect(() => {
    if (clientToBeDeleted) {
      setIsModalVisible(true);
    }
    if (!clientToBeDeleted) {
      setIsModalVisible(false);
    }
  }, [clientToBeDeleted]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <SimpleAlertWithActions
        open={isModalVisible}
        setOpen={setIsModalVisible}
        title={"Κατάργηση πελάτη"}
        message={
          "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον πελάτη; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί."
        }
        onAccept={() => {
          setClientToBeDeleted(null);
        }}
        onCancel={() => setClientToBeDeleted(null)}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Πελάτες
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Μία λίστα με όλους τους πελάτες του μαγαζιού
          </p>
        </div>
        {/*{!isAddClientShown && <div*/}
        {/*    className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">*/}
        {/*    <button*/}
        {/*        type="button"*/}
        {/*        className="block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
        {/*        onClick={() => {*/}
        {/*            setIsAddClientShown(true)*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        Προσθήκη Πελάτη*/}
        {/*    </button>*/}
        {/*</div>}*/}
      </div>
      {/*{isAddClientShown &&*/}
      {/*    <AddClient onCancel={() => {*/}
      {/*        setIsAddClientShown(false)*/}
      {/*    }}/>}*/}
      {clientToBeEdited && (
        <EditClient
          clientToBeEdited={clientToBeEdited}
          onCancel={() => {
            setClientToBeEdited(null);
          }}
        />
      )}

      <div className="mt-8 flow-root">
        <div className={"block md:flex justify-end"}>
          <div className="relative mt-2 max-w-lg flex items-end">
            <input
              onChange={(e) => setTextSearch(e.target.value)}
              placeholder={"Αναζήτηση Πελάτη"}
              type="text"
              name="search"
              id="search"
              className="block text-sm pl-2 w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <MagnifyingGlassIcon />
            </div>
          </div>
        </div>

        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Όνομα
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Τηλέφωνο
                  </th>

                  {/*<th scope="col"*/}
                  {/*    className="relative py-3.5 pl-3 pr-4 sm:pr-3">*/}
                  {/*    <span*/}
                  {/*        className="sr-only ">Διαγραφή</span>*/}
                  {/*</th>*/}
                  {/*<th scope="col"*/}
                  {/*    className="relative py-3.5 pl-3 pr-4 sm:pr-3">*/}
                  {/*    <span*/}
                  {/*        className="sr-only ">Επεξεργασία</span>*/}
                  {/*</th>*/}
                </tr>
              </thead>
              <tbody className="bg-white">
                {clientsFromShopWhoMatchSearchText?.map((client, index) => (
                  <tr key={client.email} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {client.firstName + " " + client.lastName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {validateEmail(client.email) ? client.email : "-"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {client.phone ? client.phone : "-"}
                    </td>
                    {/*<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">*/}
                    {/*    <a onClick={() => {*/}
                    {/*        setClientToBeEdited(client)*/}
                    {/*    }}*/}
                    {/*       className="cursor-pointer text-indigo-600 hover:text-indigo-900">*/}
                    {/*        Επεξεργασία*/}
                    {/*    </a>*/}
                    {/*</td>*/}
                    {/*<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">*/}

                    {/*    <a onClick={() => {*/}
                    {/*        setClientToBeDeleted(client)*/}
                    {/*    }}*/}
                    {/*       className="cursor-pointer text-red-600 hover:text-indigo-900">*/}
                    {/*        Διαγραφή*/}
                    {/*    </a>*/}
                    {/*</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
