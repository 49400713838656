import { Link, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../Utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@mui/material";
import Logo from "../../Images/logo.svg";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { logout } from "../../Features/authorizeUser/authorizeUser-slice";

const DesktopNavigationSideBar = ({ urls }) => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const personalInfo = useSelector((state) => state.personalData);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div
      className={`hidden lg:inset-y-0 lg:fixed lg:z-50 lg:flex lg:w-14 lg:flex-col`}
    >
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex pt-3 grow flex-col gap-y-5  overflow-hidden bg-primary-600">
        <nav className="flex flex-1 flex-col w-full">
          <ul
            role="list"
            className="flex flex-1 w-full flex-col gap-y-7 items-center "
          >
            <Link
              to={"/calendar"}
              className={"shadow-2xl p-1 bg-white rounded-xl"}
            >
              <img src={Logo} className={"w-8 h-8"} />
            </Link>
            <li className={"w-full"}>
              <ul
                role="list"
                className=" space-y-1 flex gap-1 items-center flex-col"
              >
                {urls.map((url, index) => (
                  <Link key={index} to={url.path}>
                    <Tooltip placement={"right"} title={url.name}>
                      <span
                        className={
                          "group text-white flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold"
                        }
                      >
                        <url.icon
                          className={classNames(
                            pathname === url.path
                              ? "border-b-2 border-white"
                              : "border-b-2 border-primary-600 hover:border-b-2 hover:border-white",
                            "h-8 w-8 p-1 shrink-0 text-white",
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Tooltip>
                  </Link>
                ))}
                <Tooltip placement={"right"} title={"Έξοδος"}>
                  <span
                    className={
                      "group text-white flex gap-x-3 rounded-md py-2 text-sm leading-6 cursor-pointer font-semibold"
                    }
                    onClick={() => {
                      dispatch(logout());
                      navigate("/");
                    }}
                  >
                    <ArrowLeftStartOnRectangleIcon
                      className={classNames(
                        "border-b-2 border-primary-600 hover:border-b-2 hover:border-white h-8 w-8 p-1 shrink-0 text-white",
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Tooltip>
              </ul>
            </li>

            <Link to={"/profile"} className="  w-10 mt-auto">
              <Tooltip placement={"right"} title={"Profile"}>
                <span className="flex justify-items-center rounded justify-center items-center  w-full gap-x-4   py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700">
                  {!personalInfo?.profilePicUrl ? (
                    <UserCircleIcon
                      className="h-8 w-8 text-gray-300"
                      aria-hidden="true"
                    />
                  ) : (
                    <img
                      style={{ objectFit: "cover" }}
                      className="h-6 w-6 rounded-full shadow-xl bg-white"
                      src={personalInfo?.profilePicUrl}
                      alt=""
                    />
                  )}
                </span>
              </Tooltip>
            </Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DesktopNavigationSideBar;
