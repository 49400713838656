import { UserIcon } from "@heroicons/react/24/outline";

const SelectedEmployee = ({ employee }) => {
  return (
    <div className={"flex flex-row gap-3 p-4 border-b-[1px]"}>
      <span className="relative inline-block">
        {employee?.profilePicUrl?.includes("https") ? (
          <img
            className="h-12 w-12 ring-1 ring-gray-400 object-cover rounded-full overflow-hidden"
            src={employee?.profilePicUrl}
            alt=""
          />
        ) : (
          <div className="h-12 w-12 overflow-hidden rounded-full ring-1 ring-gray-400 bg-white flex items-center justify-center">
            <UserIcon className={"h-5 w-5 inline-block text-gray-700 "} />
          </div>
        )}

        <span className="absolute bottom-1 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
      </span>
      <div>
        <h3 className={"text-sm font-semibold text-gray-700"}>
          {employee?.firstName} {employee?.lastName}
        </h3>
        <span className={"text-gray-500"}>{employee?.occupation}</span>
      </div>
    </div>
  );
};

export default SelectedEmployee;
