import "./styles.css";
const HorizontalLoader = () => {
  return (
    <div className="w-full fixed top-0">
      <div className="h-1 lg:h-1.4 w-full bg-gray-100 overflow-hidden">
        <div className="progress w-full h-full rounded-xl bg-primary-600 left-right"></div>
      </div>
    </div>
  );
};
export default HorizontalLoader;
