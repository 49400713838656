import { UserIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

export function ProfileIcon() {
  const personalData = useSelector((state) => state.personalData);

  if (personalData?.profilePicUrl?.includes("https")) {
    return (
      <div className={"flex "}>
        <span className="relative inline-block">
          <img
            className="h-12 w-12  object-cover rounded-full overflow-hidden"
            src={personalData?.profilePicUrl}
            alt=""
          />
          <span className="absolute bottom-3 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
        </span>
        <div className={" pl-5 flex  "}>
          <h3
            className={
              "text-white font-semibold content-center text-lg align-middle"
            }
          >
            {personalData?.firstName} {personalData?.lastName}
          </h3>
        </div>
      </div>
    );
  }

  if (!personalData?.profilePicUrl?.includes("https")) {
    return (
      <div className={"flex "}>
        <span className="relative inline-block">
          <div className="h-12 w-12 overflow-hidden rounded-full bg-white flex items-center justify-center">
            <UserIcon className={"h-8 w-8 inline-block text-gray-300 "} />
          </div>
          <span className="absolute bottom-3 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
        </span>
        <div className={" pl-5 flex  "}>
          <h3
            className={
              "text-white font-semibold content-center text-lg align-middle"
            }
          >
            {personalData?.firstName} {personalData?.lastName}
          </h3>
        </div>
      </div>
    );
  }
}
