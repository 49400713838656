import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";

export const getReviews = createAsyncThunk(
  "reviews/getReviews",
  async (_, thunkAPI) => {
    try {
      const { shop } = thunkAPI.getState();
      const response = await Request.get(
        `BranchReviews/get-reviews/${shop?.id}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRatings = createAsyncThunk(
  "reviews/getRatings",
  async (_, thunkAPI) => {
    try {
      const { shop } = thunkAPI.getState();
      const response = await Request.get(
        `BranchReviews/get-ratings/${shop?.id}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

const initialState = {
  reviews: { data: [], loading: false, error: null },
  ratings: { data: [], loading: false, error: null },
};

const reviewsSlice = createSlice({
  name: "Reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /** getReviews **/
      .addCase(getReviews.pending, (state, action) => {
        state.reviews.isLoading = true;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviews.isLoading = false;
        state.reviews.data = action.payload;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.reviews.isLoading = false;
        toast.error(action.error.message);
      })

      /** getRatings **/
      .addCase(getRatings.pending, (state, action) => {
        state.ratings.isLoading = true;
      })
      .addCase(getRatings.fulfilled, (state, action) => {
        state.ratings.isLoading = false;
        state.ratings.data = action.payload;
      })
      .addCase(getRatings.rejected, (state, action) => {
        state.ratings.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

/** Selectors **/
export const reviewsSelector = (state) => state.reviews?.reviews?.data;
export const ratingsSelector = (state) => state.reviews?.ratings?.data;

export const {} = reviewsSlice.actions;

export default reviewsSlice.reducer;
