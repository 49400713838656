import { styles } from "../Day/styles";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  setIndexOfDayThatAppointmentWsDraggedOn,
  setIndexOfWorkerThatAppointmentIsDraggedOn,
} from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";

const HourSlotInCalendar = ({
  selectedTimeForAppointment,
  indexOfWorker,
  dayObjectThatTheSlotIsPartOf,
  time,
  indexOfDay,
  getRelativeCoordsToShowWhichTimeSlotIsHovered,
}) => {
  const dispatch = useDispatch();

  const getWhatPositionShouldBackGroundBePositionedBasedOnTime = (time) => {
    if (
      time.startMinuteOfInactiveSlot === 0 &&
      time.endMinuteOfInactiveSlot === 59
    ) {
      return "0px";
    }
    if (
      time.startMinuteOfInactiveSlot === 0 &&
      time.endMinuteOfInactiveSlot > 0
    ) {
      return `-${time.endMinuteOfInactiveSlot * 2}px`;
    }
    if (time.startMinuteOfInactiveSlot > 0) {
      return `${time.startMinuteOfInactiveSlot * 2}px`;
    }
  };

  return (
    <div
      className={time}
      id={time}
      onClick={(e) => {
        if (!selectedTimeForAppointment) {
          getRelativeCoordsToShowWhichTimeSlotIsHovered(e, isMobile);
        }
      }}
      onDragOver={(e) => {
        if (!selectedTimeForAppointment) {
          getRelativeCoordsToShowWhichTimeSlotIsHovered(e);
          dispatch(setIndexOfDayThatAppointmentWsDraggedOn(indexOfDay));
          dispatch(
            setIndexOfWorkerThatAppointmentIsDraggedOn(
              !indexOfWorker ? 0 : indexOfWorker,
            ),
          );
        }
      }}
      onMouseMoveCapture={(e) => {
        if (!isMobile) {
          if (!selectedTimeForAppointment) {
            getRelativeCoordsToShowWhichTimeSlotIsHovered(e);
          }
        }
      }}
      style={
        dayObjectThatTheSlotIsPartOf[time].hasInactiveSlot
          ? {
              backgroundImage: `repeating-linear-gradient(-45deg, #F0F3F56B, #F0F3F56B 4px, #e6ebf1 6px, #e6ebf1 4px)  `,
              backgroundPositionY: `${getWhatPositionShouldBackGroundBePositionedBasedOnTime(dayObjectThatTheSlotIsPartOf[time])}`,

              backgroundRepeat: "repeat-x",
              ...styles.divOfHour,
              top: `${time * 120}px`,
            }
          : {
              ...styles.divOfHour,
              top: `${time * 120}px`,
            }
      }
    >
      <div
        style={{
          pointerEvents: "none",
          borderStyle: "solid",
          borderColor: "rgba(203,213,224,0.4)",
          borderWidth: 0,
          height: 0,
        }}
      ></div>
    </div>
  );
};

export default HourSlotInCalendar;
