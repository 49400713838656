import { Tooltip } from "@mui/material";
import { PlusIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../Utilities/utilities";
import ImageInGallery from "../ImageInGallery/ImageInGallery";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmployeeGalleryLoading,
  isShopGalleryLoading,
  uploadAShopGalleryImage,
  uploadEmployeeGalleryImage,
} from "../../Features/images/images-slice";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";

export const GalleryOfImages = ({ images, isForShop, isForEmployee }) => {
  const dispatch = useDispatch();
  const isGalleryLoadingForShop = useSelector(isShopGalleryLoading);
  const isGalleryLoadingForEmployee = useSelector(isEmployeeGalleryLoading);

  const LoaderOrToolTip = () => {
    if (isGalleryLoadingForShop || isGalleryLoadingForEmployee) {
      return <SimpleLoader />;
    }

    if (!isGalleryLoadingForShop && !isGalleryLoadingForEmployee) {
      return (
        <Tooltip title={"Add Image to gallery"}>
          <label
            htmlFor="gallery-upload"
            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
          >
            <PlusIcon
              className={classNames(
                "text-white rounded bg-primary hover:bg-indigo-500 h-6 w-6 shrink-0 ",
              )}
            />
            <input
              onChange={async (e) => {
                if (isForShop) {
                  dispatch(uploadAShopGalleryImage({ event: e }));
                }
                if (isForEmployee) {
                  dispatch(uploadEmployeeGalleryImage({ event: e }));
                }
              }}
              id="gallery-upload"
              name="gallery-upload"
              multiple={false}
              type="file"
              className="sr-only"
            />
          </label>
        </Tooltip>
      );
    }
  };

  return (
    <div className={"mt-10 space-y-12 w-full   "}>
      <div className="col-span-full">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Γκαλερί Φωτογραφιών
        </label>
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className=" grid grid-cols-2 md:grid-cols-3 gap-4">
            {images?.map((img, i) => (
              <ImageInGallery key={i} imageItem={img} />
            ))}
            <LoaderOrToolTip />
          </div>
        </div>
      </div>
    </div>
  );
};
