import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import {
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import {
  getEmployeesByServiceId,
  removeBranchCategory,
  removeBranchService,
} from "../../../Features/categoriesWithServices/categoriesWithServices-slice";

function classNames(...classes) {
  return classes?.filter(Boolean).join(" ");
}

const DropDown = ({
  fromCategory,
  category,
  setCategoryThatPressedAddService,
  setIsAddServiceShown,
  service,
  setServiceToBeEdited,
  setSelectedCategory,
  setIsAddCategoryShown,
}) => {
  const dispatch = useDispatch();

  const add = () => {
    setIsAddServiceShown(true);
    setCategoryThatPressedAddService(category);
  };

  const edit = async () => {
    if (fromCategory) {
      setIsAddCategoryShown(true);
      setSelectedCategory(category);
    } else {
      const data = await dispatch(
        getEmployeesByServiceId({ serviceId: service?.id }),
      );
      setServiceToBeEdited({
        ...service,
        employees: data?.payload?.map((data) => data?.id),
        categoryId: category?.id,
        categoryName: category?.categoryName,
      });
    }
  };

  const remove = () => {
    if (fromCategory) {
      dispatch(removeBranchCategory({ categoryId: category?.id }));
    } else {
      dispatch(removeBranchService({ serviceId: service?.id }));
    }
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={"rounded-md hover:bg-gray-100"}>
          {fromCategory ? (
            <EllipsisHorizontalIcon className={"w-8 h-8"} aria-hidden="true" />
          ) : (
            <EllipsisVerticalIcon className={"w-6 h-6"} aria-hidden="true" />
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {fromCategory && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={add}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer",
                    )}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Προσθήκη υπηρεσίας
                  </div>
                )}
              </Menu.Item>
            )}

            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={edit}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer",
                  )}
                >
                  <PencilSquareIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Επεξεργασία
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={remove}
                  className={classNames(
                    active ? "bg-red-100 text-red-900" : "text-red-500",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer",
                  )}
                >
                  <TrashIcon
                    className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                    aria-hidden="true"
                  />
                  Διαγραφή
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
