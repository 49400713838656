import { styles } from "./styles";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { classNames, indexOfDays } from "../../Utilities/utilities";
import CustomTimePicker from "../CustomTimePicker/CustomTimePicker";
import { ConstructWeeklyShifts } from "../../Hooks/ConstructWeeklyShifts";
import { useSelector } from "react-redux";

const Shift = ({
  day,
  allWeeksShiftsArray,
  setAllWeekShiftsArray,
  index,
  setIsSecondShiftShown,
  isSecondShiftShown,
  setShouldDisplayFirstShift,
}) => {
  const dayWithShifts = allWeeksShiftsArray?.filter(
    (dayObjWithShifts) => dayObjWithShifts?.day === indexOfDays?.[day],
  )?.[0];
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  useEffect(() => {
    if (index === 1 && dayWithShifts?.secondShiftEnd !== "00:00") {
      setIsSecondShiftShown(true);
    }
  }, []);

  if ((index === 1 && isSecondShiftShown) || index === 0) {
    return (
      <div className={"w-full"} style={styles.wrapper}>
        <CustomTimePicker
          index={index}
          openUpwards={indexOfDays[day] > 3}
          from
          allWeeksShiftsArray={allWeeksShiftsArray}
          setAllWeekShiftsArray={setAllWeekShiftsArray}
          day={day}
          noLessThanTime={index === 1 ? dayWithShifts?.firstShiftEnd : null}
          givenTime={
            index === 0
              ? dayWithShifts?.firstShiftStart
              : dayWithShifts?.secondShiftStart
          }
        />
        <div style={styles.text}>έως</div>
        <CustomTimePicker
          openUpwards={indexOfDays[day] > 3}
          index={index}
          day={day}
          allWeeksShiftsArray={allWeeksShiftsArray}
          setAllWeekShiftsArray={setAllWeekShiftsArray}
          to
          noLessThanTime={
            index === 0
              ? dayWithShifts?.firstShiftStart
              : dayWithShifts?.secondShiftStart
          }
          givenTime={
            index === 0
              ? dayWithShifts?.firstShiftEnd
              : dayWithShifts?.secondShiftEnd
          }
        />

        {loggedInAs !== "employee" && (
          <div style={styles.iconDiv}>
            <div
              className={
                "  justify-items-center justify-center items-center w-10  m-1"
              }
            >
              {(allWeeksShiftsArray?.[indexOfDays?.[day]]?.shifts?.length < 2 ||
                !isSecondShiftShown) &&
                !dayWithShifts?.firstShiftEnd?.includes("23") && (
                  <Tooltip title={"Προσθήκη Βάρδιας"} className={"w-full"}>
                    <PlusIcon
                      onClick={() => {
                        setIsSecondShiftShown(true);
                      }}
                      style={{
                        width: 30,
                        height: 15,
                      }}
                      className={classNames(
                        "text-green-900 bg-green-100 rounded hover:bg-green-300 ",
                      )}
                    />
                  </Tooltip>
                )}
            </div>
            <Tooltip title={"Διαγραφή Βάρδιας"}>
              <TrashIcon
                onClick={() => {
                  ConstructWeeklyShifts(
                    indexOfDays[day],
                    index,
                    null,
                    null,
                    true,
                    allWeeksShiftsArray,
                    setAllWeekShiftsArray,
                  );
                  if (index === 0) {
                    setShouldDisplayFirstShift(false);
                  }
                  if (index === 1) {
                    setIsSecondShiftShown(false);
                  }
                }}
                className={classNames(
                  "text-red-500 ml-1 rounded hover:bg-red-200 h-6 w-6 shrink-0",
                )}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
};

export default Shift;
