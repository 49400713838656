import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: []


};


const branchesSlice = createSlice({
    name: "Authorised",
    initialState,
    reducers: {
        setBranches(state, action) {
            state.data = action.payload
        }
    },

});


export const {setBranches} = branchesSlice.actions;

export default branchesSlice.reducer;
