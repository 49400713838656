import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import EmployeeCard from "../../Components/EmployeeCard/EmployeeCard";
import SimpleAlertWithActions from "../../Components/SimpleAlertWithActions/SimpleAlertWithActions";
import { InviteEmployee } from "../../Components/InviteEmployee/InviteEmployee";
import { dismissalEmployee } from "../../Features/employees/employees-slice";

export default function WorkersPage() {
  const workers = useSelector((state) => state.employees?.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddWorkerShown, setIsAddWorkerShown] = useState(false);
  const dispatch = useDispatch();

  const [workerToBeDeleted, setWorkerToBeDeleted] = useState(null);

  useEffect(() => {
    if (workerToBeDeleted) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [workerToBeDeleted]);

  useEffect(() => {
    if (!isModalVisible) {
      setWorkerToBeDeleted(null);
    }
  }, [isModalVisible]);

  const dismissal = () => {
    setIsModalVisible(false);
    dispatch(dismissalEmployee({ employeeId: workerToBeDeleted?.id }));
  };

  return (
    <div className={"p-4"}>
      <nav className={"justify-between flex"}>
        <div className={"flex flex-row gap-2"}>
          <h3 className={"font-bold text-xl sm:text-3xl text-primary"}>
            {workers?.length}
          </h3>
          <span className={"text-xl sm:text-3xl"}>Υπάλληλοι</span>
        </div>

        <div className={"flex flex-row gap-2"}>
          <button
            className={"bg-primary py-2 px-4 text-sm text-white rounded-md"}
            onClick={() => setIsAddWorkerShown(true)}
          >
            + Πρόσκληση υπαλλήλου
          </button>
        </div>
      </nav>
      <div
        className={
          "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-5"
        }
      >
        {workers?.map((employee) => (
          <EmployeeCard
            setWorkerToBeDeleted={setWorkerToBeDeleted}
            key={employee?.id}
            employee={employee}
          />
        ))}
      </div>

      <SimpleAlertWithActions
        open={isModalVisible}
        setOpen={setIsModalVisible}
        title={"Απόλυση Υπαλλήλου 😔"}
        message={
          "Είσαι σίγουρος ότι θέλεις να απολύσεις τον υπάλληλο; Αυτή η ενέργεια είναι μη αναστρέψιμη."
        }
        onAccept={() => {
          dismissal();
          setWorkerToBeDeleted(null);
        }}
        onCancel={() => setWorkerToBeDeleted(null)}
      />
      <InviteEmployee
        open={isAddWorkerShown}
        setOpen={setIsAddWorkerShown}
        onCancel={() => {
          setIsAddWorkerShown(false);
        }}
      />
    </div>
  );
}
