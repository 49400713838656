import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppointmentToEdit,
  setIdOfAppointmentThatWillBeEdited,
  setIsAppointmentBeingDragged,
  setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition,
} from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import {
  returnColorOfTextBasedOnColorOfAppointment,
  roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute,
  turnPixelsIntoHoursAndMinutes,
} from "../../Utilities/utilities";
import utc from "dayjs/plugin/utc";
import {
  editAppointmentAfterDragAndDrop,
  editAppointmentAfterResizing,
} from "../../Features/editAppointment/editAppointment-slice";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import StatusIcon from "./StatusIcon";

dayjs.extend(utc);

const Appointment = ({
  setIsAppointmentBeingResized,
  appointment,
  positionOfCursor,
}) => {
  const dispatch = useDispatch();
  const [
    heightOfAppointmentToDetermineTheDuration,
    setHeightOfAppointmentToDetermineTheDuration,
  ] = useState((appointment.duration / 15) * 30);
  const isAppointmentLoading = useSelector(
    (state) => state.editAppointment.isLoading,
  );
  const idOfAppointmentThatIsEdited = useSelector(
    (state) => state.editAppointment?.idOfAppointmentThatIsEdited,
  );
  const top =
    parseInt(dayjs.utc(appointment?.bookedDate).local().format("HH")) * 120 +
    dayjs.utc(appointment?.bookedDate).local().format("mm") * 2;

  const [
    areUpDownArrowsOnTheBottomOfTheAppointmentClicked,
    setAreUpDownArrowsOnTheBottomOfTheAppointmentClicked,
  ] = useState(false);
  const objectOfAppointmentToBeEdited = useSelector(
    (state) =>
      state.appointment
        .objectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition,
  );

  useEffect(() => {
    //when we resize appointmentToBeBooked

    if (areUpDownArrowsOnTheBottomOfTheAppointmentClicked && positionOfCursor) {
      let currentHeight =
        positionOfCursor?.cord?.yNonFixed + positionOfCursor.value * 120 - top;

      currentHeight = Math.round(currentHeight);
      if (currentHeight !== heightOfAppointmentToDetermineTheDuration) {
        if (currentHeight >= 30) {
          setHeightOfAppointmentToDetermineTheDuration(currentHeight);
        }
      }
    }
  }, [positionOfCursor]);

  useEffect(() => {
    setHeightOfAppointmentToDetermineTheDuration(
      (appointment.duration / 15) * 30,
    );
  }, [appointment.duration]);
  useEffect(() => {
    setHeightOfAppointmentToDetermineTheDuration(
      (appointment.duration / 15) * 30,
    );
  }, [appointment]);

  useEffect(() => {
    if (appointment.id === objectOfAppointmentToBeEdited?.id) {
      if (
        objectOfAppointmentToBeEdited.newTopForAppointment &&
        objectOfAppointmentToBeEdited.dayThatTheAppointmentWasDroppedAt
      ) {
        dispatch(
          editAppointmentAfterDragAndDrop({
            manualTop: objectOfAppointmentToBeEdited.newTopForAppointment,
            manualDate:
              objectOfAppointmentToBeEdited.dayThatTheAppointmentWasDroppedAt,
            manualWorker: objectOfAppointmentToBeEdited.workerToHandle,
            appointment,
            heightOfAppointmentToDetermineTheDuration,
          }),
        );
        dispatch(
          setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition(
            null,
          ),
        );
      }
    }
  }, [objectOfAppointmentToBeEdited]);
  return (
    <div
      draggable={true}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragStart={(event) => {
        dispatch(setIsAppointmentBeingDragged(true));
        event.dataTransfer.setData("id", appointment.id);
      }}
      onDrop={(event) => {
        // the onDrop here is for when we drag an appointmentToBeBooked down and
        //it falls inside the component, not inside Day.js
        let bounds = event.target.getBoundingClientRect();
        let y = event.clientY - bounds.top;
        let idOfAppointment = event.dataTransfer.getData("id");

        y = roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(y);
        dispatch(setIsAppointmentBeingDragged(false));

        if (!isNaN(y / 2 + top) && idOfAppointment === appointment?._id) {
          dispatch(
            setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition(
              {
                id: idOfAppointment,
                dayThatTheAppointmentWasDroppedAt: dayjs(appointment.bookedDate)
                  .$d,
                newTopForAppointment: top + y / 2,
              },
            ),
          );
        }
      }}
      className={"absolute w-full overflow-hidden transition-all"}
      onMouseMove={(event) => {
        if (areUpDownArrowsOnTheBottomOfTheAppointmentClicked) {
          let bounds = event.target.getBoundingClientRect();
          let heightOfAppointmentInPixelsFromTheTopOfItToThePointOfTheCursor =
            event.clientY - bounds.top;

          if (
            heightOfAppointmentInPixelsFromTheTopOfItToThePointOfTheCursor <
              heightOfAppointmentToDetermineTheDuration &&
            heightOfAppointmentInPixelsFromTheTopOfItToThePointOfTheCursor > 15
          ) {
            setHeightOfAppointmentToDetermineTheDuration(
              heightOfAppointmentInPixelsFromTheTopOfItToThePointOfTheCursor,
            );
          }
        }
      }}
      style={{
        top: `${top}px `,
      }}
    >
      <div
        onClick={() => {
          dispatch(setAppointmentToEdit(appointment));
        }}
        style={{
          backgroundColor: appointment.services?.[0]?.color
            ? appointment.services?.[0]?.color
            : "#BBABFFC5",
          height: `${heightOfAppointmentToDetermineTheDuration}px`,
        }}
        className={`relative rounded-lg border border-gray-200`}
      >
        <StatusIcon status={appointment?.status} />
        {isAppointmentLoading &&
          idOfAppointmentThatIsEdited === appointment?.id && (
            <div
              className={
                " transition-all absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              }
            >
              <SimpleLoader />
            </div>
          )}
        <div
          className={
            "overflow-hidden cursor-pointer relative  flex flex-row justify-between overflow-y-hidden  p-1 text-xs leading-4 "
          }
        >
          <div
            className={`${returnColorOfTextBasedOnColorOfAppointment(appointment.services?.[0]?.color)} font-normal flex flex-col group-hover:text-blue-700`}
          >
            <span className={"font-semibold text-ellipsis overflow-hidden"}>
              {appointment?.userFirstName} {appointment?.userLastName}
            </span>
            <span className={"text-ellipsis overflow-hidden"}>
              {turnPixelsIntoHoursAndMinutes(top).hours +
                ":" +
                turnPixelsIntoHoursAndMinutes(top).minutes}
              -
              {turnPixelsIntoHoursAndMinutes(
                top + heightOfAppointmentToDetermineTheDuration,
              ).hours +
                ":" +
                turnPixelsIntoHoursAndMinutes(
                  top + heightOfAppointmentToDetermineTheDuration,
                ).minutes}
            </span>

            <span
              className={
                "font-semibold text-blue-700 text-ellipsis overflow-hidden"
              }
            >
              {appointment.type}
            </span>
          </div>

          <AvatarComponent
            image={appointment.employeeProfilePicture}
            iconFullFill={"fill-gray-400"}
          />
        </div>

        {/*<div*/}
        {/*  onClick={() => {*/}
        {/*    if (!areUpDownArrowsOnTheBottomOfTheAppointmentClicked) {*/}
        {/*      setAreUpDownArrowsOnTheBottomOfTheAppointmentClicked(true);*/}
        {/*      setIsAppointmentBeingResized(true);*/}
        {/*      dispatch(setIdOfAppointmentThatWillBeEdited(null));*/}
        {/*    }*/}
        {/*    if (areUpDownArrowsOnTheBottomOfTheAppointmentClicked) {*/}
        {/*      setAreUpDownArrowsOnTheBottomOfTheAppointmentClicked(false);*/}
        {/*      setIsAppointmentBeingResized(false);*/}
        {/*      dispatch(*/}
        {/*        editAppointmentAfterResizing({*/}
        {/*          manualTop: top,*/}
        {/*          manualDate: appointment?.bookedDate,*/}
        {/*          manualWorker: appointment?.employeeId,*/}
        {/*          appointment,*/}
        {/*          heightOfAppointmentToDetermineTheDuration,*/}
        {/*        }),*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  className={*/}
        {/*    "w-full h-3 cursor-ns-resize hidden md:block absolute bottom-0"*/}
        {/*  }*/}
        {/*>*/}
        {/*  <ArrowsUpDownIcon*/}
        {/*    className=" left-0 right-0 mr-auto ml-auto  w-2"*/}
        {/*    aria-hidden="true"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Appointment;
