import dayjs from "dayjs";
import { Tooltip } from "@mui/material";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

export function GoToCurrentTimeButton({ setId }) {
  useEffect(() => {
    if (dayjs(Date.now()).format("HH") > 2) {
      setId(dayjs(Date.now()).format("HH") - 2);
    }
    if (dayjs(Date.now()).format("HH") <= 2) {
      setId(0);
    }
  }, []);
  return (
    <a
      type="button"
      onClick={() => {
        if (dayjs(Date.now()).format("HH") > 2) {
          setId(dayjs(Date.now()).format("HH") - 2);
        }
        if (dayjs(Date.now()).format("HH") <= 2) {
          setId(0);
        }
      }}
      className="right-2 fixed cursor-pointer bottom-10 sm:bottom-10 text-white border-1 shadow-xl  bg-primary-600 focus:bg-indigo-400 transition-all font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2  "
    >
      <Tooltip title={"Πάνε στο τώρα"}>
        <ClockIcon className={"h-5 w-5"} />
      </Tooltip>
    </a>
  );
}
