import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { setSelectedDate } from "../../Features/calendar/calendar-slice";
import dayjs from "dayjs";
import { CalendarIcon } from "@heroicons/react/24/outline";

export function CalendarDropDown() {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const handleValueChange = (newValue) => {
    dispatch(setSelectedDate(dayjs(newValue.startDate)));
  };
  return (
    <div
      style={{
        alignItems: "center",
      }}
      className=" w-full relative md:w-auto placeholder:"
    >
      <Datepicker
        i18n={"el"}
        useRange={false}
        toggleIcon={() => (
          <CalendarIcon className={"w-5 h-5 text-gray-700 sm:block hidden"} />
        )}
        primaryColor={"purple"}
        containerClassName={"w-full h-full right-0 p-0 "}
        inputClassName={
          "text-sm w-full text-center placeholder:text-gray-700 p-0 outline-none"
        }
        readOnly
        asSingle
        placeholder={dayjs(selectedDate).format("DD-MM-YYYY")}
        displayFormat={"DD-MM-YYYY"}
        value={dayjs(selectedDate).format("DD-MM-YYYY")}
        onChange={handleValueChange}
      />
    </div>
  );
}
