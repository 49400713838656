import {
    indexOfDays,

} from "../../Utilities/utilities";

import Day from "../Day/Day";


const WeekConstructor = () => {
    return (<>
            {Object.keys(indexOfDays).map((dayName, index) =>
                <Day key={index}
                     indexOfWorkerThatTheDayRepresents={0}
                     index={index}/>)}
        </>

    )
}


export default WeekConstructor
