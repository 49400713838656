import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceCard from "../ServiceCard/ServiceCard";
import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { setSelectedWorker } from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";

export default function ServicesList({ open, setOpen }) {
  const servicesFromShop = useSelector(
    (state) => state.categoriesWithServices?.data,
  );
  const employeeThatIsSelectedForTheAppointment = useSelector(
    (state) => state.appointment?.selectedWorker?.id,
  );
  const employees = useSelector((state) => state.employees?.data);
  const employeeServices = employees?.filter(
    (employee) => employee?.id === employeeThatIsSelectedForTheAppointment,
  )?.[0]?.services;
  const [servicesToShow, setServicesToShow] = useState(null);
  const dispatch = useDispatch();
  const appointmentToEdit = useSelector(
    (state) => state.appointment.appointmentToEdit,
  );
  const selectedServicesForAppointment = useSelector(
    (state) => state.appointment.selectedService,
  );
  const servicesThatMatchWithTheBranchServices = () => {
    let arrayToReturn = [];

    servicesFromShop?.map((category) => {
      //hair services
      category.services?.map((service) => {
        //hair removal

        employeeServices?.map((employeeService) => {
          if (
            arrayToReturn?.filter(
              (element) => element.categoryName === category.categoryName,
            )?.length > 0 &&
            category.services?.filter(
              (serviceFromShop) =>
                serviceFromShop.id === employeeService.branchServiceId,
            )?.length > 0
          ) {
            arrayToReturn?.map((categoryWithServices) => {
              if (
                categoryWithServices.categoryName === category.categoryName &&
                !categoryWithServices.services?.includes(employeeService)
              ) {
                return categoryWithServices.services.push(employeeService);
              }
            });
          }
          if (employeeService.branchServiceId === service?.id) {
            //if category doesn't exist in the array that is about to be returned
            if (
              arrayToReturn?.filter(
                (categoryWithServices) =>
                  categoryWithServices.categoryName === category.categoryName,
              )?.length === 0
            ) {
              return arrayToReturn.push({
                categoryName: category.categoryName,
                services: [employeeService],
              });
            }
          }
        });
      });
    });

    return arrayToReturn;
  };

  useEffect(() => {
    if (employeeThatIsSelectedForTheAppointment) {
      setServicesToShow([...servicesThatMatchWithTheBranchServices()]);
    }

    if (!employeeThatIsSelectedForTheAppointment) {
      dispatch(
        setSelectedWorker(
          employees?.filter(
            (employee) => employee?.id === appointmentToEdit?.employeeId,
          )?.[0],
        ),
      );
    }
  }, []);

  useEffect(() => {
    setServicesToShow([...servicesThatMatchWithTheBranchServices()]);
  }, [employeeThatIsSelectedForTheAppointment]);

  return (
    <SlideOverWithCloseButtonOnOutside
      title={"Διάλεξε Υπηρεσία"}
      open={open}
      setOpen={setOpen}
      serviceLength={
        selectedServicesForAppointment?.length
          ? selectedServicesForAppointment?.length
          : 0
      }
    >
      <div className="px-4">
        {servicesToShow?.map(
          (category, i) =>
            category?.services?.length > 0 && (
              <div key={i} className={"py-4"}>
                <p className={"font-semibold mb-2 text-gray-900"}>
                  {category?.categoryName}
                </p>
                <div className={"grid gap-2"}>
                  {category?.services?.map((service, index) => (
                    <ServiceCard
                      close={() => {
                        setOpen(false);
                      }}
                      key={index}
                      service={service}
                    />
                  ))}
                </div>
              </div>
            ),
        )}

        <div className={"flex justify-between items-center"}>
          <div className={"h-full font-bold"}>
            Υπηρεσίες: {selectedServicesForAppointment?.length}
          </div>
          <button
            onClick={() => {
              setOpen(false);
            }}
            type="button"
            className="rounded-md bg-primary px-5 py-2 font-semibold text-white shadow-md hover:bg-primary-500"
          >
            Επιλογή
          </button>
        </div>
      </div>
    </SlideOverWithCloseButtonOnOutside>
  );
}
