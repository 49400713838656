import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmployeePortraitLoading,
  isOwnProfilePortraitLoading,
  uploadEmployeePortrait,
  uploadProfileImage,
} from "../../Features/images/images-slice";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";
import { isLoggedInAs } from "../../Features/authorizeUser/authorizeUser-slice";

const UploadProfileImage = ({ isFromEditEmployee }) => {
  const personalInfo = useSelector((state) => state.personalData);
  const dispatch = useDispatch();
  const employeeToBeEdited = useSelector(
    (state) => state.employees.employeeToBeEdited,
  );
  const isUploadEmployeeImageLoading = useSelector(isEmployeePortraitLoading);
  const isOwnPortraitLoading = useSelector(isOwnProfilePortraitLoading);
  const loggedInAs = useSelector(isLoggedInAs);
  const changeImage = async (e) => {
    if (isFromEditEmployee) {
      return dispatch(uploadEmployeePortrait({ event: e }));
    }
    if (!isFromEditEmployee && loggedInAs !== "employee") {
      return dispatch(uploadProfileImage({ event: e }));
    }
    if (loggedInAs === "employee") {
      return dispatch(uploadEmployeePortrait({ event: e }));
    }
  };

  return (
    <div className="col-span-full">
      <label
        htmlFor="photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Φώτο Προφίλ
      </label>

      <div className="mt-2 flex items-center gap-x-3">
        {(isUploadEmployeeImageLoading || isOwnPortraitLoading) && (
          <SimpleLoader />
        )}
        {!employeeToBeEdited ? (
          !personalInfo?.profilePicUrl ? (
            <UserCircleIcon
              className="h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
          ) : (
            <img
              style={{
                objectFit: "cover",
              }}
              className="h-12 w-12 border-[1px] border-black rounded-full bg-indigo-700"
              src={personalInfo?.profilePicUrl}
              alt=""
            />
          )
        ) : !employeeToBeEdited.profilePicUrl ? (
          <UserCircleIcon
            className="h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
        ) : (
          <img
            style={{
              objectFit: "cover",
            }}
            className="h-12 w-12 border-[1px] border-black rounded-full bg-indigo-700"
            src={employeeToBeEdited.profilePicUrl}
            alt=""
          />
        )}
        <label
          htmlFor="file-upload"
          className="relative cursor-pointer  rounded-md bg-white font-semibold text-indigo-600
                       focus-within:outline-none
                        focus-within:ring-2
                        focus-within:ring-indigo-600 focus-within:ring-offset-2
                       hover:text-indigo-500"
        >
          Αλλαγή
          <input
            onChange={changeImage}
            id="file-upload"
            name="file-upload"
            multiple={false}
            type="file"
            className=" sr-only rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          />
        </label>
      </div>
    </div>
  );
};

export default UploadProfileImage;
