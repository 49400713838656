import Day from "../Day/Day";
import dayjs from "dayjs";
import HeaderOfViews from "../HeaderOfViews/HeaderOfViews";
import WeekNavigator from "../WeekNavigator/WeekNavigator";
import LeftPartShowingHours
    from "../LeftPartShowingHours/LeftPartShowingHours";
import React, {useRef, useState} from "react";

const ThreeDayViewConstructor = () => {
    const twoDayArrayToMap = [0, 1, 2]
    const divRef = useRef(null);
    const containerNav = useRef(null);

    const [scrolledLeft, setScrolledLeft] = useState(0)

    const handleScroll = () => {
        if (divRef.current) {
            const scrollLeft = divRef.current.scrollLeft;
            setScrolledLeft(scrollLeft);
        }
    };

    return (
        <div

            className="flex h-full  max-w-full flex-col">
            <HeaderOfViews/>
            <WeekNavigator
                scrolledLeft={scrolledLeft}
                containerNav={containerNav}

                typeOfNavigator={'3Day'}
            />
            <div ref={divRef}
                 onScroll={handleScroll}
                 className="isolate flex overflow-auto flex-auto flex-col  bg-white">
                <div style={{
                    width: '100%',
                    maxWidth: '100%'

                }} className="flex flex-none flex-col ">
                    <div


                        className={'flex  flex-auto'}>
                        <LeftPartShowingHours/>
                        {twoDayArrayToMap.map((index) =>
                            <Day
                                index={index}
                                key={index}/>)}
                    </div>
                </div>

            </div>
        </div>
    )
}


export default ThreeDayViewConstructor
