import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewClient } from "../../Features/clients/clientsSlice";
import { setSelectedClient } from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";

export const AddClientSlideOver = ({ open, setOpen }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  return (
    <SlideOverWithCloseButtonOnOutside
      title={"Προσθήκη Πελάτη"}
      open={open}
      setOpen={setOpen}
    >
      <div className="mt-10 mx-auto w-full p-4 max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Όνομα
            </label>
            <div className="mt-2">
              <input
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="first-name"
                type="text"
                autoComplete="first-name"
                required
                className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Επίθετο
            </label>
            <div className="mt-2">
              <input
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name="lastName"
                type="text"
                autoComplete="last-name"
                required
                className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Τηλέφωνο
              </label>
            </div>
            <div className="mt-2">
              <PhoneNumberInput phone={phone} setPhone={setPhone} />

              {/*<input*/}
              {/*  id="phone"*/}
              {/*  value={phone}*/}
              {/*  onChange={(e) => {*/}
              {/*    setPhone(e.target.value);*/}
              {/*  }}*/}
              {/*  name="phone"*/}
              {/*  type="phone"*/}
              {/*  autoComplete="phone"*/}
              {/*  required*/}
              {/*  className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
              {/*/>*/}
            </div>
          </div>

          <div>
            <button
              onClick={() => {
                dispatch(
                  addNewClient({
                    firstName,
                    lastName,
                    phone,
                  }),
                );
                dispatch(
                  setSelectedClient({
                    firstName,
                    lastName,
                    phone,
                  }),
                );

                setOpen(false);
              }}
              className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Αποθήκευση πελάτη
            </button>
          </div>
        </div>
      </div>
    </SlideOverWithCloseButtonOnOutside>
  );
};
