import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import {
  isResponseSuccessful,
  produceError,
  removeNonUsedDataFromEmployeeShiftsArray,
} from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getBranchEmployees } from "../employees/employees-slice";

//TODO clean up unused imports

const initialState = {
  isLoading: false,
};

export const editEmployeeShifts = createAsyncThunk(
  "employeeShiftsSlice/editEmployeeShifts",
  async ({ allWeekShiftsArray }, thunkAPI) => {
    const { shop, employees } = thunkAPI.getState();
    const shouldUseEmployeeToBeEditedData = employees?.employeeToBeEdited;
    const employeeIdWhenIsInEmployeeMode = employees?.data?.[0]?.id;
    const res = await Request.put(
      `EmployeeShifts/edit-employee-shift/${
        shouldUseEmployeeToBeEditedData
          ? employees?.employeeToBeEdited?.id
          : employeeIdWhenIsInEmployeeMode
      }/${shop?.id}/`,
      {
        body: removeNonUsedDataFromEmployeeShiftsArray([...allWeekShiftsArray]),
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop.id }));

      produceError(res);
    }
  },
);

const employeeShiftsSlice = createSlice({
  name: "EditEmployeeShifts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editEmployeeShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editEmployeeShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Οι βάρδιες αλλάξανε επιτυχώς");
      })
      .addCase(editEmployeeShifts.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

export const {} = employeeShiftsSlice.actions;

export default employeeShiftsSlice.reducer;
