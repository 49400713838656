import { UserIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../Utilities/utilities";

const AvatarComponent = ({
  image,
  imageSize,
  iconSize,
  iconColor,
  background,
  ringColor,
  iconFullFill,
  className,
}) => {
  return (
    <div
      className={classNames(
        className ? className : "",
        background ? background : "bg-white",
        imageSize ? imageSize : "h-6 w-6",
        iconColor ? iconColor : "text-gray-400",
        ringColor ? ringColor : "border-gray-400",
        "flex items-center border justify-center rounded-full  overflow-hidden",
      )}
    >
      {image ? (
        <img
          className={classNames(
            imageSize ? imageSize : "h-6 w-6",
            "object-cover",
          )}
          src={image}
          alt=""
        />
      ) : (
        <UserIcon
          className={classNames(
            iconSize ? iconSize : "h-4 w-4 ",
            iconFullFill ? iconFullFill : "stroke-2",
          )}
        />
      )}
    </div>
  );
};

export default AvatarComponent;
