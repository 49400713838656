import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState = {
    typeOfView: localStorage?.getItem('typeOfView') ? localStorage?.getItem('typeOfView') : 'weeklyView',//weeklyView, dayView, threeDayView,twoDayView
    numberOfMultipleWorkersShown: localStorage.getItem('numberOfMultipleWorkersShown') ? localStorage.getItem('numberOfMultipleWorkersShown') : null,
    firstViewWorker: localStorage?.getItem('firstViewWorker') ? JSON?.parse(localStorage.getItem('firstViewWorker')) : null,
    secondViewWorker: localStorage?.getItem('secondViewWorker') ? JSON?.parse(localStorage.getItem('secondViewWorker')) : null,
    thirdViewWorker: localStorage?.getItem('thirdViewWorker') ? JSON?.parse(localStorage.getItem('thirdViewWorker')) : null,
    selectedWorkerForWeekView: localStorage?.getItem('weekWorker') ? JSON?.parse(localStorage.getItem('weekWorker')) : null,
    shouldViewUpdate: `${0}`
};

const typeOfViewSlice = createSlice({
    name: "typeOfView",
    initialState,
    reducers: {
        setTypeOfView(state, action) {

            if (action.payload === 'weeklyView' || action.payload === 'threeDayView') {
                state.numberOfMultipleWorkersShown = null
            }

            if (action.payload === 'dayView' && state.numberOfMultipleWorkersShown) {
                state.numberOfMultipleWorkersShown = null

            }

            if (action.payload === 'twoDayView') {

                if (state.numberOfMultipleWorkersShown) {
                    state.numberOfMultipleWorkersShown = null
                }

            }


            localStorage.setItem('typeOfView', action.payload)
            state.typeOfView = action.payload;
        },
        setFirstViewWorker(state, action) {
            if (action.payload) {

                localStorage.setItem('firstViewWorker', JSON?.stringify(action.payload))
                state.firstViewWorker = action.payload;
            }

        },
        setSecondViewWorker(state, action) {
            if (action.payload) {

                localStorage.setItem('secondViewWorker', JSON?.stringify(action.payload))
                state.secondViewWorker = action.payload;
            }

        },
        setThirdViewWorker(state, action) {
            if (action.payload) {
                localStorage.setItem('thirdViewWorker', JSON?.stringify(
                    action.payload))
            }


            state.thirdViewWorker = action.payload;

        },
        setSelectedWorkerForWeekView(state, action) {
            if (action.payload) {
                localStorage.setItem('weekWorker', JSON?.stringify(
                    action.payload))
                state.selectedWorkerForWeekView = action.payload;
            }

        },
        setShouldViewUpdate(state, action) {
            state.shouldViewUpdate = `${Math.random()}`
        },
        setNumberOfMultipleWorkersShown(state, action) {
            if (action.payload === 2) {
                if (state.typeOfView === 'weeklyView' || state.typeOfView === 'threeDayView') {
                    localStorage.setItem('typeOfView', 'twoDayView')
                    state.typeOfView = 'twoDayView'
                }


            }
            if (action.payload === 3) {
                if (state.typeOfView === 'weeklyView' || state.typeOfView === 'twoDayView' || state.typeOfView === 'threeDayView') {
                    localStorage.setItem('typeOfView', 'dayView')
                    state.typeOfView = 'dayView'
                }
            }
            if (action.payload) {
                localStorage?.setItem('numberOfMultipleWorkersShown', action.payload)
            }
            if (!action.payload) {
                localStorage?.removeItem('numberOfMultipleWorkersShown')
            }
            state.numberOfMultipleWorkersShown = action.payload

        }


    },

});

export const {
    setSelectedWorkerForWeekView,
    setShouldViewUpdate,
    setThirdViewWorker,
    setFirstViewWorker,
    setSecondViewWorker,
    setTypeOfView,
    setNumberOfMultipleWorkersShown
} = typeOfViewSlice.actions;

export default typeOfViewSlice.reducer;
