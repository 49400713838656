import { Tooltip } from "@mui/material";
import { BellIcon } from "@heroicons/react/24/outline";

const NotificationBell = () => {
  return (
    <a
      type="button"
      onClick={() => {
        console.log("log");
      }}
      className="right-2 fixed md:hidden bottom-[90px] text-gray-700 shadow-xl border-[1px] bg-white focus:bg-indigo-400 transition-all font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2  "
    >
      <Tooltip title={"Notifications"}>
        <BellIcon className={"h-5 w-5"} />
      </Tooltip>
    </a>
  );
};
export default NotificationBell;
