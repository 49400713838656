import { Route, Routes } from "react-router-dom";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import ServicesPage from "../../Pages/ServicesPage/ServicesPage";
import WorkersPage from "../../Pages/WorkersPage/WorkersPage";
import SettingsPage from "../../Pages/SettingsPage/SettingsPage";
import ProfilePage from "../../Pages/ProfilePage/ProfilePage";
import ClientsPage from "../ClientsPage/ClientsPage";
import { CreateShopFlow } from "../../Pages/CreateShopFlow/CreateShopFlow";
import { EmployeeProfilePage } from "../../Pages/EmployeeProfilePage/EmployeeProfilePage";
import HomeScreen from "../../Pages/HomeScreen/HomeScreen";

export const OwnerRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<HomeScreen />} />
      <Route path="/calendar" element={<Dashboard />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/workers" element={<WorkersPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/clients" element={<ClientsPage />} />
      <Route path={"/create-shop"} element={<CreateShopFlow />} />
      <Route
        path={"/employee-profile/:idOfEmployee"}
        element={<EmployeeProfilePage />}
      />
    </Routes>
  );
};
