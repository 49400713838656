import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import {
  getBranchOwnerInfo,
  getEmployeeInfo,
} from "../personalData/personalData-slice";
import { isJwtTokenValid } from "../../Utilities/isJwtTokenValid";
import { getShop } from "../shop/shop-slice";
import { removeToken } from "../../notifications/firebase";
import { getBranchEmployees } from "../employees/employees-slice";

const doesOwnerHaveBranches = (res, thunkAPI) => {
  if (res?.payload?.branches?.length === 0) {
    thunkAPI.dispatch(setCreateShopFlow());
  }
  if (res?.payload?.branches?.length > 0) {
    thunkAPI?.dispatch(getShop({ id: res?.payload?.branches?.[0]?.id }));
    thunkAPI?.dispatch(
      getBranchEmployees({ id: res?.payload?.branches?.[0]?.id }),
    );
  }
};

export const login = createAsyncThunk(
  "authorizeUser/login",
  async (payload, thunkAPI) => {
    const res = await Request.post(`Authentication/authtoken`, {
      body: {
        email: payload.email,
        password: payload.password,
        loginAs: payload.loginAs,

        // isFromOAuth2: payload.isFromOAuth2,
        // tokenId: payload.tokenId
      },
    });
    if (isResponseSuccessful(res)) {
      if (res?.token) {
        localStorage.setItem("accessToken", res?.token);
      }

      if (!res.employeeId) {
        const res = await thunkAPI.dispatch(getBranchOwnerInfo());
        doesOwnerHaveBranches(res, thunkAPI);
      }
      if (res.employeeId) {
        thunkAPI.dispatch(getEmployeeInfo());
      }
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const logout = createAsyncThunk(
  "authorizeUser/logout",
  async (_, thunkAPI) => {
    await removeToken();
    thunkAPI.dispatch({ type: "RESET" });
    localStorage.clear();
  },
);

export const acceptInvitation = createAsyncThunk(
  "authorizeUser/acceptInvitation",
  async ({ data }, thunkAPI) => {
    const res = await Request.post(
      `Employee/register-employee/${data?.invitationCode}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const createEmployee = createAsyncThunk(
  "authorizeUser/createEmployee",
  async ({ payload, invitationCode }, thunkAPI) => {
    localStorage?.removeItem("accessToken");

    const res = await Request.post(
      `Employee/create-employee/${invitationCode}`,
      {
        body: {
          ...payload,
        },
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(logout());
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const registerBranchOwner = createAsyncThunk(
  "authorizeUser/registerBranchOwner",
  async ({ payload }, thunkAPI) => {
    const res = await Request.post("Branch/create-branch-owner", {
      body: {
        ...payload,
      },
    });

    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const manualLogin = createAsyncThunk(
  "authorizeUser/manualLogin",
  async (payload, thunkAPI) => {
    let accessToken = localStorage?.getItem("accessToken");

    const role = localStorage?.getItem("role");
    if (accessToken && accessToken !== "null") {
      let isValidToken = isJwtTokenValid(accessToken);
      if (isValidToken) {
        if (role === "employee") {
          thunkAPI.dispatch(getEmployeeInfo());
          const employeeIdFromStorage = localStorage?.getItem("employeeId");
          thunkAPI.dispatch(setEmployeeId(employeeIdFromStorage));
        }
        if (role === "owner") {
          const res = await thunkAPI.dispatch(getBranchOwnerInfo());
          doesOwnerHaveBranches(res, thunkAPI);
        }
        thunkAPI.dispatch(setLoggedInAs(role));
        return true;
      } else {
        thunkAPI.dispatch(logout());
        return produceError({ message: "Token Expired" });
      }
    } else {
      return produceError({ message: "Token not found" });
    }
  },
);

const initialState = {
  isLoggedIn: false,
  status: null,
  isLoading: false,
  isUserLoggedInUsingOAuth2: false,
  loggedInAs: localStorage?.getItem("role")
    ? localStorage?.getItem("role")
    : null,
  employeeId: null,
  enabledFlow: null,
};

const authorisedSlice = createSlice({
  name: "Authorised",
  initialState,
  reducers: {
    setLoggedInAs(state, action) {
      state.loggedInAs = action.payload;
    },
    setCreateShopFlow(state, action) {
      state.enabledFlow = "create-shop";
    },
    setRegisterEmployeeFlow(state, action) {
      state.enabledFlow = "registerEmployee";
    },
    setEnableFlowToNull(state, action) {
      state.enabledFlow = null;
    },
    setCreateEmployeeFlow(state, action) {
      state.enabledFlow = "createEmployee";
    },

    setEmployeeId(state, action) {
      state.employeeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = "success";
        state.isLoading = false;

        if (action.payload?.employeeId) {
          state.loggedInAs = "employee";
          localStorage.setItem("role", "employee");
          localStorage.setItem("employeeId", action.payload?.employeeId);
          state.employeeId = action.payload?.employeeId;
        }
        if (!action.payload?.employeeId) {
          state.loggedInAs = "owner";
          localStorage.setItem("role", "owner");
        }
      })
      .addCase(login.rejected, (state, action) => {
        toast.error(action.error.message);
        state.isLoading = false;
        state.isLoggedIn = false;
      })
      .addCase(manualLogin.fulfilled, (state, action) => {
        state.isLoggedIn = true;
      })
      .addCase(manualLogin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
      })
      .addCase(acceptInvitation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        toast.success(
          "Πλέον είσαι υπάλληλος του μαγαζιού! Συγχαρητήρια 🥳! Μπορείς να συνδεθείς.",
        );
      })
      .addCase(acceptInvitation.rejected, (state, action) => {
        state.isLoggedIn = false;
        toast.error(action.error.message);
      })
      .addCase(createEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(
          "Ο υπάλληλος δημιουργήθηκε. Συγχαρητήρια 🥳! Παρακαλώ συνδεθείτε",
        );
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;

        toast.error(action.error.message);
      })
      .addCase(registerBranchOwner.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(registerBranchOwner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enabledFlow = "create-shop";
        localStorage.setItem("accessToken", action?.payload?.token);
        localStorage.setItem("role", "owner");

        state.loggedInAs = "owner";
        state.isLoggedIn = true;
        toast.success(
          "🎉 Ο λογαριασμός δημιουργήθηκε, τώρα μπορείτε να φτιάξετε το κατάστημα σας",
        );
      })
      .addCase(registerBranchOwner.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isUserLoggedInUsingOAuth2 = false;
        state.loggedInAs = null;
      });
  },
});

export const isLoggedInAs = (state) => state?.authorizeUser?.loggedInAs;
export const {
  setEnableFlowToNull,
  setCreateShopFlow,
  setLoggedInAs,
  setEmployeeId,
} = authorisedSlice.actions;

export default authorisedSlice.reducer;
