import { classNames } from "../../Utilities/utilities";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";

export const ButtonWithLoader = ({
  isLoading,
  onClick,
  text,
  disabled,
  component,
  customStyling,
  type,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      className={classNames(
        !customStyling
          ? !component && !isLoading
            ? " disabled:bg-gray-300 cursor-pointer rounded-md bg-primary px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            : "p1 ml-1"
          : customStyling,
        "flex justify-center align-middle content-center items-center",
      )}
    >
      {!isLoading ? text || component : <SimpleLoader />}
    </button>
  );
};
