import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import {
  getTotalsOfAllTheServices,
  isResponseSuccessful,
  produceError,
} from "../../Utilities/utilities";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getBranchOrEmployeeAppointments } from "../appointments/appointments-slice";
import { getBranchClients } from "../clients/clientsSlice";

//TODO Don't use names like this -> 'objectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition'
//TODO You can use comments to describe the variable
const initialState = {
  isAppointmentSlideOverOpen: false,
  isEditAppointmentSlideOverOpen: false,
  selectedAppointment: null,
  selectedTime: null,
  selectedService: null,
  isAddEventManuallyPressed: null,
  indexOfDayThatWasPressed: null,
  selectedClient: null,
  selectedWorker: null,
  appointmentToEdit: null,
  durationOfAppointment: 0,
  objectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition:
    null,
  dayThatTheAppointmentWasDroppedAt: null,
  newTopPositionForAppointmentAfterDragAndDroppedOnAnotherDay: null,
  idOfAppointmentThatWillBeEdited: null,
  pixelThatCursorIsOnDuringDrag: 0,
  isAppointmentBeingDragged: false,
  indexOfDayThatAppointmentIsDraggedOn: null,
  indexOfWorkerThatAppointmentIsDraggedOn: null,
  extraData: "",
};

export const bookAppointment = createAsyncThunk(
  "appointment/book",
  async (payload, thunkAPI) => {
    const { appointment, shop } = thunkAPI.getState();
    const res = await Request.post(
      `EmployeeAppointment/create-force-appointment/${shop?.id}`,
      {
        body: {
          firstName: appointment?.selectedClient?.firstName,
          lastName: appointment?.selectedClient?.lastName,
          phone: appointment?.selectedClient?.phone,
          employeeId: appointment?.selectedWorker?.id,
          serviceId: [
            ...getTotalsOfAllTheServices(appointment?.selectedService).idsOfAll,
          ],
          startTime: dayjs(appointment?.selectedTime)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          duration: getTotalsOfAllTheServices(appointment?.selectedService)
            .durationOfAll,
          additionalInformation: appointment?.extraData,
        },
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments());
      thunkAPI.dispatch(getBranchClients());
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const appointmentToBeBookedSlice = createSlice({
  name: "Appointment",
  initialState,
  reducers: {
    setSelectedTime(state, action) {
      state.selectedTime = action.payload;
    },
    setIsAppointmentBeingDragged(state, action) {
      state.isAppointmentBeingDragged = action.payload;
    },
    setSelectedService(state, action) {
      state.selectedService = action.payload;
    },
    setSelectedClient(state, action) {
      state.selectedClient = action.payload;
    },
    setPixelThatCursorIsOnDuringDrag(state, action) {
      state.pixelThatCursorIsOnDuringDrag = action.payload;
    },
    setIsAppointmentSlideOverOpen(state, action) {
      state.isAppointmentSlideOverOpen = action.payload;
    },
    setDurationOfAppointment(state, action) {
      state.durationOfAppointment = parseInt(action.payload);
    },
    setIsAddEventManuallyPressed(state, action) {
      state.isAddEventManuallyPressed = action.payload;
    },
    setIndexOfDayThatWasPressed(state, action) {
      state.indexOfDayThatWasPressed = action.payload;
    },
    setSelectedWorker(state, action) {
      state.selectedWorker = action.payload;
    },
    setAppointmentToEdit(state, action) {
      state.appointmentToEdit = action.payload;
      if (action.payload && !state.isAppointmentSlideOverOpen) {
        state.isEditAppointmentSlideOverOpen = true;
      }
    },
    setIsEditAppointmentSlideOverOpen(state, action) {
      state.isEditAppointmentSlideOverOpen = action.payload;
    },
    setDayThatTheAppointmentWasDroppedAt(state, action) {
      state.dayThatTheAppointmentWasDroppedAt = action.payload;
    },
    setNewTopForAppointmentAfterDraggedAndDroppedOnAnotherDay(state, action) {
      state.newTopPositionForAppointmentAfterDragAndDroppedOnAnotherDay =
        action.payload;
    },
    setIdOfAppointmentThatWillBeEdited(state, action) {
      state.idOfAppointmentThatWillBeEdited = action.payload;
    },
    setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition(
      state,
      action,
    ) {
      state.objectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition =
        action.payload;
    },
    setIndexOfDayThatAppointmentWsDraggedOn(state, action) {
      state.indexOfDayThatAppointmentIsDraggedOn = action.payload;
    },
    setIndexOfWorkerThatAppointmentIsDraggedOn(state, action) {
      state.indexOfWorkerThatAppointmentIsDraggedOn = action.payload;
    },
    setExtraData(state, action) {
      state.extraData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bookAppointment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(bookAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Το ραντεβού κλείστηκε επιτυχώς");
      })
      .addCase(bookAppointment.rejected, (state, action) => {
        state.isLoading = false;
        toast.success(action.error.message);
      });
  },
});

export const selectedClientForAppointment = (state) =>
  state.appointment.selectedClient;
export const selectedServicesForAppointment = (state) =>
  state.appointment.selectedService;
export const durationOfAppointment = (state) =>
  state.appointment.durationOfAppointment;
export const appointmentToEdit = (state) => state.appointment.appointmentToEdit;
export const {
  setDurationOfAppointment,
  setIsAppointmentBeingDragged,
  setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition,
  setIsEditAppointmentSlideOverOpen,
  setAppointmentToEdit,
  setSelectedWorker,
  setIsAppointmentSlideOverOpen,
  setSelectedClient,
  setSelectedService,
  setSelectedTime,
  setIsAddEventManuallyPressed,
  setIndexOfDayThatWasPressed,
  setDayThatTheAppointmentWasDroppedAt,
  setNewTopForAppointmentAfterDraggedAndDroppedOnAnotherDay,
  setIdOfAppointmentThatWillBeEdited,
  setPixelThatCursorIsOnDuringDrag,
  setIndexOfDayThatAppointmentWsDraggedOn,
  setIndexOfWorkerThatAppointmentIsDraggedOn,
  setExtraData,
} = appointmentToBeBookedSlice.actions;

export default appointmentToBeBookedSlice.reducer;
