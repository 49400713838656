import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful } from "../../Utilities/utilities";

export const getTotalIncome = createAsyncThunk(
  "branch/total-cost-appointments",
  async ({ data }, thunkAPI) => {
    try {
      const { shop } = thunkAPI?.getState();
      const response = await Request.get(
        `branch/total-cost-appointments/${shop?.id}/${data?.dateStart}/${data?.dateEnd}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getYearIncomeWithEachMonths = createAsyncThunk(
  "dashboard/get-branch-monthly-income",
  async (year, thunkAPI) => {
    try {
      const { shop } = thunkAPI?.getState();
      const response = await Request.get(
        `Dashboard/get-branch-monthly-income/${year}/${shop?.id}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmployeeMonthlyIncome = createAsyncThunk(
  "dashboard/get-employee-monthly-income",
  async (data, thunkAPI) => {
    try {
      const { shop } = thunkAPI?.getState();
      const response = await Request.get(
        `Dashboard/get-employee-monthly-income/${data?.year}/${shop?.id}/${data?.employeeId}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getBestCustomers = createAsyncThunk(
  "dashboard/get-branch-customers",
  async (year, thunkAPI) => {
    try {
      const { shop } = thunkAPI?.getState();
      const response = await Request.get(
        `Dashboard/get-branch-customers/${shop?.id}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response?.customers;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmployeeIncome = createAsyncThunk(
  "branch/total-cost-appointments-for-employee",
  async ({ data }, thunkAPI) => {
    try {
      const { shop } = thunkAPI?.getState();
      const response = await Request.get(
        `branch/total-cost-appointments-for-employee/${shop?.id}/${data?.employee?.id}/${data?.dateStart}/${data?.dateEnd}`,
      );
      if (!isResponseSuccessful(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return { ...data?.employee, ...response?.data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

const initialState = {
  totalIncome: {
    totalAppointments: 0,
    totalIncome: 0,
    loading: false,
    error: null,
  },
  yearIncomeEachMonth: {
    fullYearIncome: "",
    data: [],
    loading: false,
    error: null,
  },
  employeeMonthlyIncome: {
    data: [],
    loading: false,
    error: null,
  },
  bestCustomers: {
    data: [],
    loading: false,
    error: null,
  },
  employeesIncome: {
    data: [],
    loading: false,
    error: null,
  },
};

const statisticsSlice = createSlice({
  name: "Statistics",
  initialState,
  reducers: {
    initializeEmployeesIncome(state, action) {
      state.employeesIncome.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      /** getTotalIncome **/
      .addCase(getTotalIncome.pending, (state, action) => {
        state.totalIncome.loading = true;
      })
      .addCase(getTotalIncome.fulfilled, (state, action) => {
        state.totalIncome.loading = false;
        state.totalIncome = {
          ...action.payload?.data?.information[2],
        };
        state.totalIncome.error = action.payload?.error || null;
      })
      .addCase(getTotalIncome.rejected, (state, action) => {
        state.totalIncome.loading = false;
        state.totalIncome.error = action?.payload || action?.error;
      })

      /** getYearIncomeWithEachMonths **/
      .addCase(getYearIncomeWithEachMonths.pending, (state, action) => {
        state.yearIncomeEachMonth.loading = true;
      })
      .addCase(getYearIncomeWithEachMonths.fulfilled, (state, action) => {
        const fullYearIncome = action.payload?.reduce(
          (total, entry) => total + entry?.totalIncome,
          0,
        );

        state.yearIncomeEachMonth.loading = false;
        state.yearIncomeEachMonth.fullYearIncome = fullYearIncome;
        state.yearIncomeEachMonth.data = action.payload;
        state.yearIncomeEachMonth.error = action.payload?.error || null;
      })
      .addCase(getYearIncomeWithEachMonths.rejected, (state, action) => {
        state.yearIncomeEachMonth.loading = false;
        state.yearIncomeEachMonth.error = action?.payload || action?.error;
      })

      /** getBestCustomers **/
      .addCase(getBestCustomers.pending, (state, action) => {
        state.bestCustomers.loading = true;
      })
      .addCase(getBestCustomers.fulfilled, (state, action) => {
        state.bestCustomers.loading = false;
        state.bestCustomers.data = action.payload;
        state.bestCustomers.error = action.payload?.error || null;
      })
      .addCase(getBestCustomers.rejected, (state, action) => {
        state.bestCustomers.loading = false;
        state.bestCustomers.error = action?.payload || action?.error;
      })

      /** getEmployeeMonthlyIncome **/
      .addCase(getEmployeeMonthlyIncome.pending, (state, action) => {
        state.employeeMonthlyIncome.loading = true;
      })
      .addCase(getEmployeeMonthlyIncome.fulfilled, (state, action) => {
        state.employeeMonthlyIncome.loading = false;
        state.employeeMonthlyIncome.data = action.payload;
        state.employeeMonthlyIncome.error = action.payload?.error || null;
      })
      .addCase(getEmployeeMonthlyIncome.rejected, (state, action) => {
        state.employeeMonthlyIncome.loading = false;
        state.employeeMonthlyIncome.error = action?.payload || action?.error;
      })

      /** getEmployeeIncome **/
      .addCase(getEmployeeIncome.pending, (state, action) => {
        state.employeesIncome.loading = true;
      })
      .addCase(getEmployeeIncome.fulfilled, (state, action) => {
        state.employeesIncome.loading = false;
        state.employeesIncome.data = [
          ...state.employeesIncome.data,
          action.payload,
        ];
        state.employeesIncome.error = action.payload?.error || null;
      })
      .addCase(getEmployeeIncome.rejected, (state, action) => {
        state.employeesIncome.loading = false;
        state.employeesIncome.error = action?.payload || action?.error;
      });
  },
});

/** Selectors **/
export const fullYearIncomeSelect = (state) =>
  calculateFinishedIncome(state.statistics.yearIncomeEachMonth?.data);

export const yearIncomeEachMonthSelect = (state) =>
  state.statistics.yearIncomeEachMonth?.data;

export const bestCustomersSelector = (state) =>
  state.statistics.bestCustomers?.data;

export const employeesIncomeSelector = (state) => {
  const data = [...state.statistics.employeesIncome?.data];
  if (data?.length > 0) {
    return data?.sort(
      (a, b) =>
        new Date(a?.createdDate).valueOf() - new Date(b?.createdDate).valueOf(),
    );
  }
  return data;
};

export const totalIncomeSelector = (state) =>
  state.statistics.totalIncome?.totalIncome;

export const totalAppointmentsSelector = (state) =>
  state.statistics.totalIncome?.totalAppointments;

export const { initializeEmployeesIncome } = statisticsSlice.actions;
export default statisticsSlice.reducer;

function calculateFinishedIncome(data) {
  let totalFinishedIncome = 0;

  data.forEach((monthData) => {
    monthData.information.forEach((info) => {
      if (info.appointmentStatus === "Finished") {
        totalFinishedIncome += info.totalIncome;
      }
    });
  });

  return totalFinishedIncome;
}
