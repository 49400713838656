import {
  CreditCardIcon,
  CurrencyDollarIcon,
  UsersIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import {
  totalAppointmentsSelector,
  totalIncomeSelector,
} from "../../../Features/statistics/statistics-slice";
import { useSelector } from "react-redux";
import { customersSelector } from "../../../Features/clients/clientsSlice";
import { ratingsSelector } from "../../../Features/reviews/reviews-slice";

const HeaderCards = () => {
  const totalIncome = useSelector(totalIncomeSelector);
  const customers = useSelector(customersSelector);
  const ratings = useSelector(ratingsSelector);
  const totalAppointments = useSelector(totalAppointmentsSelector);

  return (
    <>
      <div className="rounded-xl border bg-card text-card-foreground shadow">
        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
          <h3 className="tracking-tight text-sm font-medium">Εισόδημα</h3>
          <CurrencyDollarIcon className="h-5 w-5 stroke-1" />
        </div>
        <div className="p-6 pt-0">
          <div className="text-2xl font-bold">{totalIncome}€</div>
          {/*<p className="text-xs text-muted-foreground flex flex-row gap-2 text-green-600">*/}
          {/*  <ArrowTrendingUpIcon className="h-4 w-4 stroke-1" />*/}
          {/*  +20.1% από τον περασμένο μήνα*/}
          {/*</p>*/}
        </div>
      </div>
      <div className="rounded-xl border bg-card text-card-foreground shadow">
        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
          <h3 className="tracking-tight text-sm font-medium">Σύνολο πελατών</h3>
          <UsersIcon className="h-5 w-5 stroke-1" />
        </div>
        <div className="p-6 pt-0">
          <div className="text-2xl font-bold">{customers?.length}</div>
          {/*<p className="text-xs text-muted-foreground flex flex-row gap-2 text-green-600">*/}
          {/*  <ArrowTrendingUpIcon className="h-4 w-4 stroke-1" />*/}
          {/*  +180.1% από τον περασμένο μήνα*/}
          {/*</p>*/}
        </div>
      </div>
      <div className="rounded-xl border bg-card text-card-foreground shadow relative">
        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
          <h3 className="tracking-tight text-sm font-medium">
            Συνολικά ραντεβού
          </h3>
          <CreditCardIcon className="h-5 w-5 stroke-1" />
        </div>
        <div className="p-6 pt-0">
          <div className="text-2xl font-bold">{totalAppointments}</div>
          {/*<p className="text-xs text-muted-foreground flex flex-row gap-2 text-red-600">*/}
          {/*  <ArrowTrendingDownIcon className="h-4 w-4 stroke-1" />*/}
          {/*  -19% από τον περασμένο μήνα*/}
          {/*</p>          */}
        </div>
      </div>
      <div className="rounded-xl border bg-card text-card-foreground shadow">
        <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
          <h3 className="tracking-tight text-sm font-medium">Αξιολογήσεις</h3>
          <StarIcon className="h-5 w-5 stroke-1" />
        </div>
        <div className="p-6 pt-0">
          <div className="text-2xl font-bold">{ratings?.totalCount}</div>
          {/*<p className="text-xs text-muted-foreground flex flex-row gap-2 text-green-600">*/}
          {/*  <ArrowTrendingUpIcon className="h-4 w-4 stroke-1" />*/}
          {/*  +201 από τον περασμένο μήνα*/}
          {/*</p>*/}
        </div>
      </div>
    </>
  );
};
export default HeaderCards;
