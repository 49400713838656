import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { logout } from "../authorizeUser/authorizeUser-slice";
import { setFirstViewWorker } from "../typeOfView/typeOfView-slice";
import {
  setBranchEmployees,
  setEmployeeToBeEdited,
} from "../employees/employees-slice";
import { getShop, setShopIdAndName } from "../shop/shop-slice";

const initialState = {
  firstName: null,
  lastName: null,
  createdAt: null,
  email: null,
  profilePicUrl: null,
  phone: null,
  isLoading: false,
  branchId: null,
  branches: null,
  id: null,
};
//TODO you don't have to recall every time this call. Save the data in localstorage and set it form an action.
export const getBranchOwnerInfo = createAsyncThunk(
  "personalData/getBranchOwnerData",
  async (_, thunkAPI) => {
    const res = await Request.get(`User/get-branch-owner-info`, null);
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
      thunkAPI.dispatch(logout());
    }
  },
);
export const getEmployeeInfo = createAsyncThunk(
  "personalData/getPersonalData",
  async (payload, thunkAPI) => {
    const res = await Request.get(`User/get-employee-info`, null);
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(setFirstViewWorker(res));
      thunkAPI.dispatch(setBranchEmployees([res]));
      thunkAPI.dispatch(setShopIdAndName(res?.branches?.[0]));
      thunkAPI.dispatch(setEmployeeToBeEdited(res));
      await thunkAPI.dispatch(getShop({ id: res?.branches?.[0]?.branchId }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const savePersonalData = createAsyncThunk(
  "personalData/savePersonalData",
  async ({ payload }, thunkAPI) => {
    const res = await Request.put("User/update/", { body: payload });
    if (isResponseSuccessful(res)) {
      //TODO call this getBranchOwnerInfo() to get the new data
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

//TODO fix the initialState and past them here ->

const personalDataSlice = createSlice({
  name: "PersonalData",
  initialState,
  reducers: {
    setPersonalData(state, action) {
      if (action.payload) {
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.profilePicUrl = action.payload.profilePicUrl;
        state.branchId = action.payload.branchId;
        state.id = action.payload.id;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //TODO Use Comments like this to manage the cases. Sort cases (pending, fulfilled, rejected)
      /** getBranchOwnerInfo **/
      .addCase(getBranchOwnerInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.firstName = action.payload.firstName;
          state.lastName = action.payload.lastName;
          state.email = action.payload.email;
          state.phone = action.payload.phone;
          state.profilePicUrl = action.payload.profilePicUrl;
          state.branchId = action.payload.branchId;
          state.branches = action.payload.branches;
          state.id = action.payload.id;
        }
      })
      .addCase(getEmployeeInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.firstName = action.payload.firstName;
          state.lastName = action.payload.lastName;
          state.email = action.payload.email;
          state.phone = action.payload.phone;
          state.profilePicUrl = action.payload.profilePicUrl;
          state.branchId = action.payload.branchId;
          state.branches = action.payload.branches;
          state.id = action.payload.id;
        }
      })
      .addCase(getBranchOwnerInfo.rejected, (state, action) => {
        toast.error(action.error.message);
        state.isLoading = false;
      })
      .addCase(getEmployeeInfo.rejected, (state, action) => {
        toast.error(action.error.message);
        state.isLoading = false;
      })
      .addCase(getEmployeeInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchOwnerInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(savePersonalData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(savePersonalData.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Οι πληροφορίες του χρήστη αλλάξανε επιτυχώς");
      })
      .addCase(savePersonalData.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

//TODO selectors ->

export const { setPersonalData } = personalDataSlice.actions;

export default personalDataSlice.reducer;
