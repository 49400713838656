import { classNames } from "../Utilities/utilities";
import { toast } from "react-toastify";

const Msg = ({ title, body }) => {
  return (
    <div className={"grid items-center grid-cols-5 gap-2"}>
      <div
        className={classNames(
          title === "0" ? "bg-red-100" : "bg-green-100",
          "w-10 h-10 col-span-1 text-lg rounded-xl justify-center flex items-center",
        )}
      >
        🔔
      </div>
      <div className={"col-span-4"}>
        <h3 className={"font-semibold text-sm"}>
          {title === "Ακύρωση ραντεβού" ? "Ακύρωση ραντεβού" : "Νέο ραντεβού"}
        </h3>
        <p className={"text-xs text-ellipsis overflow-hidden"}>{body}</p>
      </div>
    </div>
  );
};

const NotificationToastComponent = (title, body) => {
  try {
    var audio = new Audio();
    audio.src = "../assets/level-up-notification.mp3";
    // when the sound has been loaded, execute your code
    audio.oncanplaythrough = (event) => {
      var playedPromise = audio.play();
      if (playedPromise) {
        playedPromise
          .catch((e) => {
            console.log(e);
            if (
              e.name === "NotAllowedError" ||
              e.name === "NotSupportedError"
            ) {
              console.log(e.name);
            }
          })
          .then(() => {
            console.log("playing sound !!!");
          });
      }
    };
    toast(<Msg title={title} body={body} />, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } catch (e) {
    console.log(e);
  }
};

export default NotificationToastComponent;
