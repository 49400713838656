import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { classNames } from "../../Utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import { getShop } from "../../Features/shop/shop-slice";
import { Tooltip } from "@mui/material";
import { PlusIcon } from "@heroicons/react/24/outline";

export const BranchSelector = ({ setIsAddShopClicked }) => {
  const branches = useSelector((state) => state.personalData.branches);
  const shopId = useSelector((state) => state.shop.id);
  const dispatch = useDispatch();
  return (
    <Listbox value={null} onChange={null}>
      {({ open }) => (
        <>
          <Listbox.Label className="font-medium pl-1 pr-1 text-gray-900 flex flex-row justify-between w-full  sm:flex-none sm:pr-1">
            Επιλεγμένο Κατάστημα
            <div>
              <Tooltip title={"Προσθήκη Μαγαζιού"}>
                <PlusIcon
                  onClick={() => setIsAddShopClicked(true)}
                  className={classNames(
                    "text-white rounded bg-primary hover:bg-indigo-500 h-5 w-5 shrink-0 ",
                  )}
                />
              </Tooltip>
            </div>
          </Listbox.Label>
          <div className="relative ">
            <Listbox.Button className="relative w-full mt-1 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                {branches?.filter((branch) => branch?.id === shopId)?.[0]?.name}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {branches?.map((branch) => (
                  <Listbox.Option
                    key={branch.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-primary text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={branch}
                    onClick={() => {
                      dispatch(getShop({ id: branch?.id }));
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate",
                            )}
                          >
                            {branch.name}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
