import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import {
  classNames,
  hoursInDayArrayConstructor,
  indexOfDays,
  thirtyMinutesInAnHour,
} from "../../Utilities/utilities";
import dayjs from "dayjs";
import { ConstructWeeklyShifts } from "../../Hooks/ConstructWeeklyShifts";
import { useSelector } from "react-redux";

export default function CustomTimePicker({
  from,
  day,
  allWeeksShiftsArray,
  setAllWeekShiftsArray,
  index,
  givenTime,
  noLessThanTime,
  openUpwards,
}) {
  const hoursInDay = hoursInDayArrayConstructor(
    noLessThanTime ? noLessThanTime?.split(":")?.[0] : null,
  );
  const minutesInAnHour = thirtyMinutesInAnHour();
  const [minutes, setMinutes] = useState(
    givenTime?.split(":")?.[1] ? givenTime?.split(":")?.[1] : "00",
  );
  const [hours, setHours] = useState(
    givenTime?.split(":")?.[0] ? givenTime?.split(":")?.[0] : "00",
  );
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  return (
    <div
      style={{
        alignItems: "center",
        width: "100%",
        justifyContent: "space-evenly",
        minHeight: "34px",
      }}
      className={
        "flex flex-row border-[1px] border-gray-200 shadow-md rounded-md max-w-[150px] min-w-[90px]"
      }
    >
      <Listbox disabled={loggedInAs === "employee"}>
        {({ open }) => (
          <>
            <div className="relative w-full">
              <Listbox.Button className="relative w-full text-center cursor-pointer text-white bg-transparent focus:outline-none font-normal sm:text-md sm:leading-6">
                <span className="truncate text-black">
                  {givenTime?.split(":")?.[0]}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={classNames(
                    "absolute  z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                    openUpwards ? "bottom-8" : "",
                  )}
                >
                  {hoursInDay.map((hourFromArr) => (
                    <Listbox.Option
                      disabled={
                        noLessThanTime
                          ? parseInt(hourFromArr) <=
                            noLessThanTime?.split(":")?.[0]
                          : false
                      }
                      onClick={() => {
                        setHours(hourFromArr);
                        ConstructWeeklyShifts(
                          indexOfDays[day],
                          index,
                          from ? "from" : "to",
                          `${hourFromArr}:${minutes}`,
                          null,
                          allWeeksShiftsArray,
                          setAllWeekShiftsArray,
                        );
                      }}
                      key={hourFromArr}
                      className={({ active }) =>
                        classNames(
                          hourFromArr <= noLessThanTime?.split(":")?.[0]
                            ? "bg-gray-200 text-gray-300"
                            : "text-gray-900 ",
                          "relative cursor-default select-none py-2 pl-3 pr-9 disabled:text-gray-300 ",
                        )
                      }
                      value={hourFromArr}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              hourFromArr === noLessThanTime?.split(":")?.[0]
                                ? "font-semibold"
                                : "font-normal",
                              "block",
                            )}
                          >
                            {hourFromArr}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <span className={"text-black semi-bold"}>:</span>
      <Listbox disabled={loggedInAs === "employee"}>
        {({ open }) => (
          <>
            <div className="relative w-full">
              <Listbox.Button className="relative w-full cursor-pointer text-white bg-transparent focus:outline-none font-normal sm:text-sm sm:leading-6">
                <span className="text-black truncate">
                  {givenTime?.split(":")?.[1]}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={classNames(
                    "absolute  z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                    openUpwards ? "bottom-8" : "",
                  )}
                >
                  {minutesInAnHour.map((minutesFromArr) => (
                    <Listbox.Option
                      disabled={
                        noLessThanTime
                          ? parseInt(hours) < noLessThanTime?.split(":")?.[0] ||
                            (parseInt(noLessThanTime?.split(":")?.[0]) ===
                              parseInt(hours) &&
                              parseInt(minutesFromArr) <=
                                parseInt(noLessThanTime?.split(":")?.[1]))
                          : false
                      }
                      onClick={() => {
                        setMinutes(minutesFromArr);
                        ConstructWeeklyShifts(
                          indexOfDays[day],
                          index,
                          from ? "from" : "to",
                          `${hours}:${minutesFromArr}`,
                          null,
                          allWeeksShiftsArray,
                          setAllWeekShiftsArray,
                        );
                      }}
                      key={minutesFromArr}
                      className={({ active }) =>
                        classNames(
                          minutesFromArr === noLessThanTime?.split(":")?.[0]
                            ? "bg-primary text-white"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9 ",
                        )
                      }
                      value={minutesFromArr}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              minutesFromArr === noLessThanTime?.split(":")?.[0]
                                ? "font-semibold"
                                : "font-normal",
                              "block",
                              noLessThanTime &&
                                (parseInt(noLessThanTime?.split(":")?.[0]) <
                                  parseInt(
                                    dayjs(noLessThanTime).format("HH"),
                                  ) ||
                                  (parseInt(noLessThanTime?.split(":")?.[0]) ===
                                    parseInt(
                                      dayjs(noLessThanTime).format("HH"),
                                    ) &&
                                    parseInt(minutesFromArr) <=
                                      parseInt(
                                        dayjs(noLessThanTime).format("mm"),
                                      )))
                                ? "text-gray-500"
                                : "",
                            )}
                          >
                            {minutesFromArr}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
