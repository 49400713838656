import { useDispatch, useSelector } from "react-redux";
import WeeklyView from "../../Components/WeeklyView/WeeklyView";
import DayView from "../../Components/DayView/DayView";
import TwoDayViewConstructor from "../../Components/TwoDayViewConstructor/TwoDayViewConstructor";
import ThreeDayViewConstructor from "../../Components/ThreeDayViewConstructor/ThreeDayViewConstructor";
import MultipleWorkersCalendars from "../../Components/MultipleWorkersCalendars/MultipleWorkersCalendars";
import { BookAppointmentSlideOver } from "../../Components/BookAppointmentSlideOver/BookAppointmentSlideOver";
import { EditAppointmentSlideOver } from "../../Components/EditAppointmentSlideOver/EditAppointmentSlideOver";
import { MobileCalendarSettingsSidebar } from "../../Components/MobileCalendarSettingsSidebar/MobileCalendarSettingsSidebar";
import CenteredModalWithSingleAction from "../../Components/CenteredModalWithSingleActions/CenteredModalWithSingleAction";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GoToCurrentTimeButton } from "../../Components/GoToCurrentTimeButton/GoToCurrentTimeButton";
import NotificationBell from "./components/NotificationBell";
import { getBranchOrEmployeeAppointments } from "../../Features/appointments/appointments-slice";
const Dashboard = () => {
  const typeOfView = useSelector((state) => state.typeOfView.typeOfView);
  const isShopDataLoading = useSelector((state) => state.shop.isLoading);
  const isEmployeesDataLoading = useSelector(
    (state) => state.employees.isLoading,
  );
  const navigate = useNavigate();
  const employees = useSelector((state) => state.employees?.data);
  const numberOfMultipleWorkersShown = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );
  const [id, setId] = useState(null);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const dispatch = useDispatch();
  const shopData = useSelector((state) => state.shop);
  useEffect(() => {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setId(null);
      }
    }
  }, [id]);

  useEffect(() => {
    if (shopData?.id) {
      dispatch(getBranchOrEmployeeAppointments());
    }
  }, [typeOfView, selectedDate, shopData]);

  return (
    <>
      {shopData?.id &&
        !isShopDataLoading &&
        !isEmployeesDataLoading &&
        employees?.length === 0 && (
          <CenteredModalWithSingleAction
            onClick={() => {
              navigate("/workers");
            }}
            shouldNotClose={true}
            message={
              "Για να το χρησιμοποιήσεις πρέπει να έχεις τουλάχιστον έναν υπάλληλο"
            }
            callToActionText={"Πάνε στους υπαλλήλους"}
            title={"Δεν μπορείς να χρησιμοποιήσεις το ημερολόγιο"}
          />
        )}
      <MobileCalendarSettingsSidebar />
      <BookAppointmentSlideOver />
      <EditAppointmentSlideOver />

      {!numberOfMultipleWorkersShown && (
        <>
          {typeOfView === "weeklyView" && <WeeklyView />}
          {typeOfView === "dayView" && <DayView />}
          {typeOfView === "twoDayView" && <TwoDayViewConstructor />}
          {typeOfView === "threeDayView" && <ThreeDayViewConstructor />}
        </>
      )}
      {numberOfMultipleWorkersShown && (
        <>
          <MultipleWorkersCalendars />
        </>
      )}
      <NotificationBell />
      <GoToCurrentTimeButton setId={setId} />
    </>
  );
};

export default Dashboard;
