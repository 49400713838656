import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setSelectedDate } from "../../Features/calendar/calendar-slice";
import { CalendarDropDown } from "../CalendarDropdown/CalendarDropDown";

export default function DaySelector() {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const dispatch = useDispatch();
  return (
    <div
      style={{ zIndex: 1, alignItems: "center", justifyItems: "center" }}
      className=" w-full h-[40px] grid grid-cols-4 justify-center p-[8px] rounded-md ring-1 ring-inset ring-gray-300 shadow-sm"
    >
      <button
        onClick={() => {
          dispatch(setSelectedDate(dayjs(selectedDate).subtract(1, "d")));
        }}
        type="button"
        className="flex items-center justify-center col-start-1 pr-1 hover:text-gray-500 focus:relative md:w-9 md:pr-0 "
      >
        <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
      </button>

      <button
        type="button"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        className="h-full  col-span-2 text-sm font-semibold text-gray-900"
      >
        <CalendarDropDown />
      </button>

      <button
        onClick={() => {
          dispatch(setSelectedDate(dayjs(selectedDate).add(1, "d")));
        }}
        type="button"
        className="flex items-center col-start-4 justify-center pl-1 hover:text-gray-500 focus:relative md:w-9 md:pl-0"
      >
        <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
}
