// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken } from "firebase/messaging";
import { isMobile } from "react-device-detect";
import Request from "../Utilities/Request";
import { isResponseSuccessful, produceError } from "../Utilities/utilities";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB6Ge3LmS62CNO3C0dZjaYnCYwB23Cqi7Q",
  authDomain: "booke-app-43ee3.firebaseapp.com",
  projectId: "booke-app-43ee3",
  storageBucket: "booke-app-43ee3.appspot.com",
  messagingSenderId: "380146119373",
  appId: "1:380146119373:web:80f464e85d1e1fc8022f84",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = () => {
  try {
    const fcm = localStorage.getItem("firebaseToken");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted" || isMobile) {
        getToken(messaging, {
          vapidKey:
            "BJTq7XffceSD9JlfVvXxlvbuQWKPR_eOgV-5slEWdtA6JhbflnkztRhRQiqa97zwzhQqSOwNstieh_GeyR1p-Ks",
        }).then((token) => {
          if (token) {
            if (fcm && fcm !== token) {
              // Remove existing token and overwrite with new
              return updateFirebaseToken(fcm, token).then(
                (res) => res && localStorage.setItem("firebaseToken", token),
              );
            } else if (!fcm) {
              return saveFirebaseToken(token).then(
                (res) => res && localStorage.setItem("firebaseToken", token),
              );
            }
          }
        });
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeToken = async () => {
  try {
    const token = localStorage.getItem("firebaseToken");
    Notification.requestPermission().then(async (permission) => {
      if (token && permission === "granted") {
        await deleteFirebaseToken(token);
        await deleteToken(messaging);
      }
    });
  } catch (e) {
    return console.log("Remove Firebase Token error:" + e?.toString());
  }
};

const saveFirebaseToken = async (token) => {
  try {
    const res = await Request.post(`Device/register-device-token/${token}`);
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  } catch (e) {
    console.log(e);
  }
};

const updateFirebaseToken = async (oldToken, newToken) => {
  try {
    const res = await Request.put(
      `Device/replace-device-token/${oldToken}/${newToken}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  } catch (e) {
    console.log(e);
  }
};

const deleteFirebaseToken = async (token) => {
  try {
    const res = await Request.remove(`Device/delete-device-token/${token}`);
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  } catch (e) {
    console.log(e);
  }
};
