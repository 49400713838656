import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const initialState = {
  isVisible: false,
  data: [],
  isLoading: false,
};

export const getBranchOrEmployeeAppointments = createAsyncThunk(
  "appointments/getAppointments",
  async (data, thunkAPI) => {
    const { shop, calendar, typeOfView, employees, authorizeUser } =
      thunkAPI.getState();
    const role = localStorage?.getItem("role");
    let days = 7;

    switch (typeOfView?.typeOfView) {
      case "dayView":
        days = 1;
        break;
      case "twoDayView":
        days = 2;
        break;
      case "threeDayView":
        days = 3;
        break;
      case "weeklyView":
        days = 7;
        break;
      default:
        days = 7;
    }

    let res;
    if (role === "owner") {
      res = await Request.get(
        `Branch/get-all-appointments/${data?.id ? data?.id : shop?.id}/${dayjs(calendar?.selectedDate).format("YYYY-MM-DD")}/${days}`,
      );
    }
    if (role === "employee") {
      res = await Request.get(
        `Employee/fetch-all-appointments/${authorizeUser?.employeeId}`,
      );
    }
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const modalSlice = createSlice({
  name: "Appointments",
  initialState,
  reducers: {
    setAppointments(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchOrEmployeeAppointments.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchOrEmployeeAppointments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getBranchOrEmployeeAppointments.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

export const { setAppointments } = modalSlice.actions;

export default modalSlice.reducer;
