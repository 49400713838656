import MobileNavigationSideBar from "../MobileNavigationSideBar/MobileNavigationSideBar";
import DesktopNavigationSideBar from "../DesktopNavigationSideBar/DesktopNavigationSideBar";
import { useEffect, useState } from "react";
import {
  Bars3Icon,
  CalendarIcon,
  ClockIcon,
  Cog6ToothIcon,
  HomeIcon,
  QueueListIcon,
  UserPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/solid";

export const Navigations = () => {
  const isLoggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);
  const isLoggedIn = useSelector((state) => state.authorizeUser.isLoggedIn);
  const enabledFlow = useSelector((state) => state.authorizeUser.enabledFlow);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const personalData = useSelector((state) => state.personalData);
  const role = localStorage?.getItem("role");

  const [urls, setUrls] = useState([
    {
      name: "Αρχική",
      path: "/",
      icon: HomeIcon,
    },
    {
      name: "Ημερολόγιο",
      path: "/calendar",
      icon: CalendarIcon,
    },
    {
      name: "Υπάλληλοι",
      path: "/workers",
      icon: UsersIcon,
    },
    {
      name: "Υπηρεσίες",
      path: "/services",
      icon: QueueListIcon,
    },
    {
      name: "Ρυθμίσεις",
      path: "/settings",
      icon: Cog6ToothIcon,
    },
    {
      name: "Πελάτες",
      path: "/clients",
      icon: UserPlusIcon,
    },
  ]);

  useEffect(() => {
    if (isLoggedInAs === "employee") {
      setUrls([
        {
          name: "Ημερολόγιο",
          path: "/calendar",
          icon: CalendarIcon,
        },
        {
          name: "Ωράριο",
          path: "/shifts",
          icon: ClockIcon,
        },
      ]);
    }

    if (isLoggedInAs === "owner") {
      setUrls([
        {
          name: "Αρχική",
          path: "/",
          icon: HomeIcon,
        },
        {
          name: "Ημερολόγιο",
          path: "/calendar",
          icon: CalendarIcon,
        },
        {
          name: "Υπάλληλοι",
          path: "/workers",
          icon: UsersIcon,
        },
        {
          name: "Υπηρεσίες",
          path: "/services",
          icon: QueueListIcon,
        },
        {
          name: "Ρυθμίσεις",
          path: "/settings",
          icon: Cog6ToothIcon,
        },
        {
          name: "Πελάτες",
          path: "/clients",
          icon: UserPlusIcon,
        },
      ]);
    }
  }, [isLoggedInAs]);

  if (role && !enabledFlow) {
    return (
      <>
        <div className="static top-0 z-40 flex items-center gap-x-6 bg-primary-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-white lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white"></div>
          <Link to={"/profile"} href="#">
            <span className="sr-only">Your profile</span>
            {!personalData?.profilePicUrl ? (
              <UserCircleIcon
                className="h-8 w-8 text-white"
                aria-hidden="true"
              />
            ) : (
              <img
                style={{
                  objectFit: "cover",
                }}
                className="h-8 w-8 rounded-full shadow-xl bg-white"
                src={personalData?.profilePicUrl}
                alt=""
              />
            )}
          </Link>
        </div>
        <MobileNavigationSideBar
          urls={urls}
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
        />
        {/* Static sidebar for desktop */}
        <DesktopNavigationSideBar urls={urls} />
      </>
    );
  }
};
