import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import ServiceCard from "../ServiceCard/ServiceCard";
import { getTotalsOfAllTheServices } from "../../Utilities/utilities";

export const SelectAServiceButtonInSlideOver = ({
  openSelectServiceFromList,
  onEdit,
}) => {
  const selectedServiceForAppointment = useSelector(
    (state) => state.appointment.selectedService,
  );

  if (
    !selectedServiceForAppointment ||
    selectedServiceForAppointment?.length === 0
  ) {
    return (
      <button
        type="button"
        onClick={openSelectServiceFromList}
        className="w-full relative flex items-center justify-between p-4 text-sm font-semibold text-gray-900
         hover:bg-gray-50 border-[1px] border-gray-200 rounded-xl"
      >
        Διάλεξε μία υπηρεσία
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    );
  }

  if (
    selectedServiceForAppointment &&
    selectedServiceForAppointment?.length > 0
  ) {
    return (
      <div className={"grid gap-2"}>
        {selectedServiceForAppointment.map((service) => (
          <ServiceCard
            key={service?.id}
            openSelectServiceFromList={openSelectServiceFromList}
            onEdit={onEdit}
            service={service}
          />
        ))}
        <div
          className={
            "inline-flex justify-between py-2 border-t-[1px] border-gray-200 font-bold"
          }
        >
          <span className={"text-lg"}>Σύνολο:</span>
          <span className={"text-lg"}>
            {
              getTotalsOfAllTheServices(selectedServiceForAppointment)
                .priceOfAll
            }
            €
          </span>
        </div>
      </div>
    );
  }
};
