import {
  createEmployee,
  registerBranchOwner,
} from "../../Features/authorizeUser/authorizeUser-slice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SelectSex from "../SelectSex/SelectSex";
import { checkEmptyProperties } from "../../Utilities/utilities";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";

export const SignUpForm = ({ setUserWantsToSignIn, isSigningUpEmployee }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [occupation, setOccupation] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [selectedSex, setSelectedSex] = useState(null);
  const { pathname } = location;
  const navigate = useNavigate();
  const invitationCode = pathname?.replace("/create-employee/", "");

  const registerOwner = async () => {
    dispatch(
      registerBranchOwner({
        payload: {
          firstName,
          lastName,
          createdAt: `${dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss")}`,
          email,
          password,
          profilePicUrl:
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          phone,
          genderValue: selectedSex.name,
        },
      }),
    );
  };
  const registerEmployeeFunc = async () => {
    const res = await dispatch(
      createEmployee({
        payload: {
          firstName,
          lastName,
          createdAt: `${dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss")}`,
          email,
          password,
          profilePicUrl:
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          phone,
          genderValue: selectedSex.name,
          occupation: occupation,
          description: description,
        },
        invitationCode,
      }),
    );
    if (!res?.error) {
      navigate("/");
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Δημιούργησε ένα λογαριασμό{" "}
            {isSigningUpEmployee ? "υπαλλήλου" : "ιδιοκτήτη"}
          </h2>
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-1">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Όνομα
                {isSigningUpEmployee && " (Έτσι θα εμφανίζεται στους πελάτες)"}
              </label>
              <div className="mt-2">
                <input
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  name="first-name"
                  type="text"
                  autoComplete="first-name"
                  required
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Επίθετο
                {isSigningUpEmployee && " (Έτσι θα εμφανίζεται στους πελάτες)"}
              </label>
              <div className="mt-2">
                <input
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  name="lastName"
                  type="text"
                  autoComplete="last-name"
                  required
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
                {isSigningUpEmployee &&
                  " (Πρέπει να είναι ίδιο με το email στο οποίο έλαβες την πρόσκληση)"}
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Κωδικός
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Τηλέφωνο
                </label>
              </div>
              <div className="mt-2">
                <PhoneNumberInput
                  phone={phone}
                  setPhone={setPhone}
                  className={"border"}
                />
                {/*<input*/}
                {/*  id="phone"*/}
                {/*  value={phone}*/}
                {/*  onChange={(e) => {*/}
                {/*    setPhone(e.target.value);*/}
                {/*  }}*/}
                {/*  name="phone"*/}
                {/*  type="phone"*/}
                {/*  autoComplete="phone"*/}
                {/*  required*/}
                {/*  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"*/}
                {/*/>*/}
              </div>
            </div>
            {isSigningUpEmployee && (
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Ειδικότητα
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="occupation"
                    value={occupation}
                    onChange={(e) => {
                      setOccupation(e.target.value);
                    }}
                    name="occupation"
                    type="text"
                    autoComplete="occupation"
                    required
                    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                  />
                </div>
              </div>
            )}

            <SelectSex
              isFromSignUp={true}
              selectedSex={selectedSex}
              setSelectedSex={setSelectedSex}
            />
            {isSigningUpEmployee && (
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Περιγραφή
                  </label>
                </div>
                <div className="mt-2">
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    name="description"
                    required
                    className="pt-2 border mt-1 rounded px-4 w-full bg-gray-50"
                  />
                </div>
              </div>
            )}
            <div>
              <button
                disabled={
                  checkEmptyProperties({
                    firstName,
                    lastName,
                    email,
                    phone,
                    password,
                  })?.length > 0 ||
                  !isValidPhoneNumber(phone) ||
                  (isSigningUpEmployee
                    ? checkEmptyProperties({
                        occupation,
                        description,
                      })?.length > 0
                    : false)
                }
                onClick={() => {
                  if (!isSigningUpEmployee) {
                    registerOwner();
                  }
                  if (isSigningUpEmployee) {
                    registerEmployeeFunc();
                  }
                }}
                className="disabled:bg-gray-400 flex w-full justify-center rounded-md bg-primary mt-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Εγγραφή
              </button>
            </div>
            {!isSigningUpEmployee && (
              <p className="mt-5 text-center text-sm text-gray-500">
                Έχεις λογαριασμό ?{" "}
                <span
                  onClick={() => setUserWantsToSignIn()}
                  className="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  Συνδέσου
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
