import {
  Bar,
  BarChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { useEffect, useState } from "react";

export function MyBarChart({ data, fullYearIncome }) {
  const [chartData, setChartData] = useState([
    {
      name: "Ιαν",
      total: 0,
    },
    {
      name: "Φεβ",
      total: 0,
    },
    {
      name: "Μαρ",
      total: 0,
    },
    {
      name: "Απρ",
      total: 0,
    },
    {
      name: "Μαι",
      total: 0,
    },
    {
      name: "Ιουν",
      total: 0,
    },
    {
      name: "Ιουλ",
      total: 0,
    },
    {
      name: "Αυγ",
      total: 0,
    },
    {
      name: "Σεπ",
      total: 0,
    },
    {
      name: "Οκτ",
      total: 0,
    },
    {
      name: "Νοε",
      total: 0,
    },
    {
      name: "Δεκ",
      total: 0,
    },
  ]);

  useEffect(() => {
    if (data?.length) {
      createCartData();
    }
  }, [data]);

  const createCartData = () => {
    let newData = [];
    data?.map((item, index) => {
      newData.push({
        ...chartData[index],
        total: item?.information[2]?.totalIncome,
      });
    });
    setChartData(newData);
  };

  const renderCustomBarLabel = ({ x, y, width, value }) => {
    return (
      <text
        x={x + width / 2}
        y={y}
        className={"text-xs hidden md:block"}
        fill="#666"
        textAnchor="middle"
        dy={-2}
      >
        {value}
      </text>
    );
  };

  return (
    <div>
      <div className={"border-b mb-2 p-2 bg-gray-100"}>
        <h4 className="text-center font-semibold  leading-none tracking-tight">
          2024 - Σύνολο {fullYearIncome}€
        </h4>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={chartData}
          margin={{
            top: 15,
            right: 0,
            left: -12,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="name"
            stroke="#888888"
            fontSize={12}
            tickLine={true}
            axisLine={true}
            allowDataOverflow={false}
            includeHidden={true}
            type={"category"}
          />
          <YAxis
            // domain={[0, 500]}
            stroke="#888888"
            fontSize={10}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => `${value}€`}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip wrapperClassName={"rounded-xl shadow-lg"} />

          <Bar
            dataKey="total"
            fill="currentColor"
            radius={[4, 4, 0, 0]}
            className="fill-primary"
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
