import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { manualLogin } from "./Features/authorizeUser/authorizeUser-slice";
import "dayjs/locale/el";
import { ChangeViewWorkersIfTheyHaveBeenUpdated } from "./Hooks/ChangeViewWorkersIfTheyHaveBeenUpdated";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HorizontalLoader from "./Components/Loaders/HorizontalLoader";
import { Navigations } from "./Components/Navigations/Navigations";
import { ConnectWithGoogleApi } from "./Hooks/ConnectWithGoogleApi";
import { AppRouter } from "./Components/AppRouter/AppRouter";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import NotificationToastComponent from "./notifications/NotificationToastComponent";
import { classNames } from "./Utilities/utilities";
import { getBranchOrEmployeeAppointments } from "./Features/appointments/appointments-slice";

function App() {
  const dispatch = useDispatch();
  const isGetEmployeesLoading = useSelector(
    (state) => state.employees.isLoading,
  );
  const enabledFlow = useSelector((state) => state.authorizeUser.enabledFlow);
  const isGetShopLoading = useSelector((state) => state.shop.isLoading);
  const isGetAppointmentsLoading = useSelector(
    (state) => state.appointments.isLoading,
  );
  const role = localStorage?.getItem("role");

  useEffect(() => {
    //Try open the app from IP you get this error
    //Error: This browser doesn't support the API's required to use the Firebase SDK.
    //FIX in app.js comment out imports and firebase functions

    if (role) {
      try {
        onMessage(messaging, ({ notification }) => {
          NotificationToastComponent(notification?.title, notification?.body);
          dispatch(getBranchOrEmployeeAppointments());
        });
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    if (role) {
      generateToken();
    }
  }, [role]);

  //hooks
  ChangeViewWorkersIfTheyHaveBeenUpdated();
  ConnectWithGoogleApi();
  // checkValidityOfAccessToken();

  useEffect(() => {
    dispatch(manualLogin());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider adapterLocale={"el"} dateAdapter={AdapterDayjs}>
        <ToastContainer stacked />
        <div>
          <Navigations />
          {(isGetEmployeesLoading ||
            isGetShopLoading ||
            isGetAppointmentsLoading) && (
            <div className={"relative z-[49]"}>
              <HorizontalLoader />
            </div>
          )}

          <main className=" py-2 sm:pb-10 scroll-smooth ">
            <div
              className={classNames(
                role && !enabledFlow ? "lg:ml-14 px-1" : "",
              )}
            >
              <AppRouter />
            </div>
          </main>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
