const isProd = process.env.NODE_ENV === "production";
const uatHost = "https://prod.booke.gr/api";
const prodHost = "https://uat.booke.gr/api";
const apiHost = isProd ? prodHost : uatHost;
const apiVersion = "/v1/";
const isAuthenticated = true; // development helper.

// If logHttpRequests is true, Request methods will log all requests/responses, unless enableConsoleLogging is disabled.
const logHttpRequests = true;
const enableConsoleLogging = process.env.NODE_ENV !== "production"; // Globally toggle Logger class console logging
// consoleLoggingLevel and consoleLoggingDetail are not implemented yet
const consoleLoggingLevel = 3; // 1: Log only Errors, 2: Log Errors and Warnings, 3: Log everything
const consoleLoggingDetail = 3; // 1: Show only title, 2: Show only timestamp and title, 3: Show everything
const services = [
  "Κομμωτική & styling",
  "Υπηρεσίες νυχιών",
  "Barbering",
  "Φρύδια & βλεφαρίδες",
  "Περιποίηση επιδερμίδας",
  "Μακιγιάζ",
  "Επέκταση μαλλιών",
  "Μασάζ & Spa",
  "Αποτρίχωση",
  "Τρυπήματα & τατουάζ",
];
export {
  apiHost,
  apiVersion,
  logHttpRequests,
  enableConsoleLogging,
  consoleLoggingLevel,
  consoleLoggingDetail,
  isAuthenticated,
  services,
};
