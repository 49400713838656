import { CalendarDaysIcon } from "@heroicons/react/24/outline";

export const NavigationInsideBookAppointmentSlideOver = () => {
  return (
    <div>
      <span
        className={
          "w-full group flex items-center gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold"
        }
      >
        <CalendarDaysIcon className={" h-6 w-6 shrink-0"} aria-hidden="true" />
        Νέo Ραντεβού
      </span>
    </div>
  );
};
