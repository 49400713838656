import { UploadImage } from "../UploadShopMainImage/UploadShopMainImage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GalleryOfImages } from "../GalleryOfImages/GalleryOfImages";
import { editShop, isShopLoading } from "../../Features/shop/shop-slice";
import { BranchSelector } from "../BranchSelector/BranchSelector";
import { CreateShopFlow } from "../../Pages/CreateShopFlow/CreateShopFlow";
import { ListItemWithEditFunctionality } from "../ListItemWithEditFunctionality/ListItemWithEditFunctionality";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";

const GeneralShopSettings = () => {
  const imagesInGallery = useSelector((state) => state.shop?.gallery);
  const shop = useSelector((state) => state.shop);
  const shopIsLoading = useSelector(isShopLoading);
  const dispatch = useDispatch();
  const [aboutUs, setAboutUs] = useState(null);
  const [isAddShopClicked, setIsAddShopClicked] = useState(null);
  const save = async () => {
    dispatch(
      editShop({
        payload: {
          branchName: shop.name,
          branchType: shop.type,
          branchCountry: shop.country,
          branchRegion: shop.region,
          branchAddress: shop.address,
          branchCity: shop.city,
          branchPostCode: shop.zipCode,
          branchLatitude: shop.latitude,
          branchLongitude: shop.longitude,
          branchPhone: shop.phone,
          aboutUs: aboutUs,
          timeZone: "Europe/Athens",
        },
      }),
    );
  };

  useEffect(() => {
    if (shop?.aboutUs) {
      setAboutUs(shop?.aboutUs);
    }
  }, [shop?.aboutUs]);

  if (!isAddShopClicked) {
    return (
      <div>
        <BranchSelector setIsAddShopClicked={setIsAddShopClicked} />
        <div
          style={{ justifyContent: "space-around" }}
          className={" mt-5 sm:flex w-full  sm:items-center"}
        >
          <div className={"sm:flex w-full  sm:items-start flex-col"}>
            <h2 className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Προφίλ Μαγαζιού
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Βεβαιώσου πως είναι σωστές οι πληροφορίες.
            </p>
          </div>
          <div className={"mt-4 sm:ml-16 sm:mt-0 sm:flex-none"}>
            <ButtonWithLoader
              text={"Αποθήκευση"}
              disabled={shop?.aboutUs === aboutUs}
              customStyling={
                " disabled:bg-gray-400 block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                save();
              }}
              isLoading={shopIsLoading}
            />
          </div>
        </div>
        <UploadImage />
        <GalleryOfImages images={imagesInGallery} isForShop />

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <ListItemWithEditFunctionality
            name={"Όνομα μαγαζιού"}
            notEditable
            data={shop?.name}
          />{" "}
          <ListItemWithEditFunctionality
            name={"Τηλέφωνο μαγαζιού"}
            notEditable
            data={shop?.phone}
          />
          <ListItemWithEditFunctionality
            name={"Τοποθεσία μαγαζιού"}
            notEditable
            data={shop?.address + ", " + shop?.city + ", " + shop?.zipCode}
          />{" "}
          <ListItemWithEditFunctionality
            name={"Id μαγαζιού"}
            notEditable
            data={shop?.id}
          />
          <div className={" pt-6"}>
            <label
              htmlFor="comment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Σχετικά με εμάς
            </label>
            <div className="mt-2">
              <textarea
                rows={4}
                name="comment"
                id="comment"
                onChange={(e) => setAboutUs(e.target.value)}
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={aboutUs ? aboutUs : ""}
              />
            </div>
          </div>
        </dl>
      </div>
    );
  }

  if (isAddShopClicked) {
    return (
      <CreateShopFlow
        isFromSettings={true}
        setIsAddBranchPressed={setIsAddShopClicked}
      />
    );
  }
};

export default GeneralShopSettings;
