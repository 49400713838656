import WorkerSelectorInsideMultipleWorkersCalendar from "../WorkerSelectorInsideMultipleWorkersCalendar/WorkerSelectorInsideMultipleWorkersCalendar";
import Day from "../Day/Day";

export function DayInsideWrapperOfMultipleDays({ item, indexOfDay }) {
  return (
    <div className={"flex w-full  flex-row"}>
      {item.numberOfWorkers?.map((workerIndex) => (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          id={"dayFromMultiple"}
          className={`flex  border-r flex-col ${workerIndex === 0 && indexOfDay === 0 && "border-l"}`}
        >
          <WorkerSelectorInsideMultipleWorkersCalendar
            indexOfWorker={workerIndex}
            indexOfDay={indexOfDay}
          />
          <Day
            indexOfWorkerThatTheDayRepresents={workerIndex}
            index={indexOfDay}
          />
        </div>
      ))}
    </div>
  );
}
