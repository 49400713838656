import { useDispatch, useSelector } from "react-redux";
import HeaderOfViews from "../HeaderOfViews/HeaderOfViews";
import WeekNavigator from "../WeekNavigator/WeekNavigator";
import LeftPartShowingHours from "../LeftPartShowingHours/LeftPartShowingHours";

import React, { useEffect, useRef, useState } from "react";

import WrapperOfMultipleDays from "../WrapperOfMultipleDays/WrapperOfMultipleDays";
import {
  setSecondViewWorker,
  setThirdViewWorker,
} from "../../Features/typeOfView/typeOfView-slice";

const MultipleWorkersCalendars = () => {
  const divRef = useRef(null);

  const workersFromShop = useSelector((state) => state.employees?.data);
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const numberOfMultipleWorkersShown = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );
  const currentTypeOfView = useSelector((state) => state.typeOfView.typeOfView);
  const [arrayToBeMapped, setArrayToBeMapped] = useState(null);

  const arrayConstructor = (arrayLengthToReturn) => {
    let arr = [];
    for (let i = 0; i < arrayLengthToReturn; i++) {
      arr.push(i);
    }
    return arr;
  };
  const [scrolledLeft, setScrolledLeft] = useState(0);

  const handleScroll = () => {
    if (divRef.current) {
      const scrollLeft = divRef.current.scrollLeft;
      setScrolledLeft(scrollLeft);
    }
  };

  useEffect(() => {
    dispatch(setSecondViewWorker(workersFromShop?.[1]));
    dispatch(setThirdViewWorker(workersFromShop?.[2]));
  }, []);

  useEffect(() => {
    if (currentTypeOfView) {
      if (currentTypeOfView === "dayView") {
        setArrayToBeMapped([
          {
            arrayOfDays: arrayConstructor(1),
            numberOfWorkers: arrayConstructor(numberOfMultipleWorkersShown),
          },
        ]);
      }
      if (currentTypeOfView === "twoDayView") {
        setArrayToBeMapped([
          {
            arrayOfDays: arrayConstructor(2),
            numberOfWorkers: arrayConstructor(numberOfMultipleWorkersShown),
          },
        ]);
      }
      if (currentTypeOfView === "threeDayView") {
        setArrayToBeMapped([
          {
            arrayOfDays: arrayConstructor(3),
            numberOfWorkers: arrayConstructor(numberOfMultipleWorkersShown),
          },
        ]);
      }
    }
  }, [numberOfMultipleWorkersShown, currentTypeOfView]);
  return (
    <div style={{ maxWidth: "100%" }} className="flex h-full flex-col">
      <HeaderOfViews />
      <WeekNavigator
        scrolledLeft={scrolledLeft}
        typeOfNavigator={
          currentTypeOfView === "twoDayView"
            ? "2Day"
            : currentTypeOfView === "threeDayView"
              ? "3Day"
              : currentTypeOfView === "dayView"
                ? "1Day"
                : "week"
        }
      />
      <div
        onScroll={handleScroll}
        ref={divRef}
        className="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style={{
            width: "100%",

            maxWidth: "100%",
          }}
          className="flex flex-none flex-col "
        >
          <div className={"flex flex-auto"}>
            <LeftPartShowingHours />
            {arrayToBeMapped?.map((item) => (
              <WrapperOfMultipleDays item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleWorkersCalendars;
