import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getShop } from "../shop/shop-slice";
import Request from "../../Utilities/Request";
import { getBranchEmployees } from "../employees/employees-slice";
import {
  getBranchOwnerInfo,
  getEmployeeInfo,
} from "../personalData/personalData-slice";

const constructFormData = (event, forGallery = false) => {
  const file = event?.target?.files[0];
  const formData = new FormData();
  if (forGallery) {
    formData.append("files", file);
  }
  if (!forGallery) {
    formData.append("file", file);
  }
  return formData;
};

const fetchPersonalData = (thunkAPI, role) => {
  if (role === "employee") {
    thunkAPI.dispatch(getEmployeeInfo());
  }
  if (role === "owner") {
    thunkAPI.dispatch(getBranchOwnerInfo());
  }
};

export const uploadProfileImage = createAsyncThunk(
  "uploadImage/profile",
  async ({ event }, thunkAPI) => {
    let accessToken = localStorage?.getItem("accessToken");
    let role = localStorage.getItem("role");
    let res = await Request.uploadFormData("Media/change-portrait", {
      header: { Authorization: `Bearer ${accessToken}` },
      body: constructFormData(event),
    });

    if (isResponseSuccessful(res)) {
      fetchPersonalData(thunkAPI, role);
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const uploadEmployeePortrait = createAsyncThunk(
  "uploadImage/employeePortrait",
  async ({ event }, thunkAPI) => {
    const { employees, shop } = thunkAPI.getState();
    let accessToken = localStorage?.getItem("accessToken");
    let role = localStorage.getItem("role");
    let res = await Request.uploadFormData(
      `Media/employee/change-portrait/${employees.employeeToBeEdited?.id}`,
      {
        header: { Authorization: `Bearer ${accessToken}` },
        body: constructFormData(event),
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop?.id }));
      if (role === "employee") {
        thunkAPI.dispatch(getEmployeeInfo());
      }
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const uploadShopBannerImage = createAsyncThunk(
  "uploadImage/branchPortrait",
  async ({ event }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    let accessToken = localStorage?.getItem("accessToken");
    let res = await Request.uploadFormData(
      `Media/branch/change-portrait/${shop?.id}`,
      {
        header: { Authorization: `Bearer ${accessToken}` },
        body: constructFormData(event),
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const uploadEmployeeGalleryImage = createAsyncThunk(
  "uploadImage/employeeGallery",
  async ({ event }, thunkAPI) => {
    const { employees, shop } = thunkAPI.getState();
    let accessToken = localStorage?.getItem("accessToken");

    let res = await Request.uploadFormData(
      `Media/employee/upload-to-gallery/${employees?.employeeToBeEdited?.id}`,
      {
        header: { Authorization: `Bearer ${accessToken}` },
        body: constructFormData(event, true),
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchEmployees({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const uploadAShopGalleryImage = createAsyncThunk(
  "uploadImage/shopGallery",
  async ({ event }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    let accessToken = localStorage?.getItem("accessToken");

    let res = await Request.uploadFormData(
      `Media/branch/upload-to-gallery/${shop?.id}`,
      {
        header: { Authorization: `Bearer ${accessToken}` },
        body: constructFormData(event, true),
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
const initialState = {
  isOwnProfilePortraitLoading: false,
  isShopGalleryLoading: false,
  isShopPortraitLoading: false,
  isEmployeeGalleryLoading: false,
  isEmployeePortraitLoading: false,
};

const imagesSlice = createSlice({
  name: "Images",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(uploadProfileImage.pending, (state, action) => {
        state.isOwnProfilePortraitLoading = true;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.isOwnProfilePortraitLoading = false;
        toast.success("Η φωτογραφία ανέβηκε 🥳");
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.isOwnProfilePortraitLoading = false;
        toast.error("Η φωτογραφία δεν ανέβηκε 😔");
      })
      .addCase(uploadEmployeePortrait.pending, (state, action) => {
        state.isEmployeePortraitLoading = true;
      })
      .addCase(uploadEmployeePortrait.fulfilled, (state, action) => {
        state.isEmployeePortraitLoading = false;
        toast.success("Η φωτογραφία ανέβηκε 🥳");
      })
      .addCase(uploadEmployeePortrait.rejected, (state, action) => {
        state.isEmployeePortraitLoading = false;
        toast.error("Η φωτογραφία δεν ανέβηκε 😔");
      })
      .addCase(uploadShopBannerImage.pending, (state, action) => {
        state.isShopPortraitLoading = true;
      })
      .addCase(uploadShopBannerImage.fulfilled, (state, action) => {
        state.isShopPortraitLoading = false;
        toast.success("Η φωτογραφία ανέβηκε 🥳");
      })
      .addCase(uploadShopBannerImage.rejected, (state, action) => {
        state.isShopPortraitLoading = false;
        toast.error("Η φωτογραφία δεν ανέβηκε 😔");
      })
      .addCase(uploadEmployeeGalleryImage.pending, (state, action) => {
        state.isEmployeeGalleryLoading = true;
      })
      .addCase(uploadEmployeeGalleryImage.fulfilled, (state, action) => {
        state.isEmployeeGalleryLoading = false;
        toast.success("Η φωτογραφία ανέβηκε 🥳");
      })
      .addCase(uploadEmployeeGalleryImage.rejected, (state, action) => {
        state.isEmployeeGalleryLoading = false;
        toast.error("Η φωτογραφία δεν ανέβηκε 😔");
      })
      .addCase(uploadAShopGalleryImage.pending, (state, action) => {
        state.isShopGalleryLoading = true;
      })
      .addCase(uploadAShopGalleryImage.fulfilled, (state, action) => {
        state.isShopGalleryLoading = false;
        toast.success("Η φωτογραφία ανέβηκε 🥳");
      })
      .addCase(uploadAShopGalleryImage.rejected, (state, action) => {
        state.isShopGalleryLoading = false;
        toast.error("Η φωτογραφία δεν ανέβηκε 😔");
      }),
});

export const {} = imagesSlice.actions;
export const isOwnProfilePortraitLoading = (state) =>
  state?.imagesUpload?.isOwnProfilePortraitLoading;
export const isEmployeeGalleryLoading = (state) =>
  state?.imagesUpload?.isEmployeeGalleryLoading;
export const isEmployeePortraitLoading = (state) =>
  state?.imagesUpload?.isEmployeePortraitLoading;
export const isShopPortraitLoading = (state) =>
  state?.imagesUpload?.isShopPortraitLoading;
export const isShopGalleryLoading = (state) =>
  state?.imagesUpload?.isShopGalleryLoading;

export default imagesSlice.reducer;
