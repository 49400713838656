import { toast } from "react-toastify";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";

export const ListItemWithEditFunctionality = ({
  notEditable,
  name,
  data,
  setValue,
  canBeCopied,
}) => {
  return (
    <div className="pt-6 sm:flex ">
      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
        {name}
      </dt>
      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto relative">
        {name === "Τηλέφωνο" || name === "Τηλέφωνο μαγαζιού" ? (
          <PhoneNumberInput
            phone={data}
            setPhone={setValue}
            disabled={name === "Τηλέφωνο μαγαζιού"}
          />
        ) : (
          <input
            placeholder={data}
            disabled={notEditable}
            value={data ? data : "-"}
            className={
              "text-gray-900 outline-none bg-gray-50 border-0 w-full rounded ring-black-1 p-2 disabled:text-gray-400 "
            }
            onChange={(e) => {
              if (e.target.value?.includes("-")) {
                setValue(e.target.value?.replace("-", ""));
              }
              if (!e.target.value?.includes("-")) {
                setValue(e.target.value);
              }
            }}
          />
        )}

        {canBeCopied && (
          <ClipboardDocumentCheckIcon
            color={"white"}
            className={
              "h-6  w-6  cursor-pointer bg-primary  right-2 absolute top-1/2 rounded transform -translate-y-1/2 "
            }
            onClick={() => {
              if (canBeCopied) {
                try {
                  navigator.clipboard.writeText(data);
                  toast("Copied");
                } catch (e) {
                  toast(e);
                }
              }
            }}
          />
        )}
      </dd>
    </div>
  );
};
