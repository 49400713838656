import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setFirstViewWorker,
  setSecondViewWorker,
  setThirdViewWorker,
} from "../Features/typeOfView/typeOfView-slice";

export const ChangeViewWorkersIfTheyHaveBeenUpdated = () => {
  const workerSelectedOnFirstView = useSelector(
    (state) => state.typeOfView?.firstViewWorker,
  );
  const workerSelectedOnSecondView = useSelector(
    (state) => state.typeOfView?.secondViewWorker,
  );
  const workerSelectedOnThirdView = useSelector(
    (state) => state.typeOfView?.thirdViewWorker,
  );
  const employees = useSelector((state) => state.employees?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employees) {
      employees?.map((employee) => {
        if (employee?.id === workerSelectedOnFirstView?.id) {
          dispatch(setFirstViewWorker(employee));
        }
        if (employee?.id === workerSelectedOnSecondView?.id) {
          dispatch(setSecondViewWorker(employee));
        }
        if (employee?.id === workerSelectedOnThirdView?.id) {
          dispatch(setThirdViewWorker(employee));
        }
      });
    }
  }, [employees]);
};
