// import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

import {
  enableConsoleLogging,
  consoleLoggingLevel,
  consoleLoggingDetail,
} from "./constants";

const isWebApp = false;

const styles = {
  base: [
    "color: #black",
    // "background-color: #444",
    // "padding: 2px 4px",
    // "border-radius: 2px",
  ],
  request: [
    "font-weight: bold",
    "font-size: 16px",
    "color: #4248f5",
    // "background-color: #181813"
  ],
  response: [
    "font-weight: bold",
    "font-size: 16px",
    "color: #42dcf5",
    // "background-color: #181813"
  ],
  warning: [
    "color: #eef20f",
    // "background-color: #181813"
  ],
  error: [
    "color: #d41209",
    // "background-color: #eee",
    "font-weight: bold",
    "font-size: 16px",
  ],
  success: ["color: #0bdb18"],
};

/**
 * Work in progress.
 * Nothing is final.
 * Some features are not being used at all.
 **/
class Logger {
  constructor(params = defaults) {
    this.channel = "channel" in params ? params.channel : defaults.channel;
    this.logUuid = "logUuid" in params ? params.logUuid : defaults.logUuid;
    this.printTimestamp =
      "printTimestamp" in params
        ? params.printTimestamp
        : defaults.printTimestamp;
    this.uuid = uuidv4();
    this.started = null;
    this.ended = null;
  }

  /** Example of custom diagnostics channel **/
  // static async diag(message, title = '', location = '') {
  //   const log = new Logger({printTimestamp: false});
  //   try {
  //     const uuid = uuidv4();
  //     const ts = tsTag();
  //     const actualMessage = stringify(message);
  //     const logParam =
  //       log.prefix(uuid) +
  //       ts +
  //       (location ? location + '\n' : '') +
  //       (title ? title + '\n' : '') +
  //       actualMessage +
  //       log.suffix(uuid);
  //     if (logDiagnosticsAlsoLogToConsole) {
  //       log.warning(logParam);
  //     }
  //     const logResult = await ReactNativeSoftPosModule.addCustomDiagnostics(
  //       logParam,
  //     );
  //     if (logDiagnosticsAlsoLogToConsole) {
  //       log.response('sdk logResult: ' + logResult);
  //     }
  //     return logResult === 'Success!';
  //   } catch (e) {
  //     log.error('Logger.diag() Exception: ' + e.message);
  //     return false;
  //   }
  // }

  static log(message, title) {
    if (enableConsoleLogging) {
      message = stringify(message);
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        // style += styles.response.join(';');
        console.info(`%c${title ? title + "\n" : ""}${message}`, style);
      } else {
        console.info(
          colors.KWHT + (title ? title + "\n" : "") + message + colors.KNRM,
        );
      }
    }
  }

  info(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        // style += styles.response.join(';');
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KWHT +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
    }
  }

  response(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      this.end();
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.response.join(";");
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KCYN +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
      this.printElapsed();
    }
  }

  request(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      this.start();
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.request.join(";");
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KBLU +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
    }
  }

  success(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      this.end();
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.success.join(";");
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KGRN +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
      this.printElapsed();
    }
  }

  warning(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.warning.join(";");
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KYEL +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
    }
  }

  error(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      this.end();
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.error.join(";");
        console.info(
          `%c${this.ts()}${title ? title + "\n" : ""}${message}`,
          style,
        );
      } else {
        console.info(
          colors.KRED +
            this.ts() +
            (title ? title + "\n" : "") +
            message +
            colors.KNRM,
        );
      }
      this.printElapsed();
    }
  }

  mag(message, title = null) {
    if (enableConsoleLogging) {
      message = stringify(message);
      this.end();
      console.info(
        colors.KMAG +
          this.ts() +
          (title ? title + "\n" : "") +
          message +
          colors.KNRM,
      );
      this.printElapsed();
    }
  }

  printElapsed = () => {
    if (this.started && this.ended) {
      if (isWebApp) {
        let style = styles.base.join(";") + ";";
        style += styles.warning.join(";");
        console.info(`%cCompleted in: ${this.elapsed()} seconds`, style);
      } else {
        console.info(
          colors.KYEL +
            "Completed in: " +
            this.elapsed() +
            " seconds" +
            colors.KNRM,
        );
      }
    }
  };

  elapsed = () => {
    if (this.started && this.ended) {
      return ((this.ended.getTime() - this.started.getTime()) / 1000).toFixed(
        3,
      );
    }
  };
  /**
   * @param {boolean} force Flag to force set
   */
  start = (force = false) => {
    if (!this.started || force) {
      this.started = now();
    }
  };
  /**
   * @param {boolean} force Flag to force set
   */
  end = (force = false) => {
    if (!this.ended || force) {
      this.ended = now();
    }
  };
  ts = () => {
    if (this.printTimestamp) {
      return (
        tsTag() +
        (this.logUuid
          ? " - Log ID {" + this.uuid + "}: \n"
          : isWebApp
            ? "\n"
            : " ")
      );
    }
    return "";
  };

  prefix = (uuid) => {
    return "START Log ID " + uuid + "\n";
  };
  suffix = (uuid) => {
    return "\nEND Log ID " + uuid;
  };
}

const stringify = (val) => {
  let result = val;
  if (typeof val === "object") {
    result = JSON.stringify(val);
  }
  result = String(result);
  return result;
};
const tsTag = () => "[" + timestamp() + "] ";
const timestamp = () => now().toISOString();
const now = () => new Date();
const colors = {
  KNRM: "\x1b[0m",
  KRED: "\x1B[31m",
  KGRN: "\x1B[32m",
  KYEL: "\x1B[33m",
  KBLU: "\x1B[34m",
  KMAG: "\x1B[35m",
  KCYN: "\x1B[36m",
  KWHT: "\x1B[37m",
};

const defaults = {
  logUuid: false,
  channel: "console",
  printTimestamp: true,
};
export default Logger;
