import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export default function SlideOverWithCloseButtonOnOutside({
  children,
  open,
  setOpen,
  title,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30  " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div style={{ zIndex: 2 }} className="fixed inset-0 " />
        </Transition.Child>

        <div style={{ zIndex: 2 }} className="fixed  inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <div
                    className={`flex lg:pt-5 h-full flex-col overflow-y-scroll bg-gray-50 py-6 border-l-[1px] shadow-xl `}
                  >
                    <div className="px-4 pb-2 inline-flex gap-2 border-gray-200 border-b-[1px] items-center">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-900 hover:bg-gray-200 focus:outline-none "
                        onClick={() => setOpen(false)}
                      >
                        <ChevronLeftIcon className="h-7 w-7 stroke-2 rounded-md text-gray-700" />
                      </button>
                      <Dialog.Title className="text-sm w-full flex justify-center font-semibold leading-6 text-center text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                    <div className="relative bg-white flex-1">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
