import { useDispatch, useSelector } from "react-redux";
import { styles } from "./styles";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { setFirstViewWorker } from "../../Features/typeOfView/typeOfView-slice";
import { GetAppointmentsOfDate } from "../../Hooks/GetAppointmentsOfDate";

import { DayConstructor } from "../../Hooks/DayConstructor";
import Appointment from "../Appointment/Appointment";
import RedLineThatShowsTheTime from "../RedLineThatShowsTheTime/RedLineThatShowsTheTime";
import HourSlotInCalendar from "../HourSlotInCalendar/HourSlotInCalendar";
import {
  setIndexOfDayThatAppointmentWsDraggedOn,
  setIndexOfDayThatWasPressed,
  setIndexOfWorkerThatAppointmentIsDraggedOn,
  setIsAppointmentBeingDragged,
  setIsAppointmentSlideOverOpen,
  setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition,
  setPixelThatCursorIsOnDuringDrag,
  setSelectedTime,
  setSelectedWorker,
} from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import {
  handleDragOver,
  roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute,
} from "../../Utilities/utilities";
import { TimeThatAppointmentIsBeingDraggedAt } from "../TimeThatAppointmentIsBeingDroppedAt/TimeThatAppointmentIsBeingDraggedAt";

const Day = ({ index, indexOfWorkerThatTheDayRepresents }) => {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const indexOfDayThatActiveTimeWasPressed = useSelector(
    (state) => state.appointment.indexOfDayThatWasPressed,
  );
  const [
    arrayToMapBasedOnNumberOfWorkersForCalendarToShow,
    setArrayToMapBasedOnNumberOfWorkersForCalendarToShow,
  ] = useState([0]);
  const typeOfView = useSelector((state) => state.typeOfView.typeOfView);
  const workersFromShop = useSelector((state) => state?.employees?.data);
  const dispatch = useDispatch();
  const isAppointmentDragged = useSelector(
    (state) => state.appointment.isAppointmentBeingDragged,
  );
  const appointmentsOfSpecificDate = GetAppointmentsOfDate(
    index,
    indexOfWorkerThatTheDayRepresents,
  );
  const [workerBasedOnTheIndexOfTheDay, setWorkerBasedOnTheIndexOfTheDay] =
    useState(null);
  const selectedTimeForAppointment = useSelector(
    (state) => state.appointment.selectedTime,
  );
  const [activeTime, setActiveTime] = useState(null);
  const wrapperOfActiveFifteenMinute = useRef(null);
  const dayToRender = DayConstructor(
    selectedDate,
    index,
    indexOfWorkerThatTheDayRepresents,
  );
  const firstViewWorker = useSelector(
    (state) => state.typeOfView.firstViewWorker,
  );
  const secondViewWorker = useSelector(
    (state) => state.typeOfView.secondViewWorker,
  );
  const thirdViewWorker = useSelector(
    (state) => state.typeOfView.thirdViewWorker,
  );
  const isAppointmentSlideOverOpen = useSelector(
    (state) => state.appointment.isAppointmentSlideOverOpen,
  );
  const selectedWorkerForAppointment = useSelector(
    (state) => state.appointment.selectedWorker,
  );
  const [isAppointmentBeingResized, setIsAppointmentBeingResized] =
    useState(false);

  const hoverOutside = (event) => {
    if (
      wrapperOfActiveFifteenMinute.current &&
      !wrapperOfActiveFifteenMinute.current.contains(event.target)
    ) {
      setActiveTime(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseover", hoverOutside, false);
    return () => {
      document.removeEventListener("mouseover", hoverOutside, false);
    };
  }, []);

  useEffect(() => {
    if (
      !indexOfWorkerThatTheDayRepresents ||
      indexOfWorkerThatTheDayRepresents === 0
    ) {
      setWorkerBasedOnTheIndexOfTheDay(firstViewWorker);
    }
    if (indexOfWorkerThatTheDayRepresents === 1) {
      setWorkerBasedOnTheIndexOfTheDay(secondViewWorker);
    }
    if (indexOfWorkerThatTheDayRepresents === 2) {
      setWorkerBasedOnTheIndexOfTheDay(thirdViewWorker);
    }
  }, [indexOfWorkerThatTheDayRepresents, firstViewWorker]);

  useEffect(() => {
    changeHowManyWorkersWillBeShownInOneDay();
  }, [typeOfView]);
  useEffect(() => {
    if (
      arrayToMapBasedOnNumberOfWorkersForCalendarToShow?.length === 1 &&
      !firstViewWorker
    ) {
      dispatch(setFirstViewWorker(workersFromShop?.[0]));
    }
  }, []);
  const changeHowManyWorkersWillBeShownInOneDay = () => {
    let array = [];
    for (let i = 0; i < typeOfView; i++) {
      array.push(i);
    }
    setArrayToMapBasedOnNumberOfWorkersForCalendarToShow(array);
  };

  const skipClickAgainWhenOnPhone = (activeTime) => {
    dispatch(setIsAppointmentSlideOverOpen(true));
    dispatch(setIndexOfDayThatWasPressed(index));
    dispatch(setSelectedWorker(workerBasedOnTheIndexOfTheDay));
    dispatch(
      setSelectedTime(
        dayjs(
          new Date(
            dayjs(selectedDate).add(index, "d").format("YYYY"),
            dayjs(selectedDate).add(index, "d").format("MM") - 1,
            dayjs(selectedDate).add(index, "d").format("DD"),
            activeTime?.value,
            activeTime?.cord.y < 30
              ? 0
              : activeTime?.cord.y === 30
                ? "15"
                : activeTime?.cord.y === 60
                  ? "30"
                  : activeTime?.cord.y === 90 && "45",
          ),
        ),
      ),
    );
  };

  const getRelativeCoordsToShowWhichTimeSlotIsHovered = (
    event,
    isFromPhone,
  ) => {
    let bounds = event.target.getBoundingClientRect();
    let y = event.clientY - bounds.top;
    let yNonFixed = event.clientY - bounds.top;
    let x = event.clientX;

    if (y < 60) {
      y = 0;
    }

    if (y > 60) {
      y = 60;
    }

    yNonFixed =
      roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(yNonFixed);
    if (isAppointmentDragged) {
      dispatch(
        setPixelThatCursorIsOnDuringDrag(
          event.target.classList.value * 120 + yNonFixed,
        ),
      );
    }

    setActiveTime({
      cord: { y: y, yNonFixed: yNonFixed },
      value: event.target.classList.value,
    });

    if (isFromPhone) {
      skipClickAgainWhenOnPhone({
        cord: { y: y, yNonFixed: yNonFixed },
        value: event.target.classList.value,
      });
    }
  };
  const onDropAppointmentAfterDrag = async (event) => {
    let bounds = event.target.getBoundingClientRect();
    let y = event.clientY - bounds.top;
    dispatch(setIsAppointmentBeingDragged(false));
    dispatch(setIndexOfDayThatAppointmentWsDraggedOn(null));
    dispatch(setIndexOfWorkerThatAppointmentIsDraggedOn(null));
    let idOfAppointment = event.dataTransfer.getData("id");

    y = roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(y);

    if (!isNaN(y / 2 + event.target.classList.value * 120)) {
      dispatch(
        setObjectThatContainsTheIdOfTheAppointmentThatWillBeEditedTheDayThatWasDroppedAtAndTheNewTopPosition(
          {
            id: idOfAppointment,
            dayThatTheAppointmentWasDroppedAt: dayjs(selectedDate).add(
              index,
              "d",
            ).$d,
            newTopForAppointment: y + event.target.classList.value * 120,
            workerToHandle: workerBasedOnTheIndexOfTheDay,
          },
        ),
      );
    }
    setActiveTime(null);
  };

  return (
    <div
      style={styles.wrapper}
      onDrop={onDropAppointmentAfterDrag}
      onDragOver={handleDragOver}
    >
      {Object.keys(dayToRender)?.map((time, i) => (
        <HourSlotInCalendar
          key={i}
          indexOfWorker={indexOfWorkerThatTheDayRepresents}
          indexOfDay={index}
          selectedTimeForAppointment={selectedTimeForAppointment}
          getRelativeCoordsToShowWhichTimeSlotIsHovered={
            getRelativeCoordsToShowWhichTimeSlotIsHovered
          }
          dayObjectThatTheSlotIsPartOf={dayToRender}
          time={time}
        />
      ))}

      {!isNaN(activeTime?.value * 120 + activeTime?.cord.y) &&
        (activeTime ||
          ((selectedTimeForAppointment && index) ===
            indexOfDayThatActiveTimeWasPressed &&
            selectedWorkerForAppointment?.id ===
              workerBasedOnTheIndexOfTheDay?.id)) &&
        !isAppointmentBeingResized &&
        !isAppointmentDragged && (
          <div
            ref={wrapperOfActiveFifteenMinute}
            onClick={() => {
              if (!isAppointmentSlideOverOpen) {
                skipClickAgainWhenOnPhone(activeTime);
              }
            }}
            style={
              !selectedTimeForAppointment
                ? {
                    ...styles.activeFifteenMinute,
                    top: activeTime?.value * 120 + activeTime?.cord.y,
                  }
                : {
                    ...styles.activeFifteenMinute,
                    top:
                      dayjs(selectedTimeForAppointment).format("HH") * 120 +
                      (dayjs(selectedTimeForAppointment).format("mm") < 15
                        ? 0
                        : dayjs(selectedTimeForAppointment).format("mm") >=
                              15 &&
                            dayjs(selectedTimeForAppointment).format("mm") < 30
                          ? 30
                          : dayjs(selectedTimeForAppointment).format("mm") >=
                                30 &&
                              dayjs(selectedTimeForAppointment).format("mm") <
                                45
                            ? 60
                            : dayjs(selectedTimeForAppointment).format("mm") >=
                                45 && 90),
                  }
            }
          >
            <p
              className={"text-xs leading-5 text-white-400"}
              style={{
                paddingLeft: 10,
              }}
            >
              {activeTime?.value ||
                dayjs(selectedTimeForAppointment)?.format("H")}
              :
              {activeTime?.cord.y < 30 ||
              dayjs(selectedTimeForAppointment)?.format("mm") < 15
                ? "00"
                : activeTime?.cord.y === 30 ||
                    dayjs(selectedTimeForAppointment)?.format("mm") < 30
                  ? "15"
                  : activeTime?.cord.y === 60 ||
                      dayjs(selectedTimeForAppointment)?.format("mm") < 45
                    ? "30"
                    : (activeTime?.cord.y === 90 ||
                        dayjs(selectedTimeForAppointment)?.format("mm") >=
                          45) &&
                      "45"}
            </p>
          </div>
        )}
      <RedLineThatShowsTheTime
        index={index}
        indexOfWorkerThatTheDayRepresents={indexOfWorkerThatTheDayRepresents}
      />

      {appointmentsOfSpecificDate?.map((appointment, i) => (
        <Appointment
          key={appointment.id + appointment?.bookedDate}
          setIsAppointmentBeingResized={setIsAppointmentBeingResized}
          positionOfCursor={activeTime}
          appointment={appointment}
        />
      ))}
      <TimeThatAppointmentIsBeingDraggedAt
        indexOfWorkerThatTheDayRepresents={indexOfWorkerThatTheDayRepresents}
        indexOfDay={index}
      />
    </div>
  );
};

export default Day;
