import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect } from "react";
import { classNames } from "../../Utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstViewWorker,
  setNumberOfMultipleWorkersShown,
  setSelectedWorkerForWeekView,
} from "../../Features/typeOfView/typeOfView-slice";
import AvatarComponent from "../AvatarComponent/AvatarComponent";

export default function WorkerSelector() {
  const workersFromShop = useSelector((state) => state.employees?.data);
  const dispatch = useDispatch();
  const numberOfMultipleWorkersShown = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );
  const selectedWorkerForWeekView = useSelector(
    (state) => state.typeOfView?.selectedWorkerForWeekView,
  );
  const workerSelectedOnFirstView = useSelector(
    (state) => state.typeOfView?.firstViewWorker,
  );
  const workerSelectedOnSecondView = useSelector(
    (state) => state.typeOfView?.secondViewWorker,
  );
  const workerSelectedOnThirdView = useSelector(
    (state) => state.typeOfView?.thirdViewWorker,
  );
  useEffect(() => {
    if (
      workersFromShop &&
      !selectedWorkerForWeekView &&
      !workerSelectedOnFirstView
    ) {
      dispatch(setSelectedWorkerForWeekView(workersFromShop?.[0]));
      dispatch(setFirstViewWorker(workersFromShop?.[0]));
    }
  }, [workersFromShop]);

  return (
    <Menu as="div" className=" w-full md:w-auto sm:relative">
      <Menu.Button
        type="button"
        className="w-full h-[40px] justify-between flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {!numberOfMultipleWorkersShown && (
          <AvatarComponent
            ringColor={"border-gray-500"}
            iconColor={"text-gray-700"}
            image={workerSelectedOnFirstView?.profilePicUrl}
          />
        )}
        {numberOfMultipleWorkersShown === 2 && (
          <>
            <AvatarComponent
              ringColor={"border-gray-500"}
              iconColor={"text-gray-700"}
              image={workerSelectedOnFirstView?.profilePicUrl}
            />
            <AvatarComponent
              ringColor={"border-gray-500"}
              iconColor={"text-gray-700"}
              className={"ml-[-20px]"}
              image={workerSelectedOnSecondView?.profilePicUrl}
            />
          </>
        )}
        {numberOfMultipleWorkersShown === 3 && (
          <>
            <AvatarComponent
              ringColor={"border-gray-500"}
              iconColor={"text-gray-700"}
              image={workerSelectedOnFirstView?.profilePicUrl}
            />
            <AvatarComponent
              ringColor={"border-gray-500"}
              iconColor={"text-gray-700"}
              className={"ml-[-20px]"}
              image={workerSelectedOnSecondView?.profilePicUrl}
            />
            <AvatarComponent
              ringColor={"border-gray-500"}
              iconColor={"text-gray-700"}
              className={"ml-[-20px]"}
              image={workerSelectedOnThirdView?.profilePicUrl}
            />
          </>
        )}
        <div className={"hidden sm:block whitespace-nowrap overflow-hidden"}>
          {!numberOfMultipleWorkersShown
            ? workerSelectedOnFirstView?.firstName +
              " " +
              workerSelectedOnFirstView?.lastName?.[0] +
              "."
            : numberOfMultipleWorkersShown == 2
              ? "Δείξε 2 υπαλλήλους"
              : numberOfMultipleWorkersShown == 3 && "Δείξε 3 υπαλλήλους"}
        </div>

        <ChevronDownIcon className="-mr-1 h-4 w-4" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className=" w-auto left-0 sm:w-full sm:left-0 md:w-full z-10  absolute rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {workersFromShop?.map((worker, i) => (
              <Menu.Item as="div" key={i}>
                {({ active }) => (
                  <div
                    onClick={() => {
                      dispatch(setSelectedWorkerForWeekView(worker));
                      dispatch(setFirstViewWorker(worker));
                      dispatch(setNumberOfMultipleWorkersShown(null));
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex flex-row items-center px-2 gap-2 cursor-pointer",
                    )}
                  >
                    <AvatarComponent
                      ringColor={"border-gray-500"}
                      iconColor={"text-gray-700"}
                      image={worker?.profilePicUrl}
                    />
                    <span
                      key={i}
                      className={
                        "block py-2 text-sm cursor-pointer text-ellipsis overflow-hidden"
                      }
                    >
                      {worker?.firstName} {worker?.lastName.slice(0, 1)}.
                    </span>
                  </div>
                )}
              </Menu.Item>
            ))}
            {workersFromShop?.length >= 2 && (
              <Menu.Item as="div">
                {({ active }) => (
                  <span
                    key={"double"}
                    onClick={() => {
                      dispatch(setNumberOfMultipleWorkersShown(2));
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-2 py-2 text-sm cursor-pointer",
                    )}
                  >
                    Δείξε 2 υπαλλήλους
                  </span>
                )}
              </Menu.Item>
            )}
            {workersFromShop?.length >= 3 && (
              <Menu.Item as="div">
                {({ active }) => (
                  <span
                    key={"triple"}
                    onClick={() => {
                      dispatch(setNumberOfMultipleWorkersShown(3));
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-2 py-2 text-sm cursor-pointer",
                    )}
                  >
                    Δείξε 3 υπαλλήλους
                  </span>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
