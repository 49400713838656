import { Route, Routes } from "react-router-dom";
import LoginSignUpResetPass from "../../Pages/LoginSignUpResetPass/LoginSignUpResetPass";
import { EmployeeAcceptInvitationPage } from "../../Pages/EmployeeAcceptInvitationPage/EmployeeAcceptInvitationPage";
import { SignUpForm } from "../SignUpForm/SignUpForm";

export const LoggedOutRoutes = () => {
  return (
    <Routes>
      <Route path={"*"} element={<LoginSignUpResetPass />} />
      <Route
        path={"/register-employee/:invitationCode"}
        element={<EmployeeAcceptInvitationPage />}
      />
      <Route
        path={"/create-employee/:invitationCode"}
        element={<SignUpForm isSigningUpEmployee={true} />}
      />
    </Routes>
  );
};
