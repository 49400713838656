import { useEffect, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../Utilities/utilities";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import UploadProfileImage from "../../Components/UploadProfileImage/UploadProfileImage";
import { ListItemWithEditFunctionality } from "../../Components/ListItemWithEditFunctionality/ListItemWithEditFunctionality";
import { updateEmployee } from "../../Features/employees/employees-slice";
import { ButtonWithLoader } from "../../Components/ButtonWithLoader/ButtonWithLoader";
import { toast } from "react-toastify";
import { savePersonalData } from "../../Features/personalData/personalData-slice";

const secondaryNavigation = [
  {
    name: "Γενικές Πληροφορίες",
    icon: UserCircleIcon,
    current: true,
    eng: "General Info",
  },
];
export default function ProfilePage({ isFromEditEmployee }) {
  const dispatch = useDispatch();
  const personalInfo = useSelector((state) => state.personalData);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const firebaseToken = localStorage.getItem("firebaseToken");
  const role = localStorage?.getItem("role");

  const employeeToBeEdited = useSelector(
    (state) => state.employees.employeeToBeEdited,
  );
  const isPersonalInfoLoading = useSelector(
    (state) => state.personalData.isLoading,
  );
  const isEmployeeInfoLoading = useSelector(
    (state) => state.employees.isLoading,
  );

  useEffect(() => {
    if (!isFromEditEmployee) {
      setFirstName(personalInfo.firstName);
      setLastName(personalInfo?.lastName);
      setPhone(personalInfo?.phone);
      setEmail(personalInfo?.email);
    }
  }, [personalInfo]);

  useEffect(() => {
    if (isFromEditEmployee) {
      setFirstName(employeeToBeEdited?.firstName);
      setLastName(employeeToBeEdited?.lastName);
      setPhone(employeeToBeEdited?.phone);
      setEmail(employeeToBeEdited?.email);
      setDescription(employeeToBeEdited?.description);
      setOccupation(employeeToBeEdited?.occupation);
    }
  }, [employeeToBeEdited]);

  const updateEmployeeInfo = async () => {
    try {
      if (!isFromEditEmployee && role !== "employee") {
        await dispatch(
          savePersonalData({
            payload: {
              firstName: firstName,
              lastName: lastName,
              profilePicUrl: personalInfo?.profilePicUrl,
              email: email,
              phone: phone,
            },
          }),
        );
      }
      if (isFromEditEmployee || role === "employee") {
        await dispatch(
          updateEmployee({
            payload: {
              firstName: firstName,
              lastName: lastName,
              profilePicUrl: employeeToBeEdited?.profilePicUrl,
              phone: phone,
              occupation: occupation,
              description: description,
            },
          }),
        );
      }
    } catch (e) {
      toast.error(e?.message || e);
    }
  };

  return (
    <div className={" pb-20 sm:pb-5"}>
      <div className="mx-auto max-w-7xl  lg:flex lg:gap-x-16 lg:px-8">
        {!isFromEditEmployee && (
          <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul
                role="list"
                className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
              >
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-indigo-600"
                            : "text-gray-400 group-hover:text-indigo-600",
                          "h-6 w-6 shrink-0",
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </aside>
        )}

        <main className={"px-4  sm:px-6 lg:flex-auto lg:px-0 "}>
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <UploadProfileImage isFromEditEmployee={isFromEditEmployee} />

              <div className={"lg:flex justify-between"}>
                <div>
                  <h2 className="text-base font-semibold leading-7 pt-5 text-gray-900">
                    Προφίλ
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    Οι πληροφορίες εδώ φαίνονται δημόσια. Βεβαιώσου πως είναι
                    σωστές.
                  </p>
                </div>
                <div className={"mt-4 mb-5   sm:flex-none"}>
                  <ButtonWithLoader
                    customStyling={
                      "block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                    isLoading={isPersonalInfoLoading || isEmployeeInfoLoading}
                    onClick={updateEmployeeInfo}
                    text={"Αποθήκευση"}
                  />
                </div>
              </div>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <ListItemWithEditFunctionality
                  name={"Όνομα"}
                  data={firstName}
                  setValue={setFirstName}
                />

                <ListItemWithEditFunctionality
                  name={"Επίθετο"}
                  data={lastName}
                  setValue={setLastName}
                />

                <ListItemWithEditFunctionality
                  name={"Τηλέφωνο"}
                  data={phone}
                  setValue={setPhone}
                />

                <ListItemWithEditFunctionality
                  name={"Email"}
                  notEditable
                  data={email}
                  setValue={setEmail}
                />

                {(isFromEditEmployee || role === "employee") && (
                  <>
                    <ListItemWithEditFunctionality
                      name={"Ρόλος"}
                      data={occupation}
                      setValue={setOccupation}
                    />
                    <ListItemWithEditFunctionality
                      name={"Περιγραφή"}
                      data={description}
                      setValue={setDescription}
                    />
                  </>
                )}
                {!isFromEditEmployee && (
                  <ListItemWithEditFunctionality
                    name={"User Token"}
                    notEditable
                    canBeCopied
                    data={firebaseToken}
                  />
                )}
              </dl>
            </div>
            {!isFromEditEmployee && (
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Γλώσσες και ημερομηνίες
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Επιλέξτε ποια γλώσσα και μορφή ημερομηνίας θα χρησιμοποιείται
                  σε ολόκληρο τον λογαριασμό σας.
                </p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      Γλώσσα
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">Ελληνικά</div>
                      <Tooltip title={"Θα προστεθεί σύντομα"}>
                        <span className="font-semibold text-gray-900 ">
                          Αλλαγή
                        </span>
                      </Tooltip>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      Στυλ Ημερομηνίας
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">DD-MM-YYYY</div>
                      <Tooltip title={"Θα προστεθεί σύντομα"}>
                        <span className="font-semibold text-gray-900 ">
                          Αλλαγή
                        </span>
                      </Tooltip>
                    </dd>
                  </div>
                </dl>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
