import ProfilePage from "../ProfilePage/ProfilePage";
import { useDispatch, useSelector } from "react-redux";
import { GalleryOfImages } from "../../Components/GalleryOfImages/GalleryOfImages";
import { useEffect, useState } from "react";
import {
  employees,
  employeeToBeEdited,
  setEmployeeToBeEdited,
} from "../../Features/employees/employees-slice";
import { EmployeesShiftsTable } from "../../Components/EmployeesShiftsTable/EmployeesShiftsTable";
import { classNames } from "../../Utilities/utilities";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { TimeIcon } from "@mui/x-date-pickers";
import { useLocation, useParams } from "react-router-dom";

const secondaryNavigation = [
  {
    name: "Γενικές Πληροφορίες",
    icon: UserCircleIcon,
    eng: "General Info",
  },
  {
    name: "Βάρδιες",
    icon: TimeIcon,
    eng: "Shifts",
  },
];

export const EmployeeProfilePage = () => {
  const { state } = useLocation();
  const employeeToEdit = useSelector(employeeToBeEdited);
  const employeesOfShop = useSelector(employees);
  const dispatch = useDispatch();
  const [activeNavigation, setActiveNavigation] = useState(
    state ? "Shifts" : "General Info",
  );
  const { idOfEmployee } = useParams();

  useEffect(() => {
    if (employeeToEdit?.id !== idOfEmployee) {
      dispatch(
        setEmployeeToBeEdited(
          employeesOfShop?.filter((worker) => worker?.id === idOfEmployee)?.[0],
        ),
      );
    }
  }, [employeesOfShop, idOfEmployee]);
  return (
    <div
      className={
        " lg:flex-row  justify-center items-start lg:py-20 mx-auto max-w-7xl  lg:flex"
      }
    >
      <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:mr-3 lg:block lg:w-64 lg:flex-none lg:border-0 ">
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul
            role="list"
            className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
          >
            {secondaryNavigation.map((item) => (
              <li
                key={item.eng}
                onClick={() => {
                  setActiveNavigation(item.eng);
                }}
              >
                <a
                  href={item.href}
                  className={classNames(
                    item.eng === activeNavigation
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.eng === activeNavigation
                        ? "text-indigo-600"
                        : "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0",
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <main className="px-4  sm:px-6 lg:flex-auto lg:px-0">
        <div className="mx-auto max-w-2xl  lg:mx-0 lg:max-w-none">
          {activeNavigation === "General Info" && (
            <>
              <ProfilePage isFromEditEmployee />
              <GalleryOfImages isForEmployee images={employeeToEdit?.gallery} />
            </>
          )}
          {employeeToEdit && activeNavigation === "Shifts" && (
            <div>
              <EmployeesShiftsTable employeesProps={[{ ...employeeToEdit }]} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};
