import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/16/solid";

const NavSteps = ({ goBack, current }) => {
  const steps = [
    { id: 1, name: "Στοιχεία" },
    { id: 2, name: "Τοποθεσία" },
    { id: 3, name: "Υπηρεσίες" },
  ];

  return (
    <div
      className={`bg-white sticky top-0 z-[49] w-full shadow p-4 md:py-1 flex`}
    >
      <nav
        className={`px-1 flex flex-row w-full sticky top-0  max-w-[1536px] m-auto items-center`}
      >
        <a className={"cursor-pointer absolute"} onClick={goBack}>
          <ChevronLeftIcon className="w-8 h-8 font-bold" />
        </a>
        <h2 className={"text-center w-full font-bold block md:hidden"}>
          {steps.map((item) => {
            if (item?.id === current) {
              return item?.name;
            }
          })}
        </h2>
        <div className={"w-full justify-center md:flex hidden"}>
          <nav aria-label="Progress">
            <ol role="list" className="divide-y md:flex md:divide-y-0">
              {steps?.map((step, stepIdx) => (
                <li
                  key={step?.name}
                  className="relative md:flex justify-between"
                >
                  {step.id < current ? (
                    <a
                      // href={step.href}
                      className="group flex w-full items-center"
                    >
                      <span className="flex items-center px-4 py-4 text-sm font-medium">
                        <span className="hidden h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary group-hover:bg-indigo-800 lg:flex">
                          <CheckIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-0 lg:ml-4 text-xs font-medium text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  ) : step.id === current ? (
                    <a
                      // href={step.href}
                      className="flex items-center px-4 py-4 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="hidden lg:flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                        <span className="text-indigo-600">{step.id}</span>
                      </span>
                      <span className="ml-0 lg:ml-4 text-xs font-sm text-indigo-600">
                        {step.name}
                      </span>
                    </a>
                  ) : (
                    <a
                      // href={step.href}
                      className="group flex items-center"
                    >
                      <span className="flex items-center px-4 py-4 text-sm font-medium">
                        <span className="hidden lg:flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900 font-xs">
                            {step.id}
                          </span>
                        </span>
                        <span className="ml-0 lg:ml-4 text-xs font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  )}

                  {stepIdx !== steps?.length - 1 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div className=" h-4 w-4 self-center" aria-hidden="true">
                        <ChevronRightIcon />
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
          <nav className={"flex md:hidden z-[100]"}></nav>
        </div>
      </nav>
    </div>
  );
};

export default NavSteps;
