import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  data: [],
};

export const getBranchClients = createAsyncThunk(
  "clients/getBranchClients",
  async (data, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.get(
      `Branch/get-clients/${data?.id ? data?.id : shop?.id}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const clientsSlice = createSlice({
  name: "Clients",
  initialState,
  reducers: {
    setClients(state, action) {
      state.data = action.payload;
    },
    addNewClient(state, action) {
      state.data = [...state.data, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchClients.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchClients.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = [...action.payload];
        }
      })
      .addCase(getBranchClients.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

/** Selectors **/
export const customersSelector = (state) => state.clients?.data;

export const { setClients, addNewClient } = clientsSlice.actions;

export default clientsSlice.reducer;
