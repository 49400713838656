import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { SelectAServiceButtonInSlideOver } from "../SelectAServiceButtonInSlideOver/SelectAServiceButtonInSlideOver";
import ServicesList from "../ServicesList/ServicesList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bookAppointment,
  selectedClientForAppointment,
  setExtraData,
  setIsAppointmentSlideOverOpen,
  setSelectedClient,
  setSelectedService,
  setSelectedTime,
  setSelectedWorker,
} from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import { SelectAClientButtonInSlideOver } from "../SelectAClientButtonInSlideOver/SelectAClientButtonInSlideOver";
import { ClientsList } from "../ClientsList/ClientsList";
import dayjs from "dayjs";
import { AddClientSlideOver } from "../AddClientSlideOver/AddClientSlideOver";

import utc from "dayjs/plugin/utc";

import { NavigationInsideBookAppointmentSlideOver } from "../NavigationInsideBookAppointmentSlideOver/NavigationInsideBookAppointmentSlideOver";
import SelectedEmployee from "./components/SelectedEmployee";
import SelectedDateTime from "./components/SelectedDateTime";

dayjs.extend(utc);

export const BookAppointmentSlideOver = () => {
  const [open, setOpen] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [isAddClientSlideOverOpen, setIsAddClientSlideOverOpen] =
    useState(false);
  const [isSelectServicesOpen, setIsSelectServicesOpen] = useState(false);
  const [isSelectClientOpen, setIsSelectClientOpen] = useState(false);
  const isAppointmentSlideOverOpen = useSelector(
    (state) => state.appointment.isAppointmentSlideOverOpen,
  );
  const clientForAppointment = useSelector(selectedClientForAppointment);
  const selectedTimeForAppointment = useSelector(
    (state) => state.appointment.selectedTime,
  );
  const selectedServiceForAppointment = useSelector(
    (state) => state.appointment.selectedService,
  );
  const selectedWorkerForAppointment = useSelector(
    (state) => state.appointment.selectedWorker,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!open && isAppointmentSlideOverOpen) {
      setTimeout(() => {
        dispatch(setSelectedTime(null));
        dispatch(setIsAppointmentSlideOverOpen(false));
        dispatch(setSelectedService(null));
        dispatch(setSelectedClient(null));
        dispatch(setSelectedWorker(null));
        dispatch(setExtraData(""));
      }, 600);
    }
  }, [open]);
  useEffect(() => {
    if (isAppointmentSlideOverOpen && !open) {
      setOpen(true);
    }
  }, [isAppointmentSlideOverOpen]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (textArea !== "") {
        dispatch(setExtraData(textArea));
      } else {
        dispatch(setExtraData(""));
      }
    }, 400);

    return () => clearTimeout(getData);
  }, [textArea]);

  return (
    <SlideOverWithCloseButtonOnOutside
      open={open}
      setOpen={setOpen}
      onClose={() => {
        setOpen(false);
        dispatch(setSelectedTime(null));
      }}
      title={<NavigationInsideBookAppointmentSlideOver />}
    >
      <SelectedEmployee employee={selectedWorkerForAppointment} />
      <SelectedDateTime
        selectedTimeForAppointment={selectedTimeForAppointment}
        selectedServiceForAppointment={selectedServiceForAppointment}
      />
      <SelectAClientButtonInSlideOver
        close={() => {
          setIsSelectClientOpen(false);
        }}
        onClick={() => {
          setIsSelectClientOpen(true);
        }}
      />
      <div className={"px-4 pt-4"}>
        <h3 className={"font-semibold text-sm mb-2"}>Υπηρεσίες:</h3>
        <SelectAServiceButtonInSlideOver
          openSelectServiceFromList={() => setIsSelectServicesOpen(true)}
        />
      </div>
      <div className="px-4 mt-3">
        <textarea
          value={textArea}
          onChange={(event) => setTextArea(event.target.value)}
          placeholder={"Σχόλια"}
          className="resize-none rounded-md border w-full p-1 outline-none text-sm h-20"
        ></textarea>
      </div>
      <ClientsList
        open={isSelectClientOpen}
        setAddClientOpen={setIsAddClientSlideOverOpen}
        setOpen={setIsSelectClientOpen}
      />
      <ServicesList
        open={isSelectServicesOpen}
        setOpen={setIsSelectServicesOpen}
      />
      <AddClientSlideOver
        open={isAddClientSlideOverOpen}
        setOpen={setIsAddClientSlideOverOpen}
      />

      <div className={"p-4"}>
        <button
          onClick={() => {
            setOpen(false);
            dispatch(bookAppointment());
          }}
          disabled={!selectedServiceForAppointment || !clientForAppointment}
          type="button"
          className="rounded-md bg-primary px-5 py-2 font-semibold text-white shadow-sm  hover:bg-primary-500
        disabled:bg-inactive
        "
        >
          Κλείσε ραντεβού
        </button>
      </div>
    </SlideOverWithCloseButtonOnOutside>
  );
};
