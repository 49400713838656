import { classNames } from "../../../Utilities/utilities";
import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import { StarIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";

const ReviewCard = ({ review, index }) => {
  return (
    <div className="p-4 px-3 block border-b last:border-none">
      <div className="space-y-8">
        <div className="flex items-center flex-wrap">
          <div className={"inline-flex items-center"}>
            <span className="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9">
              <AvatarComponent
                imageSize={"w-full h-full"}
                ringColor={"border-gray-500"}
                iconColor={"text-gray-700"}
                image={review?.portraitUrl}
              />
            </span>
            <div className="ml-4 space-y-1">
              <p className="text-sm font-medium leading-none">
                {review?.firstName} {review?.lastName}
                <span className="bg-primary-100 ml-1 text-primary-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
                  Νέα
                </span>
              </p>
              <p className={"text-xs text-gray-700"}>
                {dayjs(review?.createdDate).format("DD-MM-YYYY HH:mm")}
              </p>

              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      review?.rating > rating
                        ? "text-yellow-400"
                        : "text-gray-300",
                      "h-4 w-4 flex-shrink-0",
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>

              <span className={"text-sm text-gray-800"}>{review?.comment}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
