import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../../Utilities/utilities";
import { useDispatch } from "react-redux";
import { logout } from "../../Features/authorizeUser/authorizeUser-slice";
import { CloseSideBarButton } from "../CloseSideBarButton/CloseSideBarButton";
import { ProfileIcon } from "../ProfileIcon/ProfileIcon";
import packages from "../../../package.json";

const MobileNavigationSideBar = ({ urls, sidebarOpen, setSidebarOpen }) => {
  const { hash, pathname, search } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50  lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 ">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className=" h-full w-full flex ">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className={`bg-primary relative w-full h-full p-5`}>
                <CloseSideBarButton action={setSidebarOpen} />
                {/*Sidebar component, swap this element with another sidebar if you*/}
                {/*like*/}
                <div className="flex  grow flex-col gap-y-5  overflow-hidden bg-primary-600">
                  <nav className="flex flex-1 flex-col">
                    <ul
                      onClick={() => {
                        setSidebarOpen(false);
                      }}
                      role="list"
                      className="flex flex-1 justify-items-start flex-col gap-y-7 "
                    >
                      <Link to={"/profile"} className={" rounded-xl"}>
                        <ProfileIcon />
                      </Link>
                      <li className={"w-full"}>
                        <ul
                          role="list"
                          className=" space-y-1 flex gap-1 justify-items-start flex-col"
                        >
                          {urls.map((url, index) => (
                            <Link
                              onClick={() => setSidebarOpen(false)}
                              key={index}
                              to={url.path}
                            >
                              <span
                                className={
                                  "group text-white flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold"
                                }
                              >
                                <url.icon
                                  className={classNames(
                                    pathname === url.path
                                      ? "border-b-2 border-white"
                                      : "border-b-2 border-primary-600 group-hover:border-b-2 group-hover:border-white",
                                    "h-8 w-8 p-1 shrink-0 text-white",
                                  )}
                                  aria-hidden="true"
                                />
                                <h6 className={"content-center text-lg"}>
                                  {url.name}
                                </h6>
                              </span>
                            </Link>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>

                <button
                  onClick={() => {
                    dispatch(logout());
                    setSidebarOpen(false);
                    navigate("/");
                  }}
                  className={
                    "cursor-pointer absolute bottom-5 text-white flex gap-x-3 rounded-md p-2 leading-6  text-lg font-semibold "
                  }
                >
                  <ArrowLeftStartOnRectangleIcon
                    className={"text-white h-6 w-6 shrink-0"}
                    aria-hidden="true"
                  />
                  Αποσύνδεση
                </button>
                <span
                  className={"absolute bottom-0 text-sm text-white right-1"}
                >
                  v{packages?.version}
                </span>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileNavigationSideBar;
