import { useSelector } from "react-redux";
import { useState } from "react";

import CategoryCard from "../../Components/CategoryCard/CategoryCard";
import { AddCategorySlideOver } from "../../Components/AddCategorySlideOver/AddCategorySlideOver";
import { EditServiceSlideOver } from "../../Components/EditServiceSlideOver/EditServiceSlideOver";
import { AddServiceSlideOver } from "../../Components/AddServiceSlideOver/AddServiceSlideOver";

export default function ServicesPage() {
  const categoriesWithItsServices = useSelector(
    (state) => state.categoriesWithServices.data,
  );
  const [isAddServiceShown, setIsAddServiceShown] = useState(false);
  const [isAddCategoryShown, setIsAddCategoryShown] = useState(false);
  const [serviceToBeEdited, setServiceToBeEdited] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryThatPressedAddService, setCategoryThatPressedAddService] =
    useState(null);

  return (
    <div className={"p-4"}>
      <nav className={"justify-between flex"}>
        <div className={"flex flex-row gap-2"}>
          <h3 className={"font-bold text-xl sm:text-3xl text-primary"}>
            {categoriesWithItsServices?.length}
          </h3>
          <span className={"text-xl sm:text-3xl"}>Κατηγορίες</span>
        </div>

        <div className={"flex flex-row gap-2"}>
          <button
            className={"bg-primary py-2 px-4 text-sm text-white rounded-md"}
            onClick={() => setIsAddCategoryShown(true)}
          >
            + Δημιουργία
          </button>
        </div>
      </nav>
      <div
        className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-5"}
      >
        {categoriesWithItsServices?.map((category) => (
          <CategoryCard
            setIsAddCategoryShown={setIsAddCategoryShown}
            setSelectedCategory={setSelectedCategory}
            setIsAddServiceShown={setIsAddServiceShown}
            setCategoryThatPressedAddService={setCategoryThatPressedAddService}
            key={category?.id + category?.services?.length}
            category={category}
            setServiceToBeEdited={setServiceToBeEdited}
          />
        ))}
      </div>

      <AddServiceSlideOver
        isAddServiceShown={isAddServiceShown}
        isPressedFromCategory={categoryThatPressedAddService}
        onCancel={() => {
          setIsAddServiceShown(false);
        }}
      />

      <EditServiceSlideOver
        selectedServiceToBeEdited={serviceToBeEdited}
        onCancel={() => {
          setCategoryThatPressedAddService(null);
          setServiceToBeEdited(null);
        }}
      />

      <AddCategorySlideOver
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        isAddCategoryShown={isAddCategoryShown}
        onCancel={() => {
          setIsAddCategoryShown(false);
          setSelectedCategory(null);
        }}
      />
    </div>
  );
}
