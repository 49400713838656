import { MyBarChart } from "./components/Chart";
import UserSalesCard from "./components/UserSalesCard";
import HeaderCards from "./components/HeaderCards";
import Nav from "./components/Nav";
import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "./components/ReviewCard";
import { useEffect } from "react";
import {
  bestCustomersSelector,
  employeesIncomeSelector,
  fullYearIncomeSelect,
  getBestCustomers,
  getEmployeeIncome,
  getTotalIncome,
  getYearIncomeWithEachMonths,
  initializeEmployeesIncome,
  yearIncomeEachMonthSelect,
} from "../../Features/statistics/statistics-slice";
import dayjs from "dayjs";
import {
  getRatings,
  getReviews,
  reviewsSelector,
} from "../../Features/reviews/reviews-slice";

const HomeScreen = () => {
  const employees = useSelector((state) => state.employees?.data);
  const shopData = useSelector((state) => state.shop);
  const fullYearIncome = useSelector(fullYearIncomeSelect);
  const yearIncomeEachMonth = useSelector(yearIncomeEachMonthSelect);
  const bestCustomers = useSelector(bestCustomersSelector);
  const employeesIncome = useSelector(employeesIncomeSelector);
  const reviews = useSelector(reviewsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shopData?.id) {
      initializeTheApp();
    }
  }, [shopData]);

  const initializeTheApp = async () => {
    await dispatch(initializeEmployeesIncome());
    await dispatch(getYearIncomeWithEachMonths("2024"));
    await dispatch(getBestCustomers());
    await dispatch(getReviews());
    await dispatch(getRatings());
    await dispatch(
      getTotalIncome({
        data: {
          dateStart: dayjs().format("YYYY-MM-DD"),
          dateEnd: dayjs().format("YYYY-MM-DD"),
        },
      }),
    );
    employees?.forEach((employee) => {
      const data = {
        dateStart: dayjs().format("YYYY-MM-DD"),
        dateEnd: dayjs().format("YYYY-MM-DD"),
        employee,
      };
      dispatch(getEmployeeIncome({ data }));
    });
  };

  return (
    <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8 md:pt-2">
      <div className="md:flex items-center justify-between space-y-2">
        <Nav />
      </div>
      <div className="grid gap-3 md:grid-cols-2 md:gap-4 lg:grid-cols-4">
        <HeaderCards />
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-7">
        <div className="rounded-xl border bg-card text-card-foreground overflow-hidden shadow col-span-10 md:col-span-3">
          <MyBarChart
            data={yearIncomeEachMonth}
            fullYearIncome={fullYearIncome}
          />
        </div>

        <div className="rounded-xl border overflow-hidden bg-card text-card-foreground shadow col-span-10 md:col-span-1 lg:md:col-span-2">
          <div className="col-span-4">
            <div className="flex flex-row justify-between items-center space-y-1.5 p-3 bg-gray-100">
              <h3 className="font-semibold leading-none tracking-tight">
                Υπάλληλοι
              </h3>
              <h3 className="font-semibold leading-none text-primary tracking-tight">
                {employeesIncome?.length}
              </h3>
            </div>
            <div className={"overflow-y-auto h-[345px]"}>
              {employeesIncome?.map((employee, index) => (
                <UserSalesCard key={index} data={employee} isEmployee />
              ))}
            </div>
          </div>
        </div>
        <div className="rounded-xl border overflow-hidden bg-card text-card-foreground shadow col-span-10 md:col-span-1 lg:md:col-span-2">
          <div className="col-span-4">
            <div className="flex flex-row justify-between items-center space-y-1.5 p-3 bg-gray-100">
              <h3 className="font-semibold leading-none tracking-tight">
                Top πελάτες
              </h3>
              <button className="font-semibold leading-none text-primary tracking-tight">
                Δες όλους
              </button>
            </div>
            <div className={"overflow-y-auto h-[345px]"}>
              {bestCustomers?.map((customer, index) => (
                <UserSalesCard key={index} data={customer} index={index + 1} />
              ))}
            </div>
          </div>
        </div>

        <div className="rounded-xl border overflow-hidden bg-card text-card-foreground shadow col-span-10 md:col-span-2 lg:md:col-span-3">
          <div className="flex flex-row justify-between items-center space-y-1.5 p-3 bg-gray-100">
            <h3 className="font-semibold leading-none tracking-tight">
              Αξιολογήσεις
            </h3>
            <button className="font-semibold leading-none text-primary tracking-tight">
              Δες όλες
            </button>
          </div>
          <div className={"overflow-y-auto max-h-[500px]"}>
            {reviews?.map((review, index) => (
              <ReviewCard key={index} review={review} index={index + 1} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};
export default HomeScreen;
