import React, { useRef } from "react";
import { useSelector } from "react-redux";
import HeaderOfViews from "../HeaderOfViews/HeaderOfViews";
import WeekNavigator from "../WeekNavigator/WeekNavigator";
import LeftPartShowingHours from "../LeftPartShowingHours/LeftPartShowingHours";
import Day from "../Day/Day";

export default function DayView() {
  const containerNav = useRef(null);
  const shouldUpdate = useSelector(
    (state) => state.typeOfView.setShouldViewUpdate,
  );
  return (
    <div style={{ maxWidth: "100%" }} className="flex h-full flex-col">
      <HeaderOfViews />
      <WeekNavigator typeOfNavigator={"1Day"} containerNav={containerNav} />
      <div className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div
          style={{
            width: "100%",

            maxWidth: "100%",
          }}
          className="flex flex-none flex-col "
        >
          <div className={"flex flex-auto"}>
            <LeftPartShowingHours />
            <Day index={0} key={shouldUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
}
