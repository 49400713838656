import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getBranchClients } from "../clients/clientsSlice";
import { getBranchOrEmployeeAppointments } from "../appointments/appointments-slice";
import { getBranchEmployees } from "../employees/employees-slice";
import { getBranchOwnerInfo } from "../personalData/personalData-slice";

//TODO Change states like this!!!! to {isLoading: false, data: {} or [],error: null}
const initialState = {
  id: null,
  name: null,
  type: null,
  country: null,
  region: null,
  address: null,
  city: null,
  zipCode: null,
  latitude: null,
  longitude: null,
  phone: null,
  createdDate: null,
  totalRatings: 0,
  portraitUrl: null,
  averageRating: 0,
  aboutUs: null,
  services: null,
  gallery: null,
  shifts: null,
  isLoading: false,
};
export const getShop = createAsyncThunk(
  "shop/getShop",
  async ({ id }, thunkAPI) => {
    //TODO Use try/catch to handle errors
    const res = await Request.get(`Branch/get-branch/${id}`);
    if (isResponseSuccessful(res)) {
      await thunkAPI.dispatch(getBranchEmployees({ id: res?.id }));
      await thunkAPI.dispatch(getBranchClients({ id: res?.id }));
      await thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: res?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const createShop = createAsyncThunk(
  "shop/createShop",
  async ({ payload }, thunkAPI) => {
    const res = await Request.post("Branch/create-branch", {
      body: {
        ...payload,
      },
    });
    if (isResponseSuccessful(res)) {
      const { shop } = thunkAPI.getState();
      const res = await thunkAPI.dispatch(getBranchOwnerInfo());
      if (!shop?.id) {
        thunkAPI.dispatch(
          getShop({
            id: res.payload?.branches?.[0]?.id,
          }),
        );
      }
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const editShop = createAsyncThunk(
  "shop/editShop",
  async ({ payload }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.put(`Branch/edit-branch/${shop?.id}`, {
      body: payload,
    });
    if (isResponseSuccessful(res)) {
      const res = await thunkAPI.dispatch(getBranchOwnerInfo());
      if (!shop?.id) {
        thunkAPI.dispatch(
          getShop({
            id: res.payload?.branches?.[0]?.id,
          }),
        );
      }
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const shopSlice = createSlice({
  name: "Shop",
  initialState,
  reducers: {
    setShopId(state, action) {
      state.id = action.payload;
    },
    setShopIdAndName(state, action) {
      state.id = action.payload.branchId;
      state.name = action.payload.branchName;
    },
    setShop(state, action) {
      //TODO just do this state.shop.data = action.payload
      (state.id = action.payload.id),
        (state.name = action.payload.name),
        (state.type = action.payload.type),
        (state.country = action.payload.country),
        (state.region = action.payload.region),
        (state.address = action.payload.address),
        (state.city = action.payload.city),
        (state.zipCode = action.payload.zipCode),
        (state.latitude = action.payload.latitude),
        (state.longitude = action.payload.longitude),
        (state.phone = action.payload.phone),
        (state.createdDate = action.payload.createdDate),
        (state.totalRatings = action.payload.totalRatings),
        (state.averageRating = action.payload.averageRating),
        (state.aboutUs = action.payload.aboutUs),
        (state.services = action.payload.services),
        (state.portraitUrl = action.payload.portraitUrl),
        (state.gallery = action.payload.gallery);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShop.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShop.fulfilled, (state, action) => {
        state.isLoading = false;
        //TODO You have to check fot errors not for payload. In case the response is 400 you get if(action.payload) = true. Check for action.payload.error
        if (action.payload) {
          //TODO just do this state.shop.data = action.payload
          (state.id = action.payload.id),
            (state.name = action.payload.name),
            (state.type = action.payload.type),
            (state.country = action.payload.country),
            (state.region = action.payload.region),
            (state.address = action.payload.address),
            (state.city = action.payload.city),
            (state.zipCode = action.payload.zipCode),
            (state.latitude = action.payload.latitude),
            (state.longitude = action.payload.longitude),
            (state.phone = action.payload.phone),
            (state.createdDate = action.payload.createdDate),
            (state.totalRatings = action.payload.totalRatings),
            (state.averageRating = action.payload.averageRating),
            (state.aboutUs = action.payload.aboutUs),
            (state.services = action.payload.services),
            (state.portraitUrl = action.payload.portraitUrl),
            (state.gallery = action.payload.gallery);
        }
      })
      .addCase(getShop.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(createShop.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createShop.fulfilled, (state, action) => {
        state.isLoading = false;
        //TODO In case the response is 400 you get if(action.payload) = true. Check for action.payload.error
        toast.success("Το μαγαζί σας δημιουργήθηκε με επιτυχία. Καλή αρχή 🚀");
      })
      .addCase(createShop.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(editShop.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editShop.fulfilled, (state, action) => {
        state.isLoading = false;
        //TODO In case the response is 400 you get if(action.payload) = true. Check for action.payload.error
        toast.success("Οι πληροφορίες του μαγαζιού αποθηκεύτηκαν επιτυχώς");
      })
      .addCase(editShop.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

//TODO Create here selectors ->
export const servicesOfShop = (state) => state.shop?.services;
export const isShopLoading = (state) => state.shop?.isLoading;

export const { setShop, setShopIdAndName, setShopId } = shopSlice.actions;

export default shopSlice.reducer;
