import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllWeekShiftsCreator } from "../AllWeekShiftsCreator/AllWeekShiftsCreator";

import {
  getBranchShifts,
  saveBranchShifts,
} from "../../Features/shifts/shifts-slice";
import {
  modifyShopShiftsToUtcTime,
  turnPayloadFromBackendToHaveLocalTimeNotUtc,
} from "../../Utilities/utilities";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";

const OpenHoursWrapper = () => {
  const shopId = useSelector((state) => state.shop?.id);
  const allWeekShifts = useSelector((state) => state.shifts.branchShifts);
  const dispatch = useDispatch();
  const [allWeekShiftsArray, setAllWeekShiftsArray] = useState(null);
  const areBranchShiftsLoading = useSelector((state) => state.shifts.isLoading);
  useEffect(() => {
    if (allWeekShifts) {
      setAllWeekShiftsArray(
        turnPayloadFromBackendToHaveLocalTimeNotUtc(allWeekShifts),
      );
    }
  }, [allWeekShifts]);

  useEffect(() => {
    if (shopId) {
      const awaitGetShifts = async () => {
        const res = await dispatch(getBranchShifts());
        if (res?.payload) {
          setAllWeekShiftsArray([
            ...turnPayloadFromBackendToHaveLocalTimeNotUtc(res?.payload),
          ]);
        }
      };
      awaitGetShifts();
    }
  }, [shopId]);

  return (
    <div>
      <div
        style={{ justifyContent: "space-around" }}
        className={"sm:flex w-full  sm:items-center"}
      >
        <div className={"sm:flex w-full  sm:items-start flex-col"}>
          <h2 className="text-base sm:flex sm:items-center font-semibold leading-7  text-gray-900">
            Ωράριο Λειτουργίας
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Το ωράριο θα φαίνεται στους πελάτες, βεβαιώσου πως είναι σωστές οι
            πληροφορίες.
          </p>
        </div>
        <div className={"mt-4 sm:ml-16 sm:mt-0 sm:flex-none"}>
          <ButtonWithLoader
            text={"Αποθήκευση"}
            isLoading={areBranchShiftsLoading}
            customStyling={
              "block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            }
            onClick={() => {
              dispatch(
                saveBranchShifts({
                  weekShifts: modifyShopShiftsToUtcTime([
                    ...allWeekShiftsArray,
                  ]),
                }),
              );
            }}
          />
        </div>
      </div>
      {allWeekShiftsArray && (
        <AllWeekShiftsCreator
          setAllWeekShiftsArray={setAllWeekShiftsArray}
          allWeekShiftsArray={allWeekShiftsArray}
        />
      )}
    </div>
  );
};

export default OpenHoursWrapper;
