import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../Utilities/utilities";

export default function SelectSex({
  isFromSignUp,
  selectedSex,
  setSelectedSex,
}) {
  const sexes = isFromSignUp
    ? [
        { id: 1, name: "Male", gr: "Άντρας" },
        { id: 2, name: "Female", gr: "Γυναίκα" },
      ]
    : [
        { id: 1, name: "Both", gr: "Και οι δύο" },
        { id: 2, name: "Male", gr: "Άντρας" },
        { id: 3, name: "Female", gr: "Γυναίκα" },
      ];

  useEffect(() => {
    setSelectedSex(sexes[0]);
  }, []);
  return (
    <Listbox value={selectedSex} onChange={setSelectedSex}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            Επιλεγμένο Φύλο
          </Listbox.Label>
          <div className="relative ">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selectedSex?.gr}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {sexes.map((sex) => (
                  <Listbox.Option
                    key={sex.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-primary text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={sex}
                  >
                    {({ selectedSex, active }) => (
                      <>
                        <span
                          className={classNames(
                            selectedSex ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          {sex?.gr}
                        </span>

                        {selectedSex ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
