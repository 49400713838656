import AvatarComponent from "../../../Components/AvatarComponent/AvatarComponent";
import { classNames } from "../../../Utilities/utilities";

const UserSalesCard = ({ data, index, isEmployee }) => {
  return (
    <div className="p-4 px-3 block border-b last:border-none">
      <div className="space-y-8">
        <div className="flex items-center flex-wrap">
          <div className={"inline-flex items-center"}>
            {index && (
              <span
                className={classNames(
                  index === 1
                    ? "bg-amber-500"
                    : index === 2
                      ? "bg-gray-400"
                      : index === 3
                        ? "bg-orange-600"
                        : "bg-gray-200",
                  " rounded-full w-6 h-6 mr-2 flex text-sm items-center justify-center",
                )}
              >
                {index}
              </span>
            )}

            <span className="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9">
              <AvatarComponent
                imageSize={"w-full h-full"}
                ringColor={"border-gray-500"}
                iconColor={"text-gray-700"}
                image={data?.profilePicUrl || data?.profilePicture}
              />
            </span>
            <div className="ml-2 space-y-1">
              <p className="text-sm font-medium leading-none">
                {data?.lastName}{" "}
                {data?.firstName ? data?.firstName?.slice(0, 1) : ""}.
              </p>
              {/*{isEmployee ? (*/}
              {/*  <p className="text-xs text-gray-800 text-muted-foreground">*/}
              {/*    Συνολικά ραντεβού - <b>{data?.totalAppointments}</b>*/}
              {/*  </p>*/}
              {/*) : (*/}
              <a href={`tel:${data?.phoneNumber}`}>
                <p className="text-sm text-muted-foreground">
                  {data?.phoneNumber}
                </p>
              </a>
              {/*)}*/}
            </div>
          </div>
          <div className="ml-auto font-medium">
            {!data?.additionalInformation?.length
              ? data?.information[2]?.totalIncome
              : data?.additionalInformation[2]?.totalIncome}
            €
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserSalesCard;
