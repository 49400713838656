import { useDispatch, useSelector } from "react-redux";
import { LoggedInRoutes } from "../LoggedInRoutes/LoggedInRoutes";
import { LoggedOutRoutes } from "../LoggedOutRoutes/LoggedOutRoutes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { logout } from "../../Features/authorizeUser/authorizeUser-slice";

export const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.authorizeUser.isLoggedIn);
  const role = localStorage.getItem("role");
  const flowEnabled = useSelector((state) => state.authorizeUser.enabledFlow);
  const navigate = useNavigate();
  const { pathname } = location;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      pathname?.includes("register-employee") ||
      pathname?.includes("create-employee")
    ) {
      dispatch(logout());
    }
  }, []);
  useEffect(() => {
    if (flowEnabled) {
      navigate(`/${flowEnabled}`);
    }
  }, [flowEnabled]);

  //TODO: Find a way to make it play with role, when I come in from url, to perform accept invitation
  if (isLoggedIn || role) {
    return <LoggedInRoutes />;
  }

  if (!isLoggedIn) {
    return <LoggedOutRoutes />;
  }
};
