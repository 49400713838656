export const ConstructWeeklyShifts = (
  indexOfDay,
  indexOfShift,
  fromOrTo,
  time,
  isFromDelete,
  allWeekShiftsArray,
  setAllWeekShiftsArray,
) => {
  if (!isFromDelete) {
    const newArr = allWeekShiftsArray.map((dayObj) => ({ ...dayObj }));

    // Find the day object to update
    const dayToUpdate = newArr.find((dayObj) => dayObj.day === indexOfDay);
    // Update the shift times for the appropriate index
    if (dayToUpdate) {
      const updatedDay = { ...dayToUpdate }; // Create a deep copy of the day object
      if (indexOfShift === 0) {
        if (fromOrTo === "from") {
          updatedDay.firstShiftStart = time;
        }
        if (fromOrTo === "to") {
          updatedDay.firstShiftEnd = time;
        }
      }
      if (indexOfShift === 1) {
        if (fromOrTo === "from") {
          updatedDay.secondShiftStart = time;
        }
        if (fromOrTo === "to") {
          updatedDay.secondShiftEnd = time;
        }
      }

      // Replace the original day object with the updated one in the new array
      let updatedArray = newArr?.filter((day) => day.day !== indexOfDay);
      updatedArray.push(updatedDay);

      // Update the state with the new array
      setAllWeekShiftsArray([...updatedArray]);
    }
  }

  if (isFromDelete) {
    const newArr = allWeekShiftsArray.map((dayObj) => ({ ...dayObj }));

    // Find the day object to update
    const dayToUpdate = newArr.find((dayObj) => dayObj.day === indexOfDay);
    // Update the shift times for the appropriate index
    if (dayToUpdate) {
      const updatedDay = { ...dayToUpdate }; // Create a deep copy of the day object
      if (indexOfShift === 0) {
        updatedDay.firstShiftStart = "00:00";
        updatedDay.firstShiftEnd = "00:00";
        updatedDay.secondShiftStart = "00:00";
        updatedDay.secondShiftEnd = "00:00";
      }
      if (indexOfShift === 1) {
        updatedDay.secondShiftStart = "00:00";

        updatedDay.secondShiftEnd = "00:00";
      }

      // Replace the original day object with the updated one in the new array
      let updatedArray = newArr?.filter((day) => day.day !== indexOfDay);
      updatedArray.push(updatedDay);

      // Update the state with the new array
      setAllWeekShiftsArray([...updatedArray]);
    }
  }
};
