import { styles } from "./styles";
import Shift from "../Shift/Shift";
import {
  classNames,
  fullDaysEnglishToGreek,
  indexOfDays,
} from "../../Utilities/utilities";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

const DayAndHour = ({
  isFromEmployees,
  setAllWeekShiftsArray,
  allWeekShiftsArray,
  day,
}) => {
  const [isSecondShiftShown, setIsSecondShiftShown] = useState(false);
  const numberOfShifts = [0, 1];
  const [isPlusIconPressed, setIsPlusIconPressed] = useState(false);
  const [shouldDayDisplayFirstShift, setShouldDayDisplayFirstShift] =
    useState(false);
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  useEffect(() => {
    if (
      allWeekShiftsArray?.filter(
        (dayObjWithShifts) => dayObjWithShifts?.day === indexOfDays?.[day],
      )?.[0]?.firstShiftStart !== "00:00" &&
      allWeekShiftsArray?.filter(
        (dayObjWithShifts) => dayObjWithShifts?.day === indexOfDays?.[day],
      )?.[0]?.firstShiftEnd !== "00:00"
    ) {
      setShouldDayDisplayFirstShift(true);
    }

    if (!shouldDayDisplayFirstShift) {
      if (isPlusIconPressed) {
        setShouldDayDisplayFirstShift(true);
        setIsPlusIconPressed(false);
      }
    }
  }, [allWeekShiftsArray, isPlusIconPressed]);

  return (
    <>
      <div style={styles.day}>
        <div className={"flex flex-col"}>
          <span
            style={{
              fontSize: "14px",
              minWidth: "100px",
            }}
            className={
              "text-base sm:flex sm:items-center font-semibold leading-7  text-gray-900"
            }
          >
            {fullDaysEnglishToGreek[day]}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {shouldDayDisplayFirstShift &&
            numberOfShifts.map((i) => (
              <Shift
                setShouldDisplayFirstShift={setShouldDayDisplayFirstShift}
                isFromEmployees={isFromEmployees}
                isSecondShiftShown={isSecondShiftShown}
                setIsSecondShiftShown={setIsSecondShiftShown}
                day={day}
                index={i}
                key={i}
                allWeeksShiftsArray={allWeekShiftsArray}
                setAllWeekShiftsArray={setAllWeekShiftsArray}
              />
            ))}
          {!shouldDayDisplayFirstShift && loggedInAs !== "employee" && (
            <Tooltip title={"Προσθήκη Βάρδιας"} className={"w-10 h-10 m-1"}>
              <PlusIcon
                onClick={() => {
                  setIsPlusIconPressed(true);
                }}
                style={{
                  width: 30,
                  height: 15,
                }}
                className={classNames(
                  "text-green-900 bg-green-100 rounded hover:bg-green-300 ",
                )}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <dl className=" space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"></dl>
    </>
  );
};

export default DayAndHour;
