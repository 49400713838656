import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { SelectAServiceButtonInSlideOver } from "../SelectAServiceButtonInSlideOver/SelectAServiceButtonInSlideOver";
import ServicesList from "../ServicesList/ServicesList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppointmentToEdit,
  setExtraData,
  setIsEditAppointmentSlideOverOpen,
  setSelectedClient,
  setSelectedService,
  setSelectedTime,
  setSelectedWorker,
} from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import { SelectAClientButtonInSlideOver } from "../SelectAClientButtonInSlideOver/SelectAClientButtonInSlideOver";
import { ClientsList } from "../ClientsList/ClientsList";
import {
  cancelAppointment,
  editAppointment,
} from "../../Features/editAppointment/editAppointment-slice";
import SelectedEmployee from "../BookAppointmentSlideOver/components/SelectedEmployee";
import SelectedDateTime from "../BookAppointmentSlideOver/components/SelectedDateTime";
import AppointmentStatusChanger from "../AppointmentStatusChanger/AppointmentStatusChanger";

export const EditAppointmentSlideOver = () => {
  const [open, setOpen] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [isSelectServicesOpen, setIsSelectServicesOpen] = useState(false);
  const [isSelectClientOpen, setIsSelectClientOpen] = useState(false);
  const isEditAppointmentSlideOverOpen = useSelector(
    (state) => state.appointment.isEditAppointmentSlideOverOpen,
  );
  const appointmentToEdit = useSelector(
    (state) => state.appointment.appointmentToEdit,
  );
  const selectedTimeForAppointment = useSelector(
    (state) => state.appointment.selectedTime,
  );
  const selectedClientForAppointment = useSelector(
    (state) => state.appointment.selectedClient,
  );
  const selectedServiceForAppointment = useSelector(
    (state) => state.appointment.selectedService,
  );
  const selectedWorkerForAppointment = useSelector(
    (state) => state.appointment.selectedWorker,
  );
  const dispatch = useDispatch();
  const servicesFromShop = useSelector(
    (state) => state.categoriesWithServices?.data,
  );
  const employees = useSelector((state) => state.employees?.data);
  const employeeServices = employees?.filter(
    (employee) => employee?.id === appointmentToEdit?.employeeId,
  )?.[0]?.services;

  const employeeServicesThatMatchWithTheBranchServiceOfThisAppointment = () => {
    let serviceToReturn = [];

    servicesFromShop?.map((category) => {
      category.services?.map((service) => {
        employeeServices?.map((employeeService) => {
          appointmentToEdit.services.map((appointmentService) => {
            if (
              employeeService.branchServiceId === service?.id &&
              employeeService.branchServiceId === appointmentService.id
            ) {
              return serviceToReturn.push({
                ...employeeService,
              });
            }
          });
        });
      });
    });

    return serviceToReturn;
  };

  useEffect(() => {
    if (!open && isEditAppointmentSlideOverOpen) {
      setTimeout(() => {
        dispatch(setSelectedTime(null));
        dispatch(setIsEditAppointmentSlideOverOpen(false));
        dispatch(setSelectedService(null));
        dispatch(setSelectedClient(null));
        dispatch(setSelectedWorker(null));
        dispatch(setAppointmentToEdit(null));
        dispatch(setExtraData(""));
      }, 600);
    }
  }, [open]);
  useEffect(() => {
    if (isEditAppointmentSlideOverOpen && !open) {
      setOpen(true);
    }
  }, [isEditAppointmentSlideOverOpen]);

  useEffect(() => {
    if (appointmentToEdit) {
      dispatch(
        setSelectedService([
          ...employeeServicesThatMatchWithTheBranchServiceOfThisAppointment(),
        ]),
      );

      dispatch(setSelectedTime(appointmentToEdit?.bookedDate));

      dispatch(
        setSelectedClient({
          firstName: appointmentToEdit?.userFirstName,
          lastName: appointmentToEdit?.userLastName,
          // email: appointmentToEdit.clientData?.email,
          phone: appointmentToEdit?.phone,
          id: appointmentToEdit.userId,
        }),
      );
      dispatch(setExtraData(appointmentToEdit?.additionalInformation || ""));
      setTextArea(appointmentToEdit?.additionalInformation);
    }
  }, [appointmentToEdit]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (textArea !== "") {
        dispatch(setExtraData(textArea));
      } else {
        dispatch(setExtraData(""));
      }
    }, 400);

    return () => clearTimeout(getData);
  }, [textArea]);

  return (
    <SlideOverWithCloseButtonOnOutside
      title={"Επεξεργασία Ραντεβού"}
      open={open}
      setOpen={setOpen}
    >
      <AppointmentStatusChanger appointment={appointmentToEdit} />
      <SelectedEmployee employee={selectedWorkerForAppointment} />
      <SelectedDateTime
        selectedTimeForAppointment={selectedTimeForAppointment}
        selectedServiceForAppointment={selectedServiceForAppointment}
      />
      {/*//TODO -> open client profile with history*/}
      <SelectAClientButtonInSlideOver />
      {/*TODO <-*/}

      <div className={"px-4 pt-4"}>
        <h3 className={"font-semibold text-sm mb-2"}>Υπηρεσίες:</h3>
        <SelectAServiceButtonInSlideOver
          openSelectServiceFromList={() => setIsSelectServicesOpen(true)}
        />
      </div>
      <ClientsList open={isSelectClientOpen} setOpen={setIsSelectClientOpen} />

      <ServicesList
        open={isSelectServicesOpen}
        setOpen={setIsSelectServicesOpen}
      />
      <div className="px-4 mt-3">
        <textarea
          value={textArea}
          onChange={(event) => setTextArea(event.target.value)}
          placeholder={"Σχόλια"}
          className="resize-none rounded-md border w-full p-1 outline-none text-sm h-20"
        ></textarea>
      </div>
      <div className={"flex justify-between p-4"}>
        <button
          disabled={
            appointmentToEdit?.services[0]?.id ===
              selectedServiceForAppointment?.id &&
            selectedWorkerForAppointment?.id ===
              appointmentToEdit?.employeeId &&
            appointmentToEdit?.userFirstName +
              appointmentToEdit?.userLastName ===
              selectedClientForAppointment?.firstName +
                selectedClientForAppointment?.lastName
          }
          onClick={() => {
            setOpen(false);
            dispatch(editAppointment());
          }}
          type="button"
          className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500"
        >
          Αποθήκευση
        </button>
        <button
          onClick={() => {
            setOpen(false);
            dispatch(cancelAppointment());
          }}
          className="rounded-md bg-red-200 text-red-700 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-300"
        >
          Ακύρωση ραντεβού
        </button>
      </div>
    </SlideOverWithCloseButtonOnOutside>
  );
};
