import { validatePhone } from "../../Utilities/validatePhone";
import { logout } from "../../Features/authorizeUser/authorizeUser-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";

export const BusinessBasicInfo = ({
  isFromSettings,
  onNext,
  businessName,
  setIsAddBranchPressed,
  setBusinessName,
  setBusinessPhone,
  businessPhone,
  aboutUs,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={"bg-white m-auto sm:w-[500px] p-4 rounded-xl shadow"}>
      <div>
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Πες μας για την επιχείρηση σου
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Έτσι θα φαίνεται το μαγαζί, στους πελάτες.
          </p>
        </div>
      </div>

      <div className=" pb-12">
        <div className="mt-10  grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Όνομα επιχείρησης
            </label>
            <div className="mt-2">
              <input
                name={"business-name"}
                id={"business-name"}
                placeholder={"Shop"}
                onChange={(e) => setBusinessName(e.target.value)}
                value={businessName}
                type="text"
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Τηλέφωνο
            </label>
            <div className="mt-2">
              <PhoneNumberInput
                phone={businessPhone}
                setPhone={setBusinessPhone}
              />
              {/*<input*/}
              {/*  type="text"*/}
              {/*  name="business-phone"*/}
              {/*  id="business-phone"*/}
              {/*  onChange={(e) => {*/}
              {/*    setBusinessPhone(e.target.value);*/}
              {/*  }}*/}
              {/*  value={businessPhone}*/}
              {/*  placeholder={"+30"}*/}
              {/*  autoComplete="phone"*/}
              {/*  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        {!isFromSettings && (
          <button
            onClick={() => {
              dispatch(logout());
              navigate("/");
            }}
            className="bg-gray-400 rounded-md px-3 cursor-pointer py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Πίσω
          </button>
        )}
        {isFromSettings && (
          <button
            onClick={() => {
              if (setIsAddBranchPressed) {
                setIsAddBranchPressed(false);
              }
            }}
            className="bg-gray-400 rounded-md cursor-pointer px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Πίσω
          </button>
        )}
        <button
          disabled={
            !businessName ||
            !businessPhone ||
            !isValidPhoneNumber(businessPhone) ||
            aboutUs?.length === 0
          }
          onClick={() => onNext()}
          className="disabled:bg-gray-300 cursor-pointer rounded-md bg-primary px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Επόμενο
        </button>
      </div>
    </div>
  );
};
