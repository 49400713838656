import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import ClientCard from "../ClientCard/ClientCard";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import { UsersIcon } from "@heroicons/react/24/outline";

export const SelectAClientButtonInSlideOver = ({ onEdit, onClick }) => {
  const selectedClient = useSelector(
    (state) => state?.appointment?.selectedClient,
  );

  if (selectedClient) {
    return (
      <ClientCard onEdit={onEdit ? onEdit : onClick} client={selectedClient} />
    );
  }

  if (!selectedClient) {
    return (
      <button
        type="button"
        onClick={onClick}
        className="w-full relative flex items-center justify-between p-4 text-sm font-semibold text-gray-900
         hover:bg-gray-50 border-b-[1px] border-gray-200"
      >
        <div className={"inline-flex justify-start gap-3"}>
          <div
            className={
              "h-10 w-10 ring-1 ring-gray-400 rounded-full flex justify-center items-center"
            }
          >
            <UsersIcon className={"h-5 w-5"} />
          </div>

          <div className={"flex flex-col items-start"}>
            <p>Διάλεξε Πελάτη</p>
            <p className={"text-gray-400 text-sm"}>Ή δημιούργησε έναν</p>
          </div>
        </div>

        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    );
  }
};
