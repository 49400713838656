import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClientCard from "../ClientCard/ClientCard";
import { MagnifyingGlassIcon, UserPlusIcon } from "@heroicons/react/24/outline";

export const ClientsList = ({ open, setOpen, setAddClientOpen }) => {
  const clientsFromShop = useSelector((state) => state?.clients?.data);
  const [searchText, setTextSearch] = useState("");
  const [
    clientsFromShopWhoMatchSearchText,
    setClientsFromShopWhoMatchSearchText,
  ] = useState(clientsFromShop);

  useEffect(() => {
    if (searchText === "") {
      setClientsFromShopWhoMatchSearchText(clientsFromShop);
    }

    if (searchText?.length > 0) {
      setClientsFromShopWhoMatchSearchText(
        clientsFromShop?.filter(
          (client) =>
            client.firstName?.includes(searchText) ||
            client.lastName?.includes(searchText) ||
            client.phone?.includes(searchText) ||
            client?.email?.includes(searchText),
        ),
      );
    }
  }, [searchText]);
  return (
    <SlideOverWithCloseButtonOnOutside
      title={"Επίλεξε Πελάτη"}
      open={open}
      setOpen={setOpen}
    >
      <div className={"p-4 pb-0"}>
        <div className="relative mt-2 flex items-center">
          <div className="absolute left-1 flex items-center">
            <MagnifyingGlassIcon className={"w-4 h-4 text-gray-400"} />
          </div>
          <input
            onChange={(e) => setTextSearch(e.target.value)}
            placeholder={"Αναζήτηση Πελάτη"}
            type="text"
            name="search"
            id="search"
            className="block text-sm pl-6 w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 sm:text-sm sm:leading-6"
          />
        </div>
        <button
          onClick={() => setAddClientOpen(true)}
          className={" inline-flex items-center gap-2 text-primary py-2"}
        >
          <UserPlusIcon className={"h-5 w-5"} />

          <span className={"text-sm"}>Προσθήκη Πελάτη</span>
        </button>
      </div>

      {clientsFromShopWhoMatchSearchText?.map((client, i) => (
        <ClientCard
          key={i}
          close={() => {
            setOpen(false);
          }}
          client={client}
        />
      ))}
    </SlideOverWithCloseButtonOnOutside>
  );
};
