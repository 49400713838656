export function ColorPicker({ colorProp, setColorProp }) {
  const colors = [
    "#BBABFFC5",
    "#ffd950",
    "#03b277",
    "#6c32f5",
    "#5e7cc2",
    "#c3ddff",
  ];

  return (
    <>
      <label className="block text-sm font-medium mb-2">
        Επιλέξτε χρώμα για την υπηρεσία
      </label>
      <div className={"flex justify-evenly"}>
        {colors.map((colorInArray) => (
          <div
            key={colorInArray}
            style={{ backgroundColor: colorInArray }}
            onClick={() => {
              setColorProp(colorInArray);
            }}
            className={`p-1 h-10 w-10 block border border-gray-200 cursor-pointer rounded-full disabled:opacity-50 disabled:pointer-events-none ${colorProp === colorInArray && "shadow-xl ring-2 ring-primary"}`}
            id="hs-color-input"
          />
        ))}
      </div>
    </>
  );
}
