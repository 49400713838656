import { classNames } from "../../Utilities/utilities";
import { CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { ButtonWithLoader } from "../ButtonWithLoader/ButtonWithLoader";

export const SelectServicesStep = ({
  mainService,
  setMainService,
  onNext,
  setSelectedServices,
  selectedServices,
  goBack,
}) => {
  const services = [
    "Κομμωτική & styling",
    "Υπηρεσίες νυχιών",
    "Φρύδια & βλεφαρίδες",
    "Περιποίηση Επιδερμίδας",
    "Μακιγιάζ",
    "Barbering",
    "Επέκταση μαλλιών",
    "Μασάζ & Σπα",
    "Αποτρίχωση",
    "Τρυπήματα & τατουάζ",
  ];

  const [loading, setLoading] = useState(false);

  return (
    <div
      className={
        "flex flex-col max-w-[1100px] m-auto bg-white rounded-xl shadow p-4"
      }
    >
      <RadioGroup>
        <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
          {!mainService &&
            "Διάλεξε την βασική κατηγορία υπηρεσιών του καταστήματος σου"}
          {mainService &&
            "Διάλεξε δευτερεύουσες υπηρεσίες τις οποίες προσφέρεις "}
        </RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
          {services.map((service) => (
            <RadioGroup.Option
              key={service}
              value={service}
              onClick={() => {
                if (!mainService) {
                  setMainService(service);
                }

                if (mainService) {
                  if (selectedServices) {
                    if (service !== mainService) {
                      if (
                        selectedServices?.filter(
                          (serviceInsideArray) =>
                            service === serviceInsideArray,
                        )?.length > 0
                      ) {
                        setSelectedServices(
                          selectedServices?.filter(
                            (serviceInsideArray) =>
                              service !== serviceInsideArray,
                          ),
                        );
                      }
                      if (
                        selectedServices?.filter(
                          (serviceInsideArray) =>
                            service === serviceInsideArray,
                        )?.length === 0
                      ) {
                        setSelectedServices([...selectedServices, service]);
                      }
                    }
                    if (mainService === service) {
                      setMainService(null);
                      if (selectedServices?.[0]) {
                        setMainService(selectedServices?.[0]);
                        setSelectedServices(
                          selectedServices?.filter(
                            (serviceInsideArray) =>
                              selectedServices?.[0] !== serviceInsideArray,
                          ),
                        );
                      }
                    }
                  }
                }
              }}
              className={({ active, disabled }) =>
                classNames(
                  active && disabled
                    ? "border-indigo-600 ring-2 ring-indigo-600"
                    : "border-gray-300",
                  "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                )
              }
            >
              <>
                <span className="flex flex-1 ">
                  <span className="flex flex-col ">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900 focus:border-0 focus:ring-0"
                    >
                      {service}
                    </RadioGroup.Label>
                  </span>
                </span>
                {service !== mainService && (
                  <>
                    <CheckCircleIcon
                      className={classNames(
                        selectedServices?.filter(
                          (serviceInsideArray) =>
                            service === serviceInsideArray,
                        )?.length === 0
                          ? "invisible"
                          : " ",
                        "h-5 w-5 text-indigo-600",
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        selectedServices?.filter(
                          (serviceInsideArray) =>
                            service === serviceInsideArray,
                        )?.length !== 0
                          ? "border"
                          : "border-2",
                        selectedServices?.filter(
                          (serviceInsideArray) =>
                            service === serviceInsideArray,
                        )?.length !== 0
                          ? "border-indigo-600"
                          : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg",
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}{" "}
                {service === mainService && (
                  <>
                    <CheckBadgeIcon
                      className={classNames(
                        "text-white rounded-full h-5 w-5 bg-primary",
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        "border-2",
                        "border-indigo-600",
                        "pointer-events-none absolute -inset-px rounded-lg",
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={() => {
            goBack();
          }}
          className=" rounded-md bg-gray-400 px-3 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Πίσω
        </button>
        <ButtonWithLoader
          isLoading={loading}
          disabled={!mainService}
          text={"Επόμενο"}
          onClick={async () => {
            setLoading(true);
            await onNext();
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
};
