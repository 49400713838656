import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SelectSex from "../SelectSex/SelectSex";
import WorkersToManageAServiceSelector from "../WorkersToManageAServiceSelector/WorkersToManageAServiceSelector";
import CategoryOfTheServiceSelector from "../CategoryOfTheServiceSelector/CategoryOfTheServiceSelector";
import { addBranchService } from "../../Features/categoriesWithServices/categoriesWithServices-slice";
import { ColorPicker } from "../ColorPicker/ColorPicker";

const AddService = ({ onCancel, isPressedFromCategory }) => {
  const [serviceName, setServiceName] = useState("");
  const [duration, setDuration] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [selectedSex, setSelectedSex] = useState({
    id: 1,
    name: "Both",
  });
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(
    isPressedFromCategory ? isPressedFromCategory : {},
  );
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [idsOfSelectedWorkers, setIdsOfSelectedWorkers] = useState([]);
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("#8982e1");
  const dispatch = useDispatch();

  useEffect(() => {
    let arr = [];

    selectedWorkers?.map((worker) => {
      arr.push(worker?.id);
    });
    setIdsOfSelectedWorkers(arr);
  }, [selectedWorkers]);

  const createService = async () => {
    try {
      await dispatch(
        addBranchService({
          payload: {
            categoryId: selectedServiceCategory.id,
            serviceName: serviceName,
            totalPrice: servicePrice,
            duration: duration,
            description: description,
            discount: 0,
            forGender: selectedSex.name,
            color: color,
            employees: [...idsOfSelectedWorkers],
            serviceType: "FixedPrice",
            //StartsFrom,Until,FixedPrice
          },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form method="post" noValidate onSubmit={() => false}>
      <div className=" space-y-12 px-4">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                *Όνομα υπηρεσίας
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    setServiceName(e.target.value);
                  }}
                  value={serviceName}
                  type="text"
                  required={true}
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="peer block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="duration"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                *Διάρκεια (λεπτά)
              </label>
              <div className="mt-2">
                <input
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  pattern={` ^[1-9][0-9]*$`}
                  type="number"
                  required={true}
                  name="duration"
                  id="duration"
                  className="peer block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                  Παρακαλώ εισάγετε μια έγκυρη διάρκεια
                </span>
              </div>
            </div>
            <div className={"sm:col-span-6"}>
              <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                *Τιμή
              </label>
              <div className="relative mt-2 ">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  type="number"
                  onChange={(e) => {
                    setServicePrice(e.target.value);
                  }}
                  pattern={` ^[1-9][0-9]*$`}
                  name="price"
                  required={true}
                  id="price"
                  className="block peer w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="absolute inset-y-0 h-full right-0 flex items-center">
                  <span
                    id="currency"
                    className="h-full flex items-center rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  >
                    ΕΥΡ
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <SelectSex
                selectedSex={selectedSex}
                setSelectedSex={setSelectedSex}
              />
            </div>
            <div className="sm:col-span-6">
              <WorkersToManageAServiceSelector
                selectedWorkers={selectedWorkers}
                setSelectedWorkers={setSelectedWorkers}
              />
            </div>
            <div className="sm:col-span-6">
              <CategoryOfTheServiceSelector
                isPressedFromCategory={isPressedFromCategory}
                selectedServiceCategory={selectedServiceCategory}
                setSelectedServiceCategory={setSelectedServiceCategory}
              />
            </div>
            <div className={"sm:col-span-6 pt-1"}>
              <ColorPicker colorProp={color} setColorProp={setColor} />
            </div>
            <div className={"sm:col-span-6 pt-1"}>
              <label
                htmlFor="comment"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                *Μια σύντομη περιγραφή αυτής της υπηρεσίας
              </label>
              <div className="mt-2">
                <textarea
                  rows={2}
                  name="comment"
                  id="comment"
                  onChange={(e) => setDescription(e.target.value)}
                  className="block pl-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={description}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6 px-4">
        <button
          onClick={onCancel}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Άκυρο
        </button>
        <button
          type="button"
          disabled={
            serviceName?.length === 0 ||
            duration <= 0 ||
            servicePrice <= 0 ||
            description?.length === 0 ||
            selectedWorkers?.length === 0
          }
          onClick={() => {
            createService();
            setServiceName(null);
            setServicePrice(0);
            setDuration(0);
            onCancel();
          }}
          className={
            "rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline disabled:bg-gray-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
        >
          Αποθήκευση
        </button>
      </div>
    </form>
  );
};

export default AddService;
