import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDate } from "../../Features/calendar/calendar-slice";
import { daysEnglishToGreek } from "../../Utilities/utilities";
import { useEffect, useState } from "react";

export default function WeekNavigatorSlot({ weekDay, index }) {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const dateOfThisSlot =
    weekDay.type === "subtract"
      ? dayjs(selectedDate).subtract(weekDay.number, "d")
      : weekDay.type === "add" && dayjs(selectedDate).add(weekDay.number, "d");
  const dispatch = useDispatch();
  const typeOfView = useSelector((state) => state.typeOfView.typeOfView);
  const numberOfWorkers = useSelector(
    (state) => state.typeOfView.numberOfMultipleWorkersShown,
  );
  const [minWidth, setMinWidth] = useState(0);
  const [resized, setResized] = useState(0);

  let [hourSlotWidth, setHourSlotWidth] = useState(0);
  useEffect(() => {
    let widthOfHourSlot =
      numberOfWorkers > 1
        ? document.getElementById("dayFromMultiple")?.offsetWidth
        : document.getElementsByClassName("0")?.[0]?.offsetWidth;

    if (widthOfHourSlot) {
      setHourSlotWidth(widthOfHourSlot);
    }
  }, [typeOfView, numberOfWorkers]);

  useEffect(() => {
    if (numberOfWorkers) {
      setMinWidth(numberOfWorkers * 200);
    }
    if (!numberOfWorkers) {
      setMinWidth(200);
    }
  }, [numberOfWorkers, resized]);

  return (
    <button
      type="button"
      style={{
        width: hourSlotWidth ? hourSlotWidth : "100%",
        minWidth: `${minWidth}px`,
      }}
      onClick={() => {
        dispatch(setSelectedDate(dateOfThisSlot));
      }}
      className={`flex justify-center gap-2 items-center h-10 border-r py-1 ${index === 0 && "border-l"}`}
    >
      <span className={"text-sm font-semibold sm:text-base"}>
        {daysEnglishToGreek?.[dayjs(dateOfThisSlot).format("ddd")]}
      </span>

      <span
        className={
          (dayjs(selectedDate).isSame(dateOfThisSlot, "d")
            ? "text-white bg-primary"
            : dayjs(Date.now()).isSame(dateOfThisSlot, "d")
              ? "text-primary"
              : "text-gray-900") +
          " flex h-6 w-6 font-semibold items-center justify-center rounded-full  text-sm"
        }
      >
        {dayjs(dateOfThisSlot).format("DD")}
      </span>
    </button>
  );
}
