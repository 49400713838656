import { Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../Utilities/utilities";
import { useSelector } from "react-redux";

export default function CategoryOfTheServiceSelector({
  isPressedFromCategory,
  setSelectedServiceCategory,
  selectedServiceCategory,
}) {
  const categoriesWithItsServices = useSelector(
    (state) => state.categoriesWithServices.data,
  );

  useEffect(() => {
    if (!isPressedFromCategory) {
      setSelectedServiceCategory(categoriesWithItsServices[0]);
    }
  }, []);
  return (
    <Listbox
      value={selectedServiceCategory}
      onChange={setSelectedServiceCategory}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            Επιλεγμένη Κατηγορία
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="disabled:bg-gray-200 disabled:cursor-not-allowed relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {selectedServiceCategory?.categoryName}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {Object.keys(categoriesWithItsServices).map(
                  (categoryName, index) => (
                    <Listbox.Option
                      key={categoriesWithItsServices[index].id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-primary text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      value={categoriesWithItsServices[index]}
                    >
                      {({ selectedServiceCategory, active }) => (
                        <>
                          <span
                            className={classNames(
                              selectedServiceCategory
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate",
                            )}
                          >
                            {categoriesWithItsServices[index]?.categoryName}
                          </span>

                          {selectedServiceCategory ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ),
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
