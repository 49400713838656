import { Route, Routes } from "react-router-dom";
import Dashboard from "../../Pages/Dashboard/Dashboard";

import ProfilePage from "../../Pages/ProfilePage/ProfilePage";
import { EmployeesShiftsTable } from "../EmployeesShiftsTable/EmployeesShiftsTable";

export const EmployeeRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Dashboard />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route
        path="/shifts"
        element={
          <div className={"p-4"}>
            <EmployeesShiftsTable />
          </div>
        }
      />
    </Routes>
  );
};
