import DaySelector from "../DaySelector/DaySelector";
import RangeOfDays from "../RangeOfDays/RangeOfDays";
import WorkerSelector from "../WorkerSelector/WorkerSelector";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  fullDaysEnglishToGreek,
  monthsEnglishToGreek,
} from "../../Utilities/utilities";

const EmployeeHeaderOfViews = () => {
  return (
    <div className="md:ml-4 grid grid-cols-4  gap-2 items-center relative">
      <div className={" col-start-1 sm:col-start-2 col-span-2"}>
        <DaySelector />
      </div>
      <div className={"col-span-2 sm:col-span-1 sm:col-start-4"}>
        <RangeOfDays />
      </div>
    </div>
  );
};

const OwnerHeaderOfViews = () => {
  return (
    <div className="md:ml-4 grid grid-cols-5 sm:grid-cols-4 gap-2 items-center relative">
      <div className={" sm:col-start-1"}>
        <WorkerSelector />
      </div>

      <div className={"col-span-3 sm:col-span-2"}>
        <DaySelector />
      </div>
      <div className={" sm:col-start-4"}>
        <RangeOfDays />
      </div>
    </div>
  );
};

export default function HeaderOfViews() {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const loggedInAs = useSelector((state) => state.authorizeUser.loggedInAs);

  return (
    <header
      className="py-2 sm:pb-0 flex max-height-20
            flex-col sticky bg-white top-0 lg:top-0 w-full
             z-10 flex-none items-center justify-between
             border-b border-gray-200 px-2 h-14"
    >
      <div className={"block justify-between w-full md:flex relative"}>
        <div
          style={{ alignItems: "center" }}
          className={"hidden md:flex items-center gap-2 font-medium"}
        >
          <span className="text-sm text-gray-900">
            {fullDaysEnglishToGreek[dayjs(selectedDate).format("dddd")]}{" "}
          </span>
          <span>{dayjs(selectedDate).format("DD")}</span>
          <span className=" text-sm text-gray-900">
            {monthsEnglishToGreek?.[dayjs(selectedDate).format("MMMM")] +
              dayjs(selectedDate).format(" YYYY")}
          </span>
        </div>
        {loggedInAs === "employee" && <EmployeeHeaderOfViews />}
        {loggedInAs === "owner" && <OwnerHeaderOfViews />}
      </div>
      {/*<div className={" md:hidden flex justify-between w-full"}>*/}
      {/*  <DaySelector /> <ToggleSettingsOfCalendar />*/}
      {/*</div>*/}
    </header>
  );
}
