import DraggableServiceCard from "../../Pages/ServicesPage/components/DraggableServiceCard";
import DropDown from "../../Pages/ServicesPage/components/DropDown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { SimpleLoader } from "../SimpleLoader/SimpleLoader";
import { useSelector } from "react-redux";
import {
  areCategoriesWithServicesLoading,
  idOfCategoryOrServiceThatWillBeDeletedOrEdited,
} from "../../Features/categoriesWithServices/categoriesWithServices-slice";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${7}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const CategoryCard = ({
  setIsAddServiceShown,
  setCategoryThatPressedAddService,
  category,
  setServiceToBeEdited,
  setSelectedCategory,
  setIsAddCategoryShown,
}) => {
  const [items, setItems] = useState(category?.services);
  const isCategoryLoading = useSelector(areCategoriesWithServicesLoading);
  const idOfCategoryLoading = useSelector(
    idOfCategoryOrServiceThatWillBeDeletedOrEdited,
  );
  useEffect(() => {
    setItems(category?.services);
  }, [category]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(updatedItems);
  };

  const LoaderOrLength = () => {
    if (isCategoryLoading && idOfCategoryLoading === category?.id) {
      return <SimpleLoader />;
    } else {
      return category?.services?.length;
    }
  };

  return (
    <div className={"bg-gray-100 rounded-xl p-3"}>
      <header className={"flex justify-between mb-3"}>
        <div className={"inline-flex items-center gap-2"}>
          <h3 className={"font-semibold"}>{category?.categoryName}</h3>
          <span
            className={
              "bg-white flex justify-center items-center w-7 h-7 text-sm rounded-full"
            }
          >
            <LoaderOrLength />
          </span>
        </div>
        <div>
          <DropDown
            setIsAddCategoryShown={setIsAddCategoryShown}
            setSelectedCategory={setSelectedCategory}
            category={category}
            fromCategory
            setIsAddServiceShown={setIsAddServiceShown}
            setCategoryThatPressedAddService={setCategoryThatPressedAddService}
          />
        </div>
      </header>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item, index) => (
                <Draggable key={item?.id} draggableId={item?.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <DraggableServiceCard
                        setServiceToBeEdited={setServiceToBeEdited}
                        key={item?.id}
                        service={item}
                        category={category}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CategoryCard;
