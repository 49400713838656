import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import {
  combineManualTopWithManualDateAndReturnDateInFormatThatBackendUnderstands,
  getTotalsOfAllTheServices,
  isResponseSuccessful,
  produceError,
  roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute,
  turnPixelsIntoMinutes,
  turnServicesFromBranchIdsToEmployeeIds,
} from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getBranchOrEmployeeAppointments } from "../appointments/appointments-slice";

export const editAppointment = createAsyncThunk(
  "appointment/editAppointmentSlice",
  async (_, thunkAPI) => {
    const { shop, appointment } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfAppointmentThatIsEdited(appointment?.id));
    const res = await Request.put(
      `EmployeeAppointment/employee-appointment/${appointment?.appointmentToEdit?.id}/${shop?.id}`,
      {
        body: {
          userId: appointment?.selectedClient?.id,
          employeeId: appointment?.selectedWorker?.id,
          serviceId: [
            ...getTotalsOfAllTheServices(appointment.selectedService)?.idsOfAll,
          ],
          startTime: appointment?.appointmentToEdit?.bookedDate,
          duration: getTotalsOfAllTheServices(appointment.selectedService)
            ?.durationOfAll,
          bookingId: appointment.appointmentToEdit?.id,
          additionalInformation: appointment?.extraData,
        },
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));

      produceError(res);
    }
  },
);
export const editAppointmentAfterDragAndDrop = createAsyncThunk(
  "appointment/editAppointmentAfterDragAndDrop",
  async (
    {
      manualTop,
      manualDate,
      manualWorker,
      appointment,
      heightOfAppointmentToDetermineTheDuration,
    },
    thunkAPI,
  ) => {
    const { shop, employees } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfAppointmentThatIsEdited(appointment?.id));
    const res = await Request.put(
      `EmployeeAppointment/employee-appointment/${appointment?.id}/${shop?.id}`,
      {
        body: {
          userId: appointment.userId,
          duration: turnPixelsIntoMinutes(
            heightOfAppointmentToDetermineTheDuration,
          ),
          startTime:
            combineManualTopWithManualDateAndReturnDateInFormatThatBackendUnderstands(
              manualDate,
              roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(
                manualTop,
              ),
            ),
          employeeId: manualWorker?.id,
          serviceId: [
            ...getTotalsOfAllTheServices(
              turnServicesFromBranchIdsToEmployeeIds(
                appointment,
                employees?.data,
              )?.services,
            )?.idsOfAll,
          ],
        },
      },
    );

    thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const editAppointmentAfterResizing = createAsyncThunk(
  "appointment/editAppointmentAfterResizing",
  async (
    {
      manualTop,
      manualDate,
      manualWorker,
      appointment,
      heightOfAppointmentToDetermineTheDuration,
    },
    thunkAPI,
  ) => {
    const { shop, employees } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfAppointmentThatIsEdited(appointment?.id));

    const res = await Request.put(
      `EmployeeAppointment/employee-appointment/${appointment?.id}/${shop?.id}`,
      {
        body: {
          userId: appointment?.userId,
          employeeId: manualWorker,
          serviceId: [
            ...getTotalsOfAllTheServices(
              turnServicesFromBranchIdsToEmployeeIds(
                appointment,
                employees?.data,
              ).services,
            )?.idsOfAll,
          ],
          startTime:
            combineManualTopWithManualDateAndReturnDateInFormatThatBackendUnderstands(
              manualDate,
              roundPixelsToTheNearestPixelWhichWillProduceAThirtyMinute(
                manualTop,
              ),
            ),
          duration: turnPixelsIntoMinutes(
            heightOfAppointmentToDetermineTheDuration,
          ),
        },
      },
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));

      produceError(res);
    }
  },
);

export const cancelAppointment = createAsyncThunk(
  "appointment/cancelAppointment",
  async (_, thunkAPI) => {
    const { shop, appointment } = thunkAPI.getState();

    const res = await Request.remove(
      `EmployeeAppointment/cancel-appointment/${appointment?.appointmentToEdit?.id}/${shop?.id}`,
    );

    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));

      produceError(res);
    }
  },
);

export const changeAppointmentStatues = createAsyncThunk(
  "appointment/changeAppointmentStatues",
  async ({ status }, thunkAPI) => {
    const { shop, appointment } = thunkAPI.getState();

    const res = await Request.put(
      `EmployeeAppointment/employee-appointment-status/${appointment?.appointmentToEdit?.id}/${status}/${shop?.id}`,
    );

    if (isResponseSuccessful(res)) {
      toast.success("Άλλαξε με επιτυχία");
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      toast.error(res?.message || `Κάτι πήγε στραβά - ${res?.code}`);
      thunkAPI.dispatch(getBranchOrEmployeeAppointments({ id: shop.id }));

      produceError(res);
    }
  },
);

const initialState = {
  isLoading: false,
  idOfAppointmentThatIsEdited: null,
};
const editAppointmentSlice = createSlice({
  name: "EditAppointment",
  initialState,
  reducers: {
    setIdOfAppointmentThatIsEdited(state, action) {
      state.idOfAppointmentThatIsEdited = action.payload;
    },
    clearIdOfAppointmentThatIsEdited(state, action) {
      state.idOfAppointmentThatIsEdited = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editAppointment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editAppointmentAfterDragAndDrop.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editAppointmentAfterResizing.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(cancelAppointment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.success("Το ραντεβού άλλαξε επιτυχώς");
      })
      .addCase(editAppointmentAfterResizing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.success("Το ραντεβού άλλαξε επιτυχώς");
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.success("Το ραντεβού ακυρώθηκε επιτυχώς");
      })
      .addCase(editAppointmentAfterDragAndDrop.fulfilled, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.success("Το ραντεβού άλλαξε επιτυχώς");
      })
      .addCase(editAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.error(action.error.message);
      })
      .addCase(cancelAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.error(action.error.message);
      })
      .addCase(editAppointmentAfterResizing.rejected, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.error(action.error.message);
      })
      .addCase(editAppointmentAfterDragAndDrop.rejected, (state, action) => {
        state.isLoading = false;
        state.idOfAppointmentThatIsEdited = null;
        toast.error(action.error.message);
      });
  },
});

export const { setIdOfAppointmentThatIsEdited } = editAppointmentSlice.actions;

export default editAppointmentSlice.reducer;
