import DayAndHour from "../DayAndHour/DayAndHour";
import { days } from "../../Utilities/utilities";

export const AllWeekShiftsCreator = ({
  isFromEmployees,
  allWeekShiftsArray,
  setAllWeekShiftsArray,
}) => {
  return (
    <div
      className={`overflow-auto flex w-full flex-col ${isFromEmployees ? "mt-2" : "mt-10"} mb-10`}
    >
      {days.map((day, index) => (
        <DayAndHour
          isFromEmployees={isFromEmployees}
          key={index}
          setAllWeekShiftsArray={setAllWeekShiftsArray}
          allWeekShiftsArray={allWeekShiftsArray}
          day={day}
        />
      ))}
    </div>
  );
};
