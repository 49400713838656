import {
  classNames,
  getTotalsOfAllTheServices,
} from "../../Utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedService } from "../../Features/appointmentToBeBooked/appointmentToBeBooked-slice";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

export default function ServiceCard({
  openSelectServiceFromList,
  service,
  onEdit,
}) {
  const dispatch = useDispatch();
  const selectedServicesForAppointment = useSelector(
    (state) => state.appointment.selectedService,
  );

  const doesServiceExistInSelectedServicesArray = () => {
    return (
      selectedServicesForAppointment?.filter(
        (selectedService) =>
          selectedService?.branchServiceId === service?.branchServiceId,
      )?.length > 0
    );
  };

  const removeOrAddServiceInArray = () => {
    //if card is inside list, and not standalone
    if (!openSelectServiceFromList) {
      //if selected service is empty
      if (!selectedServicesForAppointment) {
        return dispatch(setSelectedService([service]));
      }

      if (selectedServicesForAppointment) {
        //if service is selected, so we remove it
        if (
          selectedServicesForAppointment?.filter(
            (servicesFromRedux) =>
              servicesFromRedux.branchServiceId === service.branchServiceId,
          )?.length > 0
        ) {
          return dispatch(
            setSelectedService([
              ...selectedServicesForAppointment?.filter(
                (servicesFromRedux) =>
                  servicesFromRedux.branchServiceId !== service.branchServiceId,
              ),
            ]),
          );
        }
        //if service is not selected , so we added it
        if (
          selectedServicesForAppointment?.filter(
            (servicesFromRedux) =>
              servicesFromRedux.branchServiceId === service.branchServiceId,
          )?.length === 0
        ) {
          return dispatch(
            setSelectedService([...selectedServicesForAppointment, service]),
          );
        }
      }
    }
    if (openSelectServiceFromList) {
      openSelectServiceFromList();
    }
  };

  return (
    <div
      onClick={() => removeOrAddServiceInArray()}
      key={service.name}
      className={classNames(
        "border-gray-300 relative",
        doesServiceExistInSelectedServicesArray() && !openSelectServiceFromList
          ? "border-indigo-600 border"
          : "border",
        "relative block cursor-pointer rounded-lg  bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between",
      )}
    >
      <>
        <span className="flex items-center">
          <span className="flex flex-col text-sm">
            <span className="font-medium text-gray-900">{service.name}</span>
            <span className="text-gray-500">
              <span className="block sm:inline">
                {service.duration}
                min.
              </span>
              <span
                className="hidden sm:mx-1 sm:inline"
                aria-hidden="true"
              ></span>
            </span>
          </span>
        </span>
        <span className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
          <span className="font-medium text-gray-900">
            {service?.finalPrice}€
          </span>
        </span>
        <span
          className={classNames(
            selectedServicesForAppointment?.id === service?.id
              ? "border"
              : "border-2",
            "border-transparent",
            "pointer-events-none absolute -inset-px rounded-lg",
          )}
          aria-hidden="true"
        />
      </>
      {onEdit && (
        <EllipsisVerticalIcon
          onClick={() => {
            onEdit();
          }}
          className={
            "w-5 h-5 absolute right-1 cursor-pointer top-0 bottom-0 mt-auto mb-auto"
          }
        />
      )}
    </div>
  );
}
