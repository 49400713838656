import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import {
  fullDaysEnglishToGreek,
  getTotalsOfAllTheServices,
  monthsEnglishToGreekInOu,
} from "../../../Utilities/utilities";
import dayjs from "dayjs";

const SelectedDateTime = ({
  selectedTimeForAppointment,
  selectedServiceForAppointment,
}) => {
  //dayjs doesn't always return it to greek, although we have extended locale/el
  let dayGreek = fullDaysEnglishToGreek[
    dayjs(selectedTimeForAppointment).format("dddd")
  ]
    ? fullDaysEnglishToGreek[dayjs(selectedTimeForAppointment).format("dddd")]
    : dayjs(selectedTimeForAppointment).format("dddd");
  let monthGreek = monthsEnglishToGreekInOu[
    dayjs(selectedTimeForAppointment).format("MMMM")
  ]
    ? monthsEnglishToGreekInOu[dayjs(selectedTimeForAppointment).format("MMMM")]
    : dayjs(selectedTimeForAppointment).format("MMMM");
  return (
    <div className={"p-4 border-b-[1px] grid gap-1 border-gray-200"}>
      <div className={"flex items-center gap-2"}>
        <CalendarIcon className={"text-gray-700 h-5 w-5"} />
        <p className={"text-sm text-gray-900"}>
          {dayGreek +
            " " +
            dayjs(selectedTimeForAppointment).format(", DD ") +
            monthGreek +
            dayjs(selectedTimeForAppointment).format(" YYYY")}
        </p>
      </div>
      <div className={"flex items-center gap-2"}>
        <ClockIcon className={"text-gray-700 h-5 w-5"} />
        <p className={"text-sm text-gray-900"}>
          {dayjs(selectedTimeForAppointment).format("HH:mm")}
          {selectedServiceForAppointment
            ? ` - ${dayjs(selectedTimeForAppointment)
                .add(
                  getTotalsOfAllTheServices(selectedServiceForAppointment)
                    .durationOfAll,
                  "m",
                )
                .format("HH:mm")}`
            : " - Επιλέξτε υπηρεσία"}
        </p>
      </div>
    </div>
  );
};

export default SelectedDateTime;
