import React, { useRef, useState } from "react";
import LeftPartShowingHours from "../LeftPartShowingHours/LeftPartShowingHours";
import WeekConstructor from "../WeekConstructor/WeekConstructor";
import WeekNavigator from "../WeekNavigator/WeekNavigator";
import HeaderOfViews from "../HeaderOfViews/HeaderOfViews";

export default function WeeklyView() {
  const containerNav = useRef(null);
  const divRef = useRef(null);

  const [scrolledLeft, setScrolledLeft] = useState(0);

  const handleScroll = () => {
    if (divRef.current) {
      const scrollLeft = divRef.current.scrollLeft;
      setScrolledLeft(scrollLeft);
    }
  };
  return (
    <div style={{ maxWidth: "100%" }} className="flex h-full flex-col">
      <HeaderOfViews />
      <WeekNavigator
        scrolledLeft={scrolledLeft}
        typeOfNavigator={"week"}
        containerNav={containerNav}
      />
      <div
        onScroll={handleScroll}
        ref={divRef}
        className="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style={{
            width: "100%",

            maxWidth: "100%",
          }}
          className="flex flex-none flex-col "
        >
          <div className={"flex flex-auto"}>
            <LeftPartShowingHours />
            <WeekConstructor />
          </div>
        </div>
      </div>
    </div>
  );
}
