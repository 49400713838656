import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ClockIcon,
  EllipsisHorizontalIcon,
  UserIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { setEmployeeToBeEdited } from "../../../Features/employees/employees-slice";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../Utilities/utilities";

const WorkerMenuDropDown = ({ employee, setWorkerToBeDeleted }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = () => {
    navigate(`/employee-profile/${employee?.id}`);
    dispatch(setEmployeeToBeEdited(employee));
  };

  const shifts = () => {
    navigate(`/employee-profile/${employee?.id}`, {
      state: { shiftTabs: true },
    });
    dispatch(setEmployeeToBeEdited(employee));
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <EllipsisHorizontalIcon className={"w-8 h-8"} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={profile}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer",
                  )}
                >
                  <UserIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Προφίλ
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={shifts}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer",
                  )}
                >
                  <ClockIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Ωράριο εργασίας
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => setWorkerToBeDeleted(employee)}
                  className={classNames(
                    active ? "bg-red-100 text-red-900" : "text-red-500",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer",
                  )}
                >
                  <UserMinusIcon
                    className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                    aria-hidden="true"
                  />
                  Απόλυση
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default WorkerMenuDropDown;
