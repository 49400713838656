/* eslint-disable no-use-before-define */
// eslint-disable-next-line import/no-unresolved
// import AsyncStorage from "@react-native-async-storage/async-storage";
import authHeader from "./authHeader";
import Logger from "./Logger";
import { apiHost, apiVersion } from "./constants";
import { isJwtTokenValid } from "./isJwtTokenValid";

const _apiHost = apiHost + apiVersion;
const httpRequestLoggingEnabled = true;

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

const getUserId = async () => {
  // const tokens = await AsyncStorage.getItem("tokens");
  // if (!tokens) {
  //   return "";
  // }
  // const id_Token = parseJwt(JSON.parse(tokens).id_Token);
  // return id_Token.sub;
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const generateErrorResponse = (error) => {
  try {
    return error.text().then((text) => {
      const { Message, message, errorCode, code, innerError } = text
        ? JSON?.parse(text)
        : "";
      return {
        status: "error",
        code: error.status,
        title: code || errorCode,
        message: message || Message,
        innerError: innerError,
      };
    });
  } catch (e) {
    return {
      status: "error",
      code: "exception",
      message: e,
    };
  }
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {String} The Response text
 */
const handleResponse = async (response, log = null) => {
  if (!response.ok) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      // global.window.location.href = window.location.hostname;
      // global.window.location.href = "";
    } else if (response.status === 500) {
      // clear cookie;
      // global.window.location.href = window.location.hostname;
    }
    const errorResult = await generateErrorResponse(response);
    log?.error(errorResult, response.status + " - REQUEST FAILED.");
    return errorResult;
  }

  return response.text().then((text) => {
    log?.success(
      text ? JSON?.parse(text) : "",
      response.status + " - REQUEST SUCCESSES.",
    );
    return text ? JSON?.parse(text) : "No response text";
  });
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {Blob|null} The blob response
 */
const handleBlobResponse = async (response, log = null) => {
  if (!response.ok) {
    log?.error(response.status + " - REQUEST FAILED.");
    if (response.status === 401) {
    } else if (response.status === 500) {
    }
    const error = response;
    return null;
  }
  return response.blob().then((blob) => {
    log?.success("BLOB (binary large object) response");
    return blob;
  });
};
const request = async (url, params, method = "GET", isFormData = false) => {
  const log = httpRequestLoggingEnabled ? new Logger({ logUuid: true }) : null;
  log?.request(params, method + " " + _apiHost + url);
  const options = {
    method,
    headers: await authHeader(),
  };
  if (params) {
    if (params.header) {
      options.headers = params.header;
    }
    if (method === "GET" && params.body) {
      url += "?" + objectToQueryString(params.body);
    }
    if (method !== "GET" && params.body && !isFormData) {
      options.body = JSON.stringify(params.body);
    }
    if (method !== "GET" && params.body && isFormData) {
      options.body = params.body;
    }
  }

  const response = await fetch(_apiHost + url, options);
  if (params?.isBlob) {
    return handleBlobResponse(response, log);
  }
  return handleResponse(response, log);
};

const requestRedirectUri = async (url, params, method = "POST") => {
  const options = {
    method,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  options.body = params.body;

  const response = await fetch(_apiHost + url, options);

  return handleResponse(response);
};

const refreshToken = async () => {
  /** Write your project's refresh token flow  **/
};

const get = async (url, params) => {
  if (isJwtTokenValid()) {
    await refreshToken();
  }
  return request(url, params);
};

const post = async (url, params) => {
  if (isJwtTokenValid()) {
    await refreshToken();
  }
  return request(url, params, "POST");
};

const put = async (url, params) => {
  return request(url, params, "PUT");
};

const patch = async (url, params) => {
  return request(url, params, "PATCH");
};

const remove = async (url, params) => {
  if (isJwtTokenValid()) {
    await refreshToken();
  }
  return request(url, params, "DELETE");
};

const uploadFormData = async (url, params) => {
  if (isJwtTokenValid()) {
    await refreshToken();
  }
  return request(url, params, "POST", true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadFormData,
  requestRedirectUri,
  get,
  post,
  put,
  patch,
  remove,
  getUserId,
  refreshToken,
};
