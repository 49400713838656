import { useDispatch } from "react-redux";
import { acceptInvitation } from "../../Features/authorizeUser/authorizeUser-slice";
import { useNavigate } from "react-router-dom";

export const EmployeeAcceptInvitationPage = ({ setIsAcceptingInvitation }) => {
  const { pathname } = location;

  const invitationCode = pathname?.replace("/register-employee/", "");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const registerEmployee = async () => {
    const res = await dispatch(acceptInvitation({ data: { invitationCode } }));
    if (!res?.error) {
      navigate("/");
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Γεια σου 👋
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <button
                onClick={() => registerEmployee()}
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Αποδοχή πρόσκλησης🥳
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
