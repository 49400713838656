import {
  BanknotesIcon,
  ClockIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import DropDown from "./DropDown";
import { useSelector } from "react-redux";
import {
  areCategoriesWithServicesLoading,
  idOfCategoryOrServiceThatWillBeDeletedOrEdited,
} from "../../../Features/categoriesWithServices/categoriesWithServices-slice";
import { SimpleLoader } from "../../../Components/SimpleLoader/SimpleLoader";

const DraggableServiceCard = ({ service, setServiceToBeEdited, category }) => {
  const isServiceLoading = useSelector(areCategoriesWithServicesLoading);
  const idOfServiceLoading = useSelector(
    idOfCategoryOrServiceThatWillBeDeletedOrEdited,
  );
  const Loader = () => {
    if (isServiceLoading && idOfServiceLoading === service?.id) {
      return (
        <div className={"ml-1"}>
          <SimpleLoader />
        </div>
      );
    }
  };

  return (
    <div
      className={
        "border-[1px] border-gray-100 bg-white flex flex-row shadow-sm rounded-xl p-2 justify-between hover:shadow-xl cursor-pointer"
      }
    >
      <div>
        <div>
          <div className={"inline-flex items-center gap-1"}>
            <div
              style={{
                backgroundColor: service?.color,
              }}
              aria-hidden="true"
              className={
                "h-3 w-3 rounded-full border border-black border-opacity-10"
              }
            />
            <h3
              className={
                "font-semibold flex items-center leading-3 text-xs text-ellipsis overflow-hidden"
              }
            >
              {service?.serviceName}
              <Loader />
            </h3>
          </div>
        </div>

        <span
          className={"text-gray-700 text-[12px] text-ellipsis overflow-hidden"}
        >
          {service?.description}
        </span>
        <div className={"flex gap-2"}>
          <div className={"inline-flex items-center gap-1"}>
            <ClockIcon className={"w-3 h-3"} />
            <span className={"text-gray-700 text-[12px]"}>
              {service?.duration + "'"}
            </span>
          </div>
          <div className={"inline-flex items-center gap-1"}>
            <UserIcon className={"w-3 h-3"} />
            <span className={"text-gray-700 text-[12px]"}>
              {service?.gender === 1
                ? "Άνδρας"
                : service?.gender === 2
                  ? "Γυναίκα"
                  : "Άνδρας/Γυναίκα"}
            </span>
          </div>
          <div className={"inline-flex gap-1 items-center"}>
            <BanknotesIcon className={"w-3 h-3"} />
            <span className={"text-xs font-semibold"}>
              {service?.finalPrice}€
            </span>
          </div>
        </div>
      </div>
      <div>
        <DropDown
          service={service}
          setServiceToBeEdited={setServiceToBeEdited}
          category={category}
        />
      </div>
    </div>
  );
};
export default DraggableServiceCard;
