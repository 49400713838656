import { AllWeekShiftsCreator } from "../AllWeekShiftsCreator/AllWeekShiftsCreator";

export const EmployeeShiftsOfWeekTable = ({
  allWeekShiftsArray,
  setAllWeekShiftsArray,
}) => {
  return (
    <div className="text-base  leading-7 text-gray-600">
      <AllWeekShiftsCreator
        isFromEmployees
        allWeekShiftsArray={allWeekShiftsArray}
        setAllWeekShiftsArray={setAllWeekShiftsArray}
      />
    </div>
  );
};
