import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  addBranchCategory,
  editBranchCategory,
} from "../../Features/categoriesWithServices/categoriesWithServices-slice";
import { toast } from "react-toastify";

const AddServiceCategory = ({
  onCancel,
  selectedCategory,
  setSelectedCategory,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCategory) {
      setCategoryName(selectedCategory?.categoryName);
    }
  }, []);

  const createCategory = async () => {
    try {
      const { payload } = await dispatch(addBranchCategory({ categoryName }));
      if (payload?.status === "error") {
        toast.error(
          payload?.message ||
            "Η κατηγορία δεν δημιουργήθηκε, παρακαλώ δοκιμάστε ξανά",
        );
      }
      setCategoryName("");
      onCancel();
    } catch (e) {
      console.log(e);
    }
  };

  const editCategory = async () => {
    try {
      const { payload } = await dispatch(
        editBranchCategory({ categoryName, categoryId: selectedCategory?.id }),
      );
      if (payload?.status === "error") {
        toast.error(
          payload?.message || "Υπήρχε κάποιο σφάλμα, παρακαλώ δοκιμάστε ξανά",
        );
      }
      toast.success("Η κατηγορία άλλαξε με επιτυχία");
      setSelectedCategory(null);
      setCategoryName("");
      onCancel();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="space-y-12 px-4">
        <div className="border-b border-gray-900/10 pb-12 pt-5">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            *Όνομα κατηγορίας
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
              value={categoryName}
              type="text"
              required={true}
              name="category_name"
              id="category_name"
              className="peer block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 flex items-center justify-end gap-x-6">
        <button
          onClick={onCancel}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Ακύρωση
        </button>
        <button
          type="button"
          disabled={categoryName?.length === 0}
          onClick={selectedCategory ? editCategory : createCategory}
          className={
            "rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline disabled:bg-gray-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
        >
          Αποθήκευση
        </button>
      </div>
    </>
  );
};

export default AddServiceCategory;
