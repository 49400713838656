import Logger from "./Logger";
// import AsyncStorage from "@react-native-async-storage/async-storage";

const authHeader = () => {
  try {
    // return authorization header with jwt token
    let token = localStorage.getItem("accessToken");
    token = token ? token : null;
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    }
    return { "Content-Type": "application/json" };
  } catch (e) {
    return { "Content-Type": "application/json" };
  }
};

export default authHeader;
