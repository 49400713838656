import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { useEffect, useState } from "react";
import AddServiceCategory from "../AddServiceCategory/AddServiceCategory";

export const AddCategorySlideOver = ({
  isAddCategoryShown,
  onCancel,
  selectedCategory,
  setSelectedCategory,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAddCategoryShown && open) {
      setOpen(false);
    }

    if (isAddCategoryShown && !open) {
      setOpen(true);
    }
  }, [isAddCategoryShown]);

  useEffect(() => {
    if (!open) {
      onCancel();
    }
  }, [open]);

  return (
    <SlideOverWithCloseButtonOnOutside
      open={open}
      setOpen={setOpen}
      title={"Προσθήκη κατηγορίας"}
    >
      <AddServiceCategory
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        onCancel={() => {
          onCancel();
        }}
      />
    </SlideOverWithCloseButtonOnOutside>
  );
};
