import { useSelector } from "react-redux";
import CalendarRangePicker from "./CalendarRangePicker";
import dayjs from "dayjs";

const Nav = () => {
  const personalInfo = useSelector((state) => state.personalData);
  const currentDate = dayjs().locale("el");
  const formattedDate = currentDate.format("dddd D MMMM", { locale: "el" });
  return (
    <>
      <h2 className="text-3xl font-bold tracking-tight">
        Γεία σου {personalInfo?.firstName} 👋
      </h2>
      <span className="text-xl font-semibold tracking-tight">
        {formattedDate}
      </span>
      <div className="flex items-center space-x-2 w-full md:w-72 border p-2 relative rounded-xl bg-primary shadow">
        <CalendarRangePicker />
      </div>
    </>
  );
};

export default Nav;
