import { validateEmail } from "../../Utilities/validateEmail";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SlideOverWithCloseButtonOnOutside from "../SlideOverWithCloseButtonOnOutside/SlideOverWithCloseButtonOnOutside";
import { inviteEmployee } from "../../Features/inviteEmployee/InviteEmployee-slice";

export const InviteEmployee = ({ onCancel, open, setOpen }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  return (
    <SlideOverWithCloseButtonOnOutside
      title={"Πρόσκληση Υπαλλήλου"}
      open={open}
      setOpen={setOpen}
    >
      <div className={"px-4"}>
        <div className="mt-2 ">
          <div className="sm:col-span-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required={true}
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="peer block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                Παρακαλώ γράψε ένα σωστό email
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6 px-4">
        <button
          onClick={onCancel}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Άκυρο
        </button>
        <button
          type="button"
          disabled={!validateEmail(email) || !email}
          onClick={() => {
            dispatch(inviteEmployee({ email: email }));
            setOpen(false);
          }}
          className={
            "rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline disabled:bg-gray-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
        >
          Πρόσκληση 🚀
        </button>
      </div>
    </SlideOverWithCloseButtonOnOutside>
  );
};
