import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../Utilities/utilities";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export const PlacesAutoComplete = ({ setSelected, setShopAddress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelectPlace = async (address) => {
    clearSuggestions();
    const results = await getGeocode({
      placeId: address.place_id,
      language: "el",
    });

    results[0].address_components.map((item) => {
      if (item?.types?.includes("postal_code")) {
        setShopAddress((prev) => ({ ...prev, zipCode: item.long_name }));
      }
      if (item?.types?.includes("country")) {
        setShopAddress((prev) => ({ ...prev, country: item.long_name }));
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setShopAddress((prev) => ({ ...prev, city: item.long_name }));
      }
      if (item?.types?.includes("locality")) {
        setShopAddress((prev) => ({ ...prev, region: item.long_name }));
      }
      if (item?.types?.includes("route")) {
        setShopAddress((prev) => ({ ...prev, address: item.long_name }));
      }
      if (item?.types?.includes("street_number")) {
        setShopAddress((prev) => ({ ...prev, number: item.long_name }));
      }
    });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  return (
    <Combobox disabled={!ready} as="div" value={value}>
      <Combobox.Label>Βρείτε το μαγαζί σας στο google maps</Combobox.Label>
      <div className="relative ">
        <Combobox.Input
          onChange={(e) => setValue(e.target.value)}
          className="transition-all flex items-center h-10 border mt-1 rounded px-4 pl-8 w-full bg-gray-50"
          // placeholder={"Όνομα μαγαζιού ή διεύθυνση"}
        />
        <MagnifyingGlassIcon className={"w-5 h-5 left-2 absolute top-2"} />
        <Combobox.Options className="absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {status === "OK" &&
            data.map((place) => (
              <Combobox.Option
                key={place.place_id}
                value={place}
                onClick={async () => {
                  await handleSelectPlace(place);
                }}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-primary text-white" : "text-gray-900",
                  )
                }
              >
                {place.description}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
