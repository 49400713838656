import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { turnSingleShiftBackendToHaveLocalTimeNotUtc } from "../Utilities/utilities";

export const DayConstructor = (selectedDate, index, indexOfWorker) => {
  const [shiftsOfEmployee, setShiftsOfEmployee] = useState(null);
  const selectedDateWithIndexAdded = dayjs(selectedDate).add(index, "d");
  const indexOfDayToCompareWithShifts = dayjs(
    selectedDateWithIndexAdded,
  ).format("d");
  const workerSelectedOnFirstView = useSelector(
    (state) => state.typeOfView?.firstViewWorker,
  );
  const workerSelectedOnSecondView = useSelector(
    (state) => state.typeOfView?.secondViewWorker,
  );
  const workerSelectedOnWeekView = useSelector(
    (state) => state.typeOfView?.selectedWorkerForWeekView,
  );
  const workerSelectedOnThirdView = useSelector(
    (state) => state.typeOfView?.thirdViewWorker,
  );
  const [firstShiftStartInNumber, setFirstShiftStartInNumber] = useState(0);
  const [firstShiftEndInNumber, setFirstShiftEndInNumber] = useState(0);
  const [secondShiftStartInNumber, setSecondShiftStartInNumber] = useState(0);
  const [secondShiftEndInNumber, setSecondShiftEndInNumber] = useState(0);
  const employees = useSelector((state) => state.employees?.data);

  const isHourBeforeNow = (hour) => {
    if (parseInt(hour) < parseInt(dayjs(Date.now()).format("HH"))) {
      return true;
    }

    if (parseInt(hour) === parseInt(dayjs(Date.now()).format("HH"))) {
    }
  };

  const employeeWorksOnFirstShift = shiftsOfEmployee?.firstShiftEnd !== "00:00";
  const employeeWorksOnSecondShift =
    shiftsOfEmployee?.secondShiftEnd !== "00:00";

  const currentTimeInNumber =
    parseInt(dayjs(Date.now()).format("HH")) +
    dayjs(Date.now()).format("mm") / 100;

  useEffect(() => {
    if (indexOfWorker === 0 || !indexOfWorker) {
      const dayWithShifts = workerSelectedOnFirstView?.shifts?.filter(
        (day) => day.day === parseInt(indexOfDayToCompareWithShifts),
      )?.[0];

      setShiftsOfEmployee(
        turnSingleShiftBackendToHaveLocalTimeNotUtc(dayWithShifts),
      );
    }
    if (indexOfWorker === 1) {
      const dayWithShifts = workerSelectedOnSecondView?.shifts?.filter(
        (day) => day.day === parseInt(indexOfDayToCompareWithShifts),
      )?.[0];

      setShiftsOfEmployee(
        turnSingleShiftBackendToHaveLocalTimeNotUtc(dayWithShifts),
      );
    }
    if (indexOfWorker === 2) {
      const dayWithShifts = workerSelectedOnThirdView?.shifts?.filter(
        (day) => day.day === parseInt(indexOfDayToCompareWithShifts),
      )?.[0];
      setShiftsOfEmployee(
        turnSingleShiftBackendToHaveLocalTimeNotUtc(dayWithShifts),
      );
    }
  }, [
    employees,
    index,
    workerSelectedOnThirdView,
    workerSelectedOnFirstView,
    workerSelectedOnSecondView,
    selectedDate,
    workerSelectedOnWeekView,
  ]);

  useEffect(() => {
    if (shiftsOfEmployee) {
      //here lets say if shift starts at 08:30 we will turn it to 8.30 so we can make comparison between hours easier
      setFirstShiftStartInNumber(
        parseInt(shiftsOfEmployee?.firstShiftStart?.split(":")?.[0]) +
          parseInt(shiftsOfEmployee?.firstShiftStart?.split(":")?.[1]) / 100,
      );
      setFirstShiftEndInNumber(
        parseInt(shiftsOfEmployee?.firstShiftEnd?.split(":")?.[0]) +
          parseInt(shiftsOfEmployee?.firstShiftEnd?.split(":")?.[1]) / 100,
      );
      setSecondShiftStartInNumber(
        parseInt(shiftsOfEmployee?.secondShiftStart?.split(":")?.[0]) +
          parseInt(shiftsOfEmployee?.secondShiftStart?.split(":")?.[1]) / 100,
      );
      setSecondShiftEndInNumber(
        parseInt(shiftsOfEmployee?.secondShiftEnd?.split(":")?.[0]) +
          parseInt(shiftsOfEmployee?.secondShiftEnd?.split(":")?.[1]) / 100,
      );
    }
  }, [shiftsOfEmployee]);

  let dayToReturn = {
    0: {
      hasInactiveSlot: true,
    }, // if hasInactive===true then from:00 , to:38 ,hasInactiveSlot:true
    1: { hasInactiveSlot: true },
    2: { hasInactiveSlot: true },
    3: { hasInactiveSlot: true },
    4: { hasInactiveSlot: true },
    5: { hasInactiveSlot: true },
    6: { hasInactiveSlot: true },
    7: { hasInactiveSlot: true },
    8: { hasInactiveSlot: true },
    9: { hasInactiveSlot: true },
    10: { hasInactiveSlot: true },
    11: { hasInactiveSlot: true },
    12: { hasInactiveSlot: true },
    13: { hasInactiveSlot: true },
    14: { hasInactiveSlot: true },
    15: { hasInactiveSlot: true },
    16: { hasInactiveSlot: true },
    17: { hasInactiveSlot: true },
    18: { hasInactiveSlot: true },
    19: { hasInactiveSlot: true },
    20: { hasInactiveSlot: true },
    21: { hasInactiveSlot: true },
    22: { hasInactiveSlot: true },
    23: { hasInactiveSlot: true },
  };

  //if day is today or after today
  if (!dayjs(selectedDateWithIndexAdded).isBefore(dayjs(Date.now()), "day")) {
    //if day is before today

    if (employeeWorksOnFirstShift || employeeWorksOnSecondShift) {
      Object.keys(dayToReturn).map((hourInDay) => {
        const hourInDayInNumber = parseInt(hourInDay);
        //hourInDay is inside first shift

        if (employeeWorksOnFirstShift) {
          if (
            (hourInDayInNumber === Math.floor(firstShiftStartInNumber) ||
              hourInDayInNumber > Math.floor(firstShiftStartInNumber)) &&
            (hourInDayInNumber === Math.floor(firstShiftEndInNumber) ||
              hourInDayInNumber < Math.floor(firstShiftEndInNumber))
          ) {
            if (
              (Math.floor(currentTimeInNumber) < hourInDayInNumber &&
                !dayjs(selectedDateWithIndexAdded).isBefore(
                  dayjs(Date.now()),
                  "day",
                )) ||
              dayjs(selectedDateWithIndexAdded).isAfter(
                dayjs(Date.now()),
                "day",
              )
            ) {
              //if shift starts at 7:30 and hourInDay is 7
              if (hourInDayInNumber === Math.floor(firstShiftStartInNumber)) {
                if (firstShiftStartInNumber - hourInDayInNumber !== 0) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,
                    endMinuteOfInactiveSlot:
                      firstShiftStartInNumber * 100 - hourInDayInNumber * 100,
                    hasInactiveSlot: true,
                  });
                }
                if (
                  Math.round(firstShiftStartInNumber - hourInDayInNumber) === 0
                ) {
                  return (dayToReturn[hourInDay] = {
                    hasInactiveSlot: false,
                  });
                }
              }

              if (
                hourInDayInNumber > Math.floor(firstShiftStartInNumber) &&
                hourInDayInNumber < Math.floor(firstShiftEndInNumber)
              ) {
                return (dayToReturn[hourInDay] = {
                  hasInactiveSlot: false,
                });
              }

              if (hourInDayInNumber === Math.floor(firstShiftEndInNumber)) {
                return (dayToReturn[hourInDay] = {
                  startMinuteOfInactiveSlot: Math.round(
                    (firstShiftEndInNumber - hourInDayInNumber) * 100,
                  ),
                  endMinuteOfInactiveSlot: 59,
                  hasInactiveSlot: true,
                });
              }
            }

            if (Math.floor(currentTimeInNumber) >= hourInDayInNumber) {
              //if current time is bigger than the one in the map
              if (Math.floor(currentTimeInNumber) > hourInDayInNumber) {
                return (dayToReturn[hourInDay] = {
                  startMinuteOfInactiveSlot: 0,
                  endMinuteOfInactiveSlot: 59,
                  hasInactiveSlot: true,
                });
              }

              if (Math.floor(currentTimeInNumber) === hourInDayInNumber) {
                //If shift ends
                if (
                  Math.floor(firstShiftEndInNumber) ===
                  Math.floor(currentTimeInNumber)
                ) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,

                    endMinuteOfInactiveSlot: 59,

                    hasInactiveSlot: true,
                  });
                }
                //.If doesn't end shift
                if (
                  Math.floor(firstShiftEndInNumber) !==
                  Math.floor(currentTimeInNumber)
                ) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,
                    endMinuteOfInactiveSlot:
                      Math.round(
                        (currentTimeInNumber - hourInDayInNumber) * 100,
                      ) < 30
                        ? 30
                        : 59,
                    hasInactiveSlot: true,
                  });
                }
              }
            }
          }
        }

        if (employeeWorksOnSecondShift) {
          if (
            (hourInDayInNumber === Math.floor(secondShiftStartInNumber) ||
              hourInDayInNumber > Math.floor(secondShiftStartInNumber)) &&
            (hourInDayInNumber === Math.floor(secondShiftEndInNumber) ||
              hourInDayInNumber < Math.floor(secondShiftEndInNumber))
          ) {
            if (
              (Math.floor(currentTimeInNumber) < hourInDayInNumber &&
                !dayjs(selectedDateWithIndexAdded).isBefore(
                  dayjs(Date.now()),
                  "day",
                )) ||
              dayjs(selectedDateWithIndexAdded).isAfter(
                dayjs(Date.now()),
                "day",
              )
            ) {
              //if shift starts at 7:30 and hourInDay is 7
              if (hourInDayInNumber === Math.floor(secondShiftStartInNumber)) {
                if (secondShiftStartInNumber - hourInDayInNumber !== 0) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,
                    endMinuteOfInactiveSlot:
                      secondShiftStartInNumber * 100 - hourInDayInNumber * 100,
                    hasInactiveSlot: true,
                  });
                }
                if (
                  Math.round(secondShiftStartInNumber - hourInDayInNumber) === 0
                ) {
                  return (dayToReturn[hourInDay] = {
                    hasInactiveSlot: false,
                  });
                }
              }

              if (
                hourInDayInNumber > Math.floor(secondShiftStartInNumber) &&
                hourInDayInNumber < Math.floor(secondShiftEndInNumber)
              ) {
                return (dayToReturn[hourInDay] = {
                  hasInactiveSlot: false,
                });
              }

              if (hourInDayInNumber === Math.floor(secondShiftEndInNumber)) {
                return (dayToReturn[hourInDay] = {
                  startMinuteOfInactiveSlot: Math.round(
                    (secondShiftEndInNumber - hourInDayInNumber) * 100,
                  ),
                  endMinuteOfInactiveSlot: 59,
                  hasInactiveSlot: true,
                });
              }
            }
            if (Math.floor(currentTimeInNumber) >= hourInDayInNumber) {
              //if current time is bigger than the one in the map
              if (Math.floor(currentTimeInNumber) > hourInDayInNumber) {
                return (dayToReturn[hourInDay] = {
                  startMinuteOfInactiveSlot: 0,
                  endMinuteOfInactiveSlot: 59,
                  hasInactiveSlot: true,
                });
              }

              if (Math.floor(currentTimeInNumber) === hourInDayInNumber) {
                //If shift ends
                if (
                  Math.floor(secondShiftEndInNumber) ===
                  Math.floor(currentTimeInNumber)
                ) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,

                    endMinuteOfInactiveSlot: 59,

                    hasInactiveSlot: true,
                  });
                }
                //.If doesn't end shift
                if (
                  Math.floor(secondShiftEndInNumber) !==
                  Math.floor(currentTimeInNumber)
                ) {
                  return (dayToReturn[hourInDay] = {
                    startMinuteOfInactiveSlot: 0,
                    endMinuteOfInactiveSlot:
                      Math.round(
                        (currentTimeInNumber - hourInDayInNumber) * 100,
                      ) < 30
                        ? 30
                        : 59,
                    hasInactiveSlot: true,
                  });
                }
              }
            }
          }
        }
      });
    }

    //if employee doesn't work at all that day
    if (!employeeWorksOnFirstShift && !employeeWorksOnSecondShift) {
      Object.keys(dayToReturn).map((hourInDay) => {
        return (dayToReturn[hourInDay] = {
          startMinuteOfInactiveSlot: 0,
          endMinuteOfInactiveSlot: 59,
          hasInactiveSlot: true,
        });
      });
    }
  }

  if (dayjs(selectedDateWithIndexAdded).isBefore(dayjs(Date.now()), "day")) {
    Object.keys(dayToReturn).map((hourInDay) => {
      return (dayToReturn[hourInDay] = {
        startMinuteOfInactiveSlot: 0,
        endMinuteOfInactiveSlot: 59,
        hasInactiveSlot: true,
      });
    });
  }

  return { ...dayToReturn };
};
