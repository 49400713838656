import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import {
  getTotalsOfAllTheServices,
  isResponseSuccessful,
  produceError,
} from "../../Utilities/utilities";
import { toast } from "react-toastify";
const initialState = {
  isLoading: false,
};

export const inviteEmployee = createAsyncThunk(
  "employee/inviteEmployee",
  async ({ email }, thunkAPI) => {
    const { shop } = thunkAPI.getState();

    const res = await Request.post(`Employee/invite-employee/${shop?.id}`, {
      body: {
        email: email,
      },
    });

    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const inviteEmployeeSlice = createSlice({
  name: "InviteEmployee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(inviteEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Ο υπάλληλος προσκλήθηκε.");
      })
      .addCase(inviteEmployee.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      });
  },
});

export const {} = inviteEmployeeSlice.actions;

export default inviteEmployeeSlice.reducer;
