import React, { useEffect } from "react";
import {
  oneDayCreator,
  threeDayCreator,
  twoDayCreator,
  weekCreator,
} from "../../Utilities/utilities";
import WeekNavigatorSlot from "../WeekNavigatorSlot/WeekNavigatorSlot";
import "./style.css";

const WeekNavigator = ({ scrolledLeft, containerNav, typeOfNavigator }) => {
  useEffect(() => {
    const nav = document.querySelector("#nav");
    nav.scrollLeft = scrolledLeft;
  }, [scrolledLeft]);
  let widthOfLeftBar =
    document.getElementsByClassName("leftPart")?.[0]?.offsetWidth;
  return (
    <div
      ref={containerNav}
      id={"nav"}
      style={{
        flexDirection: "row",
        maxWidth: "100%",
        zIndex: 1,
        padding: 0,
        position: "sticky",
        overflowY: "scroll",
        paddingLeft: widthOfLeftBar,
      }}
      className={" top-14 border-b bg-gray-100 "}
    >
      <div
        style={{
          alignItems: "space-evenly",
          display: "flex",
        }}
      >
        {typeOfNavigator === "week" &&
          weekCreator.map((weekDay, index) => (
            <WeekNavigatorSlot key={index} index={index} weekDay={weekDay} />
          ))}
        {typeOfNavigator === "2Day" &&
          twoDayCreator.map((weekDay, index) => (
            <WeekNavigatorSlot key={index} index={index} weekDay={weekDay} />
          ))}
        {typeOfNavigator === "3Day" &&
          threeDayCreator.map((weekDay, index) => (
            <WeekNavigatorSlot key={index} index={index} weekDay={weekDay} />
          ))}
        {typeOfNavigator === "1Day" &&
          oneDayCreator.map((weekDay, index) => (
            <WeekNavigatorSlot key={index} index={index} weekDay={weekDay} />
          ))}
      </div>
    </div>
  );
};

export default WeekNavigator;
