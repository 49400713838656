import { classNames } from "../../Utilities/utilities";

const StatusIcon = ({ status }) => {
  return (
    <span
      className={classNames(
        status === "Finished"
          ? "bg-green-400"
          : status === "Pending"
            ? "bg-yellow-500"
            : status === "Absent"
              ? "bg-red-600"
              : "bg-primary",
        "flex w-2.5 h-2.5 me-3 rounded-full absolute bottom-1 left-1 border-[1px] border-white",
      )}
    ></span>
  );
};

export default StatusIcon;
