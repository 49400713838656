import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

import { useLoadScript } from "@react-google-maps/api";

import { PlacesAutoComplete } from "../PlacesAutoComplete/PlacesAutoComplete";
import { checkEmptyProperties } from "../../Utilities/utilities";

export const BusinessLocationStep = ({
  onNext,
  shopAddress,
  shopLocation,
  setShopAddress,
  setShopLocation,
  goBack,
}) => {
  const libraries = ["places"];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: "el",
    region: "GR",
  });

  return (
    <div
      className={
        "bg-white max-w-[1100px] flex m-auto flex-wrap p-4 rounded-xl shadow"
      }
    >
      <div className={"w-full md:w-1/2 flex gap-2 flex-col"}>
        <div>
          <label htmlFor="country">Χώρα</label>
          <input
            name="country"
            id="country"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            value={shopAddress?.country}
            onChange={(e) =>
              setShopAddress((prev) => ({ ...prev, country: e.target.value }))
            }
          />
        </div>

        <div>
          <label htmlFor="city">Πόλη</label>
          <input
            type="text"
            name="city"
            id="city"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            value={shopAddress?.city}
            onChange={(e) =>
              setShopAddress((prev) => ({ ...prev, city: e.target.value }))
            }
          />
        </div>

        <div>
          <label htmlFor="state">Περιοχή</label>
          <input
            name="state"
            id="state"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            value={shopAddress?.region}
            onChange={(e) =>
              setShopAddress((prev) => ({ ...prev, region: e.target.value }))
            }
          />
        </div>
        <div className="w-full flex gap-1">
          <div className={"w-2/3"}>
            <label htmlFor="address">Διεύθυνση</label>
            <input
              type="text"
              name="address"
              id="address"
              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              value={shopAddress?.address}
              onChange={(e) =>
                setShopAddress((prev) => ({ ...prev, address: e.target.value }))
              }
            />
          </div>
          <div className={"w-1/3"}>
            <label htmlFor="address">Αριθμό</label>
            <input
              type="text"
              name="address"
              id="address"
              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              value={shopAddress?.number}
              onChange={(e) =>
                setShopAddress((prev) => ({ ...prev, number: e.target.value }))
              }
            />
          </div>
        </div>

        <div className="w-1/3">
          <label htmlFor="zipcode">Τ.Κ</label>
          <input
            type="text"
            name="zipcode"
            id="zipcode"
            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            placeholder=""
            value={shopAddress?.zipCode}
            onChange={(e) =>
              setShopAddress((prev) => ({ ...prev, zipCode: e.target.value }))
            }
          />
        </div>
      </div>
      {isLoaded && (
        <APIProvider apiKey={"AIzaSyC3RQ8cuzM_j_oVxt-L8HhVV8u3zLqmaTo"}>
          <div className={"w-full md:w-1/2 md:px-4 mt-3 md:mt-0"}>
            <div>
              <PlacesAutoComplete
                setShopAddress={setShopAddress}
                shopAddress={shopAddress}
                setSelected={setShopLocation}
              />
            </div>

            {shopLocation && (
              <div style={{ height: "50vh" }} className={"h-full mt-3"}>
                <Map
                  onClick={(e) => {
                    setShopLocation({
                      lng: e.detail.latLng.lng,
                      lat: e.detail.latLng.lat,
                    });
                  }}
                  zoom={16}
                  mapId={"12e09699495f1e09"}
                  center={shopLocation}
                >
                  {shopLocation?.lng && (
                    <AdvancedMarker position={shopLocation}></AdvancedMarker>
                  )}
                </Map>
              </div>
            )}
          </div>

          <div className="mt-6 flex w-full items-center justify-end gap-x-6">
            <button
              onClick={() => {
                goBack();
              }}
              className=" rounded-md bg-gray-400 px-3 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Πίσω
            </button>
            <button
              disabled={
                checkEmptyProperties(shopAddress)?.length > 0 || !shopLocation
              }
              onClick={() => {
                onNext();
              }}
              className="disabled:bg-gray-300 cursor-pointer rounded-md bg-primary px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Επόμενο
            </button>
          </div>
        </APIProvider>
      )}
    </div>
  );
};
