import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Request from "../../Utilities/Request";
import { isResponseSuccessful, produceError } from "../../Utilities/utilities";
import { toast } from "react-toastify";
import { getShop } from "../shop/shop-slice";

const initialState = {
  isLoading: false,
  idOfCategoryOrServiceThatWillBeDeletedOrEdited: null,
  isCategoryOrServiceLoading: false,
  data: [],
};

export const getBranchServices = createAsyncThunk(
  "categoriesWithServices/getBranchServices",
  async (_, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.get(
      `BranchServices/get-branch-services/${shop?.id}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const removeBranchService = createAsyncThunk(
  "categoriesWithServices/removeBranchService",
  async ({ serviceId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfCategoryThatWillBeDeletedOrEdited(serviceId));
    const res = await Request.remove(
      `BranchServices/delete-service/${shop?.id}/${serviceId}`,
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const removeBranchCategory = createAsyncThunk(
  "categoriesWithServices/removeBranchCategory",
  async ({ categoryId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfCategoryThatWillBeDeletedOrEdited(categoryId));

    const res = await Request.remove(
      `BranchCategory/delete-category/${shop?.id}/${categoryId}`,
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const addBranchCategory = createAsyncThunk(
  "categoriesWithServices/addBranchCategory",
  async ({ categoryName }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.post(
      `BranchCategory/create-branch-category/${shop?.id}`,
      {
        body: {
          name: categoryName,
        },
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);
export const addBranchService = createAsyncThunk(
  "categoriesWithServices/addBranchService",
  async ({ payload }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.post(
      `BranchServices/create-branch-service/${shop?.id}`,
      {
        body: payload,
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const editBranchService = createAsyncThunk(
  "categoriesWithServices/editBranchService",
  async ({ service, serviceId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfCategoryThatWillBeDeletedOrEdited(serviceId));

    const res = await Request.put(
      `BranchServices/edit-branch-service/${shop?.id}/${serviceId}`,
      {
        body: service,
      },
    );
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const editBranchCategory = createAsyncThunk(
  "categoriesWithServices/editBranchCategory",
  async ({ categoryName, categoryId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    thunkAPI.dispatch(setIdOfCategoryThatWillBeDeletedOrEdited(categoryId));

    const res = await Request.put(`BranchCategory/${shop?.id}/${categoryId}`, {
      body: { name: categoryName },
    });
    if (isResponseSuccessful(res)) {
      thunkAPI.dispatch(getShop({ id: shop?.id }));
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

export const getEmployeesByServiceId = createAsyncThunk(
  "categoriesWithServices/getEmployeesByServiceId",
  async ({ serviceId }, thunkAPI) => {
    const { shop } = thunkAPI.getState();
    const res = await Request.get(
      `BranchServices/get-employees-by-service-id/${shop?.id}/${serviceId}`,
    );
    if (isResponseSuccessful(res)) {
      return res;
    }
    if (!isResponseSuccessful(res)) {
      produceError(res);
    }
  },
);

const categoriesWithServicesSlice = createSlice({
  name: "Services",
  initialState,
  reducers: {
    setBranchCategoriesWithItsServices(state, action) {
      state.data = action.payload;
    },
    setIdOfCategoryThatWillBeDeletedOrEdited(state, action) {
      state.idOfCategoryOrServiceThatWillBeDeletedOrEdited = action.payload;
    },

    clearIdOfCategoryThatWillBeDeletedOrEdited(state, action) {
      state.idOfCategoryOrServiceThatWillBeDeletedOrEdited = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchServices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBranchServices.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.data = [...action.payload];
        }
      })
      .addCase(getBranchServices.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(removeBranchService.pending, (state, action) => {
        state.isCategoryOrServiceLoading = true;
      })
      .addCase(removeBranchService.fulfilled, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.success("Η υπηρεσία διαγράφηκε επιτυχώς");
      })
      .addCase(removeBranchService.rejected, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.error(action.error.message);
      })
      .addCase(removeBranchCategory.pending, (state, action) => {
        state.isCategoryOrServiceLoading = true;
      })
      .addCase(removeBranchCategory.fulfilled, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.success("Η κατηγορία διαγράφηκε επιτυχώς");
      })
      .addCase(removeBranchCategory.rejected, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.error(action.error.message);
      })
      .addCase(addBranchCategory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addBranchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Η κατηγορία προστέθηκε επιτυχώς");
      })
      .addCase(addBranchCategory.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(editBranchCategory.pending, (state, action) => {
        state.isCategoryOrServiceLoading = true;
      })
      .addCase(editBranchCategory.fulfilled, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        // toast.success("Η κατηγορία άλλαξε επιτυχώς");
      })
      .addCase(editBranchCategory.rejected, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.error(action.error.message);
      })
      .addCase(addBranchService.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addBranchService.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Η υπηρεσία προστέθηκε επιτυχώς");
      })
      .addCase(addBranchService.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.error.message);
      })
      .addCase(getShop.fulfilled, (state, action) => {
        state.data = action?.payload?.categories;
      })
      .addCase(editBranchService.pending, (state, action) => {
        state.isCategoryOrServiceLoading = true;
      })
      .addCase(editBranchService.fulfilled, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.success("Η υπηρεσία αλλάχτηκε με επιτυχία");
      })
      .addCase(editBranchService.rejected, (state, action) => {
        state.isCategoryOrServiceLoading = false;
        toast.error(action.error.message);
      });
  },
});

export const areCategoriesWithServicesLoading = (state) =>
  state.categoriesWithServices.isCategoryOrServiceLoading;
export const idOfCategoryOrServiceThatWillBeDeletedOrEdited = (state) =>
  state.categoriesWithServices.idOfCategoryOrServiceThatWillBeDeletedOrEdited;

export const {
  setBranchCategoriesWithItsServices,
  setIdOfCategoryThatWillBeDeletedOrEdited,
  clearIdOfCategoryThatWillBeDeletedOrEdited,
} = categoriesWithServicesSlice.actions;

export default categoriesWithServicesSlice.reducer;
