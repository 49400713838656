import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export const isJwtTokenValid = () => {
  let accessToken = localStorage?.getItem("accessToken");
  let isValid = false;
  let decodedToken;
  try {
    decodedToken = jwt_decode(accessToken);
  } catch (e) {
    return isValid;
  }
  let currentDate = new Date();
  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    console.log("Token expired.");
    localStorage?.removeItem("accessToken");
    toast.error("Η σύνδεση σας έληξε, παρακαλώ συνδεθείτε ξανά");
  } else {
    // console.log("Valid token");
    isValid = true;
  }

  return isValid;
};
